import { FC, AnchorHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import { isAbsoluteURL } from '@app/utils';

type LinkElementProps = {
  url: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkElement: FC<LinkElementProps> = ({
  url,
  children,
  ...rest
}) => {
  if (isAbsoluteURL(url)) {
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};
