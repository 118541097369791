import { FC, MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useInstanceDetailsRoutePath } from '@app/hooks';

interface RecordLinksProps {
  id: string;
  title: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const RecordLinks: FC<RecordLinksProps> = ({ id, title, onClick }) => {
  const { getInstanceDetailsRoutePath } = useInstanceDetailsRoutePath();
  const { $t } = useIntl();

  return (
    <FormattedMessage
      id="resultsList.viewDetails"
      defaultMessage="View details"
    >
      {message => (
        <Link
          to={getInstanceDetailsRoutePath(id)}
          className="emphasizedLink"
          aria-label={$t(
            {
              id: 'resultsList.viewDetailsOf',
              defaultMessage: 'View {title} details',
            },
            { title }
          )}
          onClick={onClick}
        >
          {message[0]}
        </Link>
      )}
    </FormattedMessage>
  );
};
