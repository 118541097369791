import { FC, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { camelCase, noop } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { InPageNotification } from '@ebsco-ui/ebsco-ui';

import { Notification } from '@app/components';
import { setFocusOn } from '@app/utils';
import { PropsWithClassName } from '@app/constants';

import { FacetShowMore, GhostFacet } from '..';

import css from './Facet.module.scss';

type FacetHandler = (facetKey: string) => void;

interface FacetProps {
  label: string;
  facetKey: string;
  facetAttributes: ReactElement[];
  selectedCount?: number;
  isInitiallyExpanded?: boolean;
  isError?: boolean;
  isActive?: boolean;
  expandFacet?: FacetHandler;
  closeFacet?: FacetHandler;
}

export const Facet: FC<PropsWithClassName<FacetProps>> = ({
  label,
  facetAttributes,
  selectedCount = 0,
  facetKey,
  isInitiallyExpanded = false,
  className,
  isError = false,
  isActive = true,
  expandFacet = noop,
  closeFacet = noop,
}) => {
  const { $t } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isGhostFocused, setGhostFocused] = useState(false);

  const hasSelection = selectedCount > 0;
  const formattedLabel = camelCase(label);
  const accordionGroupId = `accordionGroup_${formattedLabel}`;
  const accordionPanelId = `accordionPanel_${formattedLabel}`;

  const toggleFacet = () => {
    if (isExpanded) {
      closeFacet(facetKey);
    } else {
      expandFacet(facetKey);
    }

    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(prevState => prevState || isInitiallyExpanded);

    if (isInitiallyExpanded) {
      expandFacet(facetKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitiallyExpanded]);

  useEffect(() => {
    if (facetAttributes && isGhostFocused) {
      setFocusOn({
        element: document.querySelector<HTMLElement>(
          `#${accordionPanelId} input`
        ),
      });
      setGhostFocused(false);
    }
  }, [accordionPanelId, facetAttributes, isGhostFocused]);

  const renderContent = () => {
    if (facetAttributes) {
      return <FacetShowMore>{facetAttributes}</FacetShowMore>;
    }

    if (isError) {
      return (
        <Notification
          className={css.errorMessage}
          header={$t({
            id: 'facet.errorMessage',
            defaultMessage: 'Error loading content. Please try again later.',
          })}
          variant={InPageNotification.VARIANT.error}
        />
      );
    }

    return <GhostFacet onFocusChange={setGhostFocused} />;
  };

  return (
    <div
      className={cn(css.facet, className)}
      data-testid="search-facet"
      key={label}
    >
      <h3 className={css.facetTitle}>
        <button
          id={accordionGroupId}
          className={css.accordionTrigger}
          type="button"
          data-testid="facet-header"
          aria-expanded={isExpanded}
          aria-label={$t(
            {
              id: 'facet.facetTrigger',
              defaultMessage: '{label} facet, {count} selected',
            },
            {
              label,
              count: selectedCount,
            }
          )}
          aria-controls={accordionPanelId}
          onClick={isActive ? toggleFacet : noop}
        >
          <span>
            {label} {hasSelection && `(${selectedCount})`}
          </span>
          <FontAwesomeIcon
            className={cn(css.icon, {
              [css.iconExpanded]: isExpanded,
            })}
            icon={faChevronRight}
          />
        </button>
      </h3>
      {isExpanded && (
        <div
          id={accordionPanelId}
          className={css.accordionPanel}
          data-testid="facet-content"
          role="region"
          aria-labelledby={accordionGroupId}
        >
          {renderContent()}
        </div>
      )}
    </div>
  );
};
