import { FC } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

import { FeatureFlags } from '@app/components';
import { ROUTE, FEATURE } from '@app/constants';

type ConditionalRouteProps = {
  flags: FEATURE[];
  shouldRenderOnFalse?: boolean;
};

export const ConditionalRoute: FC<RouteProps & ConditionalRouteProps> = ({
  flags,
  shouldRenderOnFalse = false,
  children,
}) => {
  const navigateToNotFound = () => <Navigate replace to={ROUTE.notFound} />;
  const renderChildren = () => children;

  const renderProps = {
    renderOn: shouldRenderOnFalse ? navigateToNotFound : renderChildren,
    renderOff: shouldRenderOnFalse ? renderChildren : navigateToNotFound,
  };

  return <FeatureFlags authorizedFlags={flags} {...renderProps} />;
};
