import { FC } from 'react';
import { times } from 'lodash';
import cn from 'classnames';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import './StepsBar.scss';

const cnBem = createBemBlockBuilder(['stepsBar']);

interface StepsBarProps {
  stepsAmount: number;
  currentStepIndex: number;
}

export const StepsBar: FC<StepsBarProps> = ({
  stepsAmount,
  currentStepIndex,
}) => {
  return (
    <div className={cnBem('__wrapper')}>
      {times(stepsAmount, index => (
        <span
          key={index}
          className={cn(cnBem('__step'), {
            [cnBem('__activeStep')]: index === currentStepIndex,
          })}
        />
      ))}
    </div>
  );
};
