import { isString } from 'lodash';

type Subfield = Record<string, string>;

interface FieldWithIndicators {
  ind1: string;
  ind2: string;
  subfields: Subfield[];
}

type MarcField = Record<string, string> | Record<string, FieldWithIndicators>;
export type TableFields = [string, string][];

export interface MarcRecordContent {
  fields: MarcField[];
  leader: string;
}

const stringifySubfields = (subfields: Subfield[]) => {
  return subfields
    .flatMap(subfield => Object.entries(subfield))
    .map(([key, value]) => `‡${key} ${value}`)
    .join(' ');
};

export const parseMarcRecord = ({
  leader,
  fields,
}: MarcRecordContent): TableFields => {
  const stringifiedFields: TableFields = fields.map(field => {
    const [name, value] = Object.entries(field)[0];

    if (isString(value)) {
      return [name, value];
    }

    const { ind1, ind2, subfields = [] } = value;

    return [`${name} ${ind1} ${ind2}`, stringifySubfields(subfields)];
  });

  return [['LEADER', leader], ...stringifiedFields];
};
