import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSessionStorage } from 'usehooks-ts';
import { faBookmark as BookmarkRegular } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as BookmarkSolid } from '@fortawesome/pro-solid-svg-icons';

import { ToolButton } from '@ebsco-ui/ebsco-ui';

import { SearchInstanceDto } from '@app/pages';
import { SAVED_INSTANCES_KEY } from '@app/search';
import { sharedMessages } from '@app/translations';

interface SavedInstanceToolButtonProps {
  entry: SearchInstanceDto;
}

export const SavedInstanceToolButton: FC<SavedInstanceToolButtonProps> = ({
  entry,
}) => {
  const { $t } = useIntl();
  const [savedInstances, setSavedInstances] = useSessionStorage<
    SearchInstanceDto[]
  >(SAVED_INSTANCES_KEY, []);

  const isSelected = Boolean(
    savedInstances.find(instance => instance.id === entry.id)
  );

  return (
    <ToolButton
      title={isSelected ? $t(sharedMessages.unsave) : $t(sharedMessages.save)}
      isSelected={isSelected}
      defaultIcon={BookmarkRegular}
      selectedIcon={BookmarkSolid}
      onClick={() => {
        setSavedInstances(
          !isSelected
            ? [...savedInstances, entry]
            : savedInstances.filter(instance => instance.id !== entry.id)
        );
      }}
    />
  );
};
