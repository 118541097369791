import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  faBooks,
  faBookReader,
  faMoneyBillWave,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Loading,
  Page,
  PageErrorMessage,
  ShowMorePagination,
} from '@ebsco-ui/ebsco-ui';

import {
  ButtonWithRedirect,
  RecordSummary,
  SortByDropdown,
} from '@app/components';
import {
  BookshelfTab,
  SetTabsCounters,
  BookShelfSortField,
} from '@app/pages/constants';
import { tabsConfig } from '@app/pages/BookshelfPage/constants';
import { useAppContext } from '@app/contexts';
import {
  useBookshelfDataFetch,
  usePaginationItemFocus,
  useQueryParams,
} from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { sharedMessages, getPageMessages } from '@app/translations';
import { RECORDS_PER_PAGE, ROUTE } from '@app/constants';

import * as tabContentMessages from './tabContentMessages';

import css from './TabContent.module.scss';

const emptyStateIconMap = {
  holds: faBooks,
  checkouts: faBookReader,
  fees: faMoneyBillWave,
};

interface TabContentProps {
  type: BookshelfTab;
  totalRecordsNumber: number;
  setTabsCounters: SetTabsCounters;
}

export const TabContent: FC<TabContentProps> = ({
  type,
  totalRecordsNumber,
  setTabsCounters,
}) => {
  const intl = useIntl();
  const { $t } = intl;
  const { isLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { sortField: sortFieldByUrl, sortDirection: sortDirectionByUrl } =
    useQueryParams();

  const { sortOptions, queryConfig } = tabsConfig[type];

  const defaultSortParams = useMemo(
    () => ({
      sortField: BookShelfSortField[sortOptions[0].sortField],
      sortDirection: sortOptions[0].sortDirection as string,
    }),
    [sortOptions]
  );
  const sortByQueryParams = {
    sortField: sortFieldByUrl as string,
    sortDirection: sortDirectionByUrl as string,
  };
  const [sortByParams, setSortByParams] = useState(defaultSortParams);
  const {
    pages,
    itemsStatusesData,
    pageNumber,
    bookshelfQuery: {
      isFetching,
      isSuccess: isBookshelfDataSuccessfullyLoaded,
      refetch: refetchBookshelfData,
    },
    setPageNumber,
  } = useBookshelfDataFetch({
    sortByParams: sortFieldByUrl ? sortByQueryParams : sortByParams,
    queryConfig,
    setTabsCounters,
  });

  usePaginationItemFocus({
    pageNumber,
    totalPagesNumber: pages?.length,
  });

  useEffect(() => {
    if (!sortFieldByUrl) {
      setSortByParams(defaultSortParams);
    }
  }, [defaultSortParams, sortFieldByUrl]);

  const isBookshelfDataEmpty =
    itemsStatusesData !== null && isEmpty(itemsStatusesData);

  const handleSortBySelect = option => {
    setSortByParams({
      sortField: option.sortField,
      sortDirection: option.sortDirection,
    });
    navigate(
      generateRouteParams({
        pathname: location.pathname,
        query: {
          sortField: option.sortField,
          sortDirection: option.sortDirection,
        },
      })
    );
  };

  const renderEmptyTabContent = () => (
    <div className={css.emptyState} data-testid="empty-state">
      <div className={css.resultsCount}>
        <FormattedMessage
          {...sharedMessages.numberOfItems}
          values={{
            number: totalRecordsNumber,
          }}
        />
      </div>
      <div className={css.messageWrapper} data-testid={`no-${type}`}>
        <FontAwesomeIcon icon={emptyStateIconMap[type]} className={css.icon} />
        {isLoggedIn ? (
          <FormattedMessage {...tabContentMessages[type].noTabResults} />
        ) : (
          <>
            <FormattedMessage {...tabContentMessages[type].promptTitle} />
            <ButtonWithRedirect redirectBackRoute={ROUTE[type]} />
          </>
        )}
      </div>
    </div>
  );

  if (
    !isLoggedIn ||
    (isBookshelfDataSuccessfullyLoaded && isBookshelfDataEmpty)
  ) {
    return renderEmptyTabContent();
  }

  if (pageNumber === 1 && isFetching) {
    return (
      <div data-testid="tab-spinner">
        <Loading
          direction="vertical"
          size="large"
          loadingMessage={$t(sharedMessages.loadingMessage)}
        />
      </div>
    );
  }

  if (!isBookshelfDataSuccessfullyLoaded) {
    return (
      <PageErrorMessage
        className={css.pageErrorMessage}
        buttonText={$t(sharedMessages.refreshPage)}
        title={$t(sharedMessages.pageUnavailable)}
        text={$t(sharedMessages.searchUnavailableText)}
        titleTag="h2"
        onClick={() => refetchBookshelfData()}
      />
    );
  }

  return itemsStatusesData && pages && !isEmpty(pages[0]) ? (
    <>
      <div className={css.resultsPanel}>
        <div className={css.resultsCount}>
          <FormattedMessage
            {...sharedMessages.numberOfItems}
            values={{
              number: totalRecordsNumber,
            }}
          />
        </div>
        <SortByDropdown
          options={sortOptions}
          sortField={sortByParams.sortField}
          sortDirection={
            sortByQueryParams.sortDirection || sortByParams.sortDirection
          }
          onSelect={handleSortBySelect}
        />
      </div>
      <ShowMorePagination
        uniqueName={`${type}-list`}
        error={undefined}
        messages={getPageMessages(intl, {
          showMoreButtonTitle: $t(tabContentMessages[type].showMoreButtonTitle),
        })}
        isLoading={isFetching}
        totalRecords={totalRecordsNumber}
        recordsPerPage={RECORDS_PER_PAGE}
        pages={pages}
        onLoadPage={() => setPageNumber(pageNumber + 1)}
      >
        {(page, currentPageNumber) => {
          return (
            <Page
              title={$t(sharedMessages.pageNumber, {
                pageNumber: currentPageNumber,
              })}
              key={currentPageNumber}
              number={currentPageNumber}
              uniqueName="bookshelfDivider"
            >
              {page.map((instance, index) => {
                const currentItemStatusData =
                  itemsStatusesData[
                    currentPageNumber * RECORDS_PER_PAGE + index
                  ];

                return (
                  <RecordSummary
                    testId="bookshelf-item"
                    key={currentItemStatusData.itemId}
                    instance={instance}
                    itemStatusData={currentItemStatusData}
                    placement="bookshelf"
                    isLast={index === page.length - 1}
                  />
                );
              })}
            </Page>
          );
        }}
      </ShowMorePagination>
    </>
  ) : null;
};
