import { FC, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';

import { PageErrorMessage } from '@ebsco-ui/ebsco-ui';

import { Image } from '@app/components';
import { ROUTE } from '@app/constants';

import NotFoundImage from './NotFoundImage.svg';

import css from './NotFoundPage.module.scss';

export const NotFoundPage: FC = memo(() => {
  const navigate = useNavigate();
  const { $t } = useIntl();

  const navigateToSearchPage = useCallback(() => {
    navigate(ROUTE.search);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'notFound.documentTitle',
            defaultMessage: 'Page not found - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <PageErrorMessage
        title="404"
        subTitle={$t({
          id: 'notFound.pageNotFound',
          defaultMessage: 'Page not found',
        })}
        text={$t({
          id: 'notFound.requestedPageNotFound',
          defaultMessage:
            'The page you requested cannot be found. Try refreshing or return to the homepage.',
        })}
        buttonText={$t({
          id: 'notFound.backToHomepage',
          defaultMessage: 'Back to homepage',
        })}
        image={<Image src={NotFoundImage} altText="404" />}
        imagePlacement="block-end"
        className={cn('container', css.container)}
        onClick={navigateToSearchPage}
      />
    </>
  );
});

NotFoundPage.displayName = 'NotFoundPage';
