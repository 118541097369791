import { FC } from 'react';

import { ShowMore } from '@ebsco-ui/ebsco-ui';

import { ShowMoreButton } from '@app/components';
import { ContributorDto } from '@app/pages/constants';

import { MAX_CONTRIBUTORS_TO_DISPLAY } from '../constants';

import css from './BrowseContributors.module.scss';

interface BrowseContributorsProps {
  contributors?: ContributorDto[];
}

export const BrowseContributors: FC<BrowseContributorsProps> = ({
  contributors = [],
}) => {
  if (!contributors.length) {
    return <span>{'-'}</span>;
  }

  const contributorItems = contributors.map(({ name }) => (
    <li key={name}>{name}</li>
  ));

  return (
    <ShowMore limit={MAX_CONTRIBUTORS_TO_DISPLAY} className={css.showMore}>
      <ul className={css.contributorsList} data-testid="contributors-list">
        <ShowMore.Items>{contributorItems}</ShowMore.Items>
      </ul>
      <ShowMoreButton
        showMoreLimit={MAX_CONTRIBUTORS_TO_DISPLAY}
        dataLength={contributors.length}
      />
    </ShowMore>
  );
};
