import { compact, isEmpty, isNumber } from 'lodash';

import {
  ALL_RECORDS_CQL,
  SEARCH_OPTION,
  SORT_FIELD,
  sortFieldMap,
  SearchState,
} from '@app/search';
import { RECORDS_PER_PAGE, SORT_DIRECTION } from '@app/constants';

import { getFacetsCQL, getPublicationYearCQL, getQueryCQL } from '.';

export interface SQLState extends SearchState {
  hasRequestedFacets?: boolean;
}

const getCQLSearchQuery = ({
  query = '',
  option = SEARCH_OPTION.keyword,
  sortField = SORT_FIELD.relevance,
  sortDirection = SORT_DIRECTION.asc,
  facets,
  publicationYear,
  hasRequestedFacets,
}: SQLState) => {
  if (
    query === '' &&
    !hasRequestedFacets &&
    isEmpty(facets) &&
    isEmpty(publicationYear)
  ) {
    return '';
  }

  const sortByParam =
    sortField !== SORT_FIELD.relevance
      ? ` sortBy ${sortFieldMap[sortField]}/sort.${sortDirection}`
      : '';

  const isRequestForFacetAttributes =
    hasRequestedFacets &&
    query === '' &&
    isEmpty(facets) &&
    isEmpty(publicationYear);

  if (isRequestForFacetAttributes) {
    return ALL_RECORDS_CQL;
  }

  const facetCQL = getFacetsCQL(facets);
  const publicationYearCQL = getPublicationYearCQL(publicationYear);
  const queryCQL = getQueryCQL(query, option);
  const queryParamWithFacetParam = compact([
    queryCQL,
    facetCQL,
    publicationYearCQL,
  ]).join(' and ');

  return `${queryParamWithFacetParam}${sortByParam}`;
};

interface SQLQuery {
  query: string;
  limit: number;
  offset?: number;
}

export const getCQLState = ({
  query,
  option,
  sortField,
  sortDirection,
  facets,
  publicationYear,
  pageNumber,
  hasRequestedFacets,
}: SQLState): SQLQuery => {
  return {
    query: getCQLSearchQuery({
      query,
      option,
      sortField,
      sortDirection,
      facets,
      publicationYear,
      hasRequestedFacets,
    }),
    limit: RECORDS_PER_PAGE,
    ...(isNumber(pageNumber) &&
      pageNumber > 1 && {
        offset: RECORDS_PER_PAGE * (pageNumber - 1),
      }),
  };
};
