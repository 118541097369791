import { useSessionStorage } from 'usehooks-ts';

import { SEARCH_HISTORY_KEY, SearchState } from '@app/search';

interface SearchHistoryUtils {
  addSearchHistoryEntry: (entry: SearchState) => void;
}

export const useSearchHistory = (): SearchHistoryUtils => {
  const [searchHistory, setSearchHistory] = useSessionStorage<SearchState[]>(
    SEARCH_HISTORY_KEY,
    []
  );

  const addSearchHistoryEntry = (entry: SearchState): void => {
    const lastSearchEntry = searchHistory[searchHistory.length - 1] ?? {};
    const isNewPageRequest =
      (lastSearchEntry?.pageNumber ?? 1) < (entry.pageNumber ?? 1);

    if (!isNewPageRequest) {
      setSearchHistory([entry, ...searchHistory]);
    }
  };

  return {
    addSearchHistoryEntry,
  };
};
