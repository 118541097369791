import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { compact, invert, isEmpty, omit, noop } from 'lodash';
import queryString from 'query-string';
import cn from 'classnames';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { Button, createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { Icon } from '@app/components';
import { FACET, SEARCH_OPTION } from '@app/search';
import { useSearchContext } from '@app/contexts';
import { generateRouteParams } from '@app/utils';
import { sharedMessages } from '@app/translations';
import { ROUTE, URLQuery } from '@app/constants';

import {
  FacetInstanceDto,
  dateFiltersMessages,
  PUBLICATION_YEAR_OPTION,
} from '..';

import './ActiveFilters.scss';

interface SelectedFacetsValues {
  label?: string;
  value?: string;
  facetKey?: string;
  remove: () => void;
}

interface ActiveFiltersProps {
  facets: FacetInstanceDto;
  onFacetAttributeRemoval: (facetKey: string, value: string) => void;
}

const facetsToMapLabel = [FACET.LOCATION, FACET.LANGUAGE];
const cnBem = createBemBlockBuilder(['activeFilters']);

export const ActiveFilters: FC<ActiveFiltersProps> = ({
  facets,
  onFacetAttributeRemoval,
}) => {
  const { $t } = useIntl();
  const {
    state: { query },
    searchOption,
    selectedFacets,
    selectedPublicationYear,
    setSelectedPublicationYear,
    updateState,
  } = useSearchContext();
  const [selectedFacetsValues, setSelectedFacetsValues] = useState<
    SelectedFacetsValues[]
  >([]);
  const navigate = useNavigate();
  const location = useLocation();

  const activeFiltersMessage = $t({
    id: 'filterPane.activeFilters',
    defaultMessage: 'Active filters',
  });

  const getFacetLabel = useCallback(
    (facet, facetKey) => {
      const shouldMapLabel = facetsToMapLabel.includes(facetKey);

      return shouldMapLabel
        ? facets[facetKey].values?.find(({ value }) => value === facet)?.label
        : facet;
    },
    [facets]
  );

  const getDateRangeLabel = useCallback(
    ({ minDate, maxDate }) => {
      if (minDate && maxDate) {
        return $t(dateFiltersMessages.range, {
          minYear: minDate,
          maxYear: maxDate,
        });
      }

      return $t(dateFiltersMessages[invert(PUBLICATION_YEAR_OPTION)[minDate]]);
    },
    [$t]
  );

  const getSelectedFacetsValues = useCallback(() => {
    const publicationYear = !isEmpty(selectedPublicationYear) && {
      label: getDateRangeLabel(selectedPublicationYear),
      remove: () => setSelectedPublicationYear({}),
    };
    const facetValues = Object.keys(selectedFacets).flatMap(facetKey => {
      if (!isEmpty(selectedFacets[facetKey])) {
        const selectedFacetValues = selectedFacets[facetKey];

        return Object.keys(selectedFacetValues)
          .filter(selectedFacet => selectedFacetValues[selectedFacet])
          .map(selectedFacet => ({
            value: selectedFacet,
            label: getFacetLabel(selectedFacet, facetKey),
            facetKey,
            remove: () => onFacetAttributeRemoval(facetKey, selectedFacet),
          }));
      }

      return [];
    });

    return compact([...facetValues, publicationYear]);
  }, [
    getDateRangeLabel,
    getFacetLabel,
    onFacetAttributeRemoval,
    selectedFacets,
    selectedPublicationYear,
    setSelectedPublicationYear,
  ]);

  useEffect(() => {
    setSelectedFacetsValues(getSelectedFacetsValues());
  }, [getSelectedFacetsValues, selectedFacets, selectedPublicationYear]);

  return (
    <div className={cnBem()}>
      <div className={cnBem('__header')}>
        {activeFiltersMessage}
        <Button
          styleType="flat-icon"
          className={cnBem('__clearButton')}
          onClick={() => {
            const updatedQuery = omit(queryString.parse(location.search), [
              'facets',
              'publicationYear',
              !query && searchOption === SEARCH_OPTION.keyword ? 'option' : '',
            ]);

            updateState({ isInnerUpdate: true });
            navigate(
              generateRouteParams({
                pathname: ROUTE.search,
                query: updatedQuery as URLQuery,
              })
            );
          }}
        >
          {$t({
            id: 'filterPane.clearAll',
            defaultMessage: 'Clear all',
          })}
        </Button>
      </div>
      <ul
        className={cnBem('__selectedFacets')}
        aria-label={activeFiltersMessage}
      >
        {selectedFacetsValues.map((selectedFacet, index) => (
          <li
            key={index}
            className={cn({
              [cnBem('__ghostFacet')]: !selectedFacet?.label,
            })}
          >
            <Button
              styleType="flat-icon"
              onClick={selectedFacet.remove}
              {...(!selectedFacet.label && {
                'aria-label': $t(sharedMessages.loadingMessage),
                'data-testid': 'ghost-active-filter',
                onClick: noop,
              })}
            >
              {selectedFacet?.label && (
                <>
                  {selectedFacet.label} <Icon icon={faTimes} />
                </>
              )}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};
