import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import { faBooks } from '@fortawesome/pro-solid-svg-icons';

import {
  Button,
  Loading,
  Modal,
  RadioButton,
  createBemBlockBuilder,
} from '@ebsco-ui/ebsco-ui';

import {
  Dropdown,
  PlaceHoldNotification,
  Icon,
  ModalNotificationType,
  ButtonWithRedirect,
  ModalHeader,
  getErrorReason,
  useRecordSummaryContext,
  DropdownItem,
} from '@app/components';
import {
  useAppContext,
  useAvailabilityContext,
  useMappingsContext,
} from '@app/contexts';
import { useFeatureFlag, usePlaceHoldMutation } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import {
  ROUTE,
  MODAL_PLACEMENT,
  NOTIFICATION_STATUS,
  IS_TEST_ENV,
  FEATURE,
} from '@app/constants';

import '../Modal/Modal.scss';
import './PlaceHoldModal.scss';

interface PlaceHoldModalProps {
  isOpen: boolean;
  onModalClose: () => void;
}

const OPTIONS_GROUP_NAME = 'place-hold-option';
const COPY_TYPE_FIRST_AVAILABLE = '1';
const COPY_TYPE_SPECIFIC = '2';
const cnBemModal = createBemBlockBuilder(['modal']);
const cnBem = createBemBlockBuilder(['placeHoldModal']);

export const PlaceHoldModal: FC<PlaceHoldModalProps> = ({
  isOpen,
  onModalClose,
}) => {
  const queryClient = useQueryClient();
  const { isLoggedIn, setHoldRecordId } = useAppContext();
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);
  const isConsortiaLogin = Boolean(consortiaLoginFlowFlag?.isActive);
  const [copies, setCopies] = useState<DropdownItem[]>([]);
  const [locations, setLocations] = useState<DropdownItem[]>([]);
  const [selectedCopy, setSelectedCopy] = useState<DropdownItem | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<DropdownItem | null>(
    null
  );
  const isMoreThanOneCopy = copies.length > 1;

  const [copyType, setCopyType] = useState(COPY_TYPE_FIRST_AVAILABLE);
  const [status, setStatus] = useState<ModalNotificationType | null>(null);
  const { $t } = useIntl();
  const { availability, isAvailabilityLoading, getAvailability } =
    useAvailabilityContext();
  const { pickupLocations } = useMappingsContext();
  const placeHoldMutation = usePlaceHoldMutation();
  const { instance } = useRecordSummaryContext();

  const resetRelayOnModalValues = () => {
    setCopyType(COPY_TYPE_FIRST_AVAILABLE);
    setSelectedCopy(null);
    setSelectedLocation(locations[0]);
    setStatus(null);
  };

  const handleCopyTypeChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setCopyType(value);
    setSelectedCopy(value === COPY_TYPE_SPECIFIC ? copies[0] : null);
  };

  useEffect(() => {
    const recordAvailability = availability[instance.id];

    if (recordAvailability) {
      const recordCopies = recordAvailability.availabilityData.map(
        holdingAvailability => ({
          label: holdingAvailability?.volume
            ? `${holdingAvailability.callNumber} ${holdingAvailability.volume}`
            : holdingAvailability.callNumber,
          id: holdingAvailability.id,
        })
      ) as DropdownItem[];

      setCopies(recordCopies);
    }
  }, [instance.id, availability]);

  useEffect(() => {
    if (pickupLocations && !isEmpty(pickupLocations)) {
      const availableLocations = pickupLocations.map(
        ({ id, discoveryDisplayName, name }) => ({
          label: discoveryDisplayName || name,
          id,
        })
      ) as DropdownItem[];

      setLocations(availableLocations);
      setSelectedLocation(availableLocations[0]);
    }
  }, [pickupLocations]);

  useEffect(() => {
    if (
      placeHoldMutation.status === NOTIFICATION_STATUS.success ||
      placeHoldMutation.status === NOTIFICATION_STATUS.error
    ) {
      setStatus(placeHoldMutation.status);
    }

    if (placeHoldMutation.status === NOTIFICATION_STATUS.success) {
      getAvailability([instance.id]);
      queryClient.refetchQueries(['userItemStatuses'], { type: 'active' });
    }
  }, [queryClient, getAvailability, placeHoldMutation.status, instance.id]);

  const getModalBody = () => {
    if (!isLoggedIn) {
      return (
        <FormattedMessage
          id="placeHoldModal.signInOrCreateAccount"
          defaultMessage="To place a hold, sign in."
        />
      );
    }

    return status ? (
      <PlaceHoldNotification
        type={status}
        queuePosition={placeHoldMutation?.data?.queuePosition}
        errorMessage={getErrorReason(placeHoldMutation)}
      />
    ) : (
      <form>
        <fieldset className={cnBem('__fieldset')}>
          {isMoreThanOneCopy && !isConsortiaLogin && (
            <>
              <legend className={cnBem('__legend')}>
                <FormattedMessage
                  id="placeHoldModal.iWantToGet"
                  defaultMessage="I want to get:"
                />
              </legend>
              <RadioButton
                label={$t({
                  id: 'placeHoldModal.firstAvailableCopy',
                  defaultMessage: 'First available copy',
                })}
                name={OPTIONS_GROUP_NAME}
                value={COPY_TYPE_FIRST_AVAILABLE}
                checked={COPY_TYPE_FIRST_AVAILABLE === copyType}
                onChange={handleCopyTypeChange}
              />
              <RadioButton
                label={$t({
                  id: 'placeHoldModal.specificCopy',
                  defaultMessage: 'A specific copy (volume)',
                })}
                name={OPTIONS_GROUP_NAME}
                value={COPY_TYPE_SPECIFIC}
                checked={COPY_TYPE_SPECIFIC === copyType}
                onChange={handleCopyTypeChange}
              />
              {copyType === COPY_TYPE_SPECIFIC && (
                <Dropdown
                  label={$t({
                    id: 'placeHoldModal.chooseCopy',
                    defaultMessage: 'Choose a copy',
                  })}
                  id="place-hold-choose-copy"
                  selectedItem={selectedCopy as DropdownItem}
                  items={copies}
                  styleType="standard"
                  onSelectedItemChange={({ selectedItem }) => {
                    setSelectedCopy(selectedItem ?? null);
                  }}
                />
              )}
            </>
          )}
          {!isEmpty(locations) && (
            <Dropdown
              label={$t({
                id: 'placeHoldModal.choosePickupLocation',
                defaultMessage: 'Choose a pickup location',
              })}
              items={locations}
              id="liked-items-sort"
              styleType="standard"
              selectedItem={selectedLocation as DropdownItem}
              onSelectedItemChange={({ selectedItem }) => {
                setSelectedLocation(selectedItem ?? null);
              }}
            />
          )}
        </fieldset>
      </form>
    );
  };

  const getModalFooter = () => {
    if (!isLoggedIn) {
      return (
        <ButtonWithRedirect
          redirectBackRoute={ROUTE.instanceDetails}
          queryParams={{ id: instance.id }}
          onClick={() => setHoldRecordId(instance.id)}
        />
      );
    }

    return (
      <>
        <Button styleType="text" onClick={onModalClose}>
          <FormattedMessage
            {...(status ? sharedMessages.close : sharedMessages.cancel)}
          />
        </Button>
        {!status && (
          <Button
            styleType="default"
            onClick={() => {
              if (selectedLocation) {
                const item =
                  copyType === COPY_TYPE_SPECIFIC
                    ? selectedCopy ?? copies[0]
                    : null;

                placeHoldMutation.mutate({
                  instanceId: instance.id,
                  pickupLocationId: selectedLocation.id as string,
                  itemId: item?.id,
                });
              }
            }}
          >
            <Icon icon={faBooks} />
            <FormattedMessage
              id="placeHoldModal.placeHold"
              defaultMessage="Place hold"
            />
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      disableAnimations={IS_TEST_ENV}
      isOpen={isOpen}
      modalTitle={$t({
        id: 'placeHoldModal.placeHold',
        defaultMessage: 'Place hold',
      })}
      appElement={MODAL_PLACEMENT}
      className={cnBemModal()}
      onClose={onModalClose}
      onAfterClose={resetRelayOnModalValues}
    >
      <div
        data-testid="place-hold-modal"
        className={cn({
          [cnBemModal('__disabledScroll')]: placeHoldMutation.isLoading,
          [cnBemModal('--is-logged-out')]: !isLoggedIn,
        })}
      >
        <ModalHeader
          headerTitle={$t({
            id: 'placeHoldModal.placeHold',
            defaultMessage: 'Place hold',
          })}
          onModalClose={onModalClose}
        />
        <Modal.Body>{getModalBody()}</Modal.Body>
        <Modal.Footer>{getModalFooter()}</Modal.Footer>
        {(placeHoldMutation.isLoading ||
          (isAvailabilityLoading && status === null)) && (
          <Loading
            size="large"
            loadingMessage={
              isAvailabilityLoading
                ? $t(sharedMessages.loadingMessage)
                : $t({
                    id: 'placeHoldModal.placingHold',
                    defaultMessage: 'Placing hold...',
                  })
            }
          />
        )}
      </div>
    </Modal>
  );
};
