import { IntlShape } from 'react-intl';

import { getFormattedDate } from '@app/utils';
import { buttonMessages, checkoutMessages } from '@app/translations';

import {
  STATUS_ICON,
  ITEM_STATUS,
  HOLD_STATUS_MESSAGE_MAP,
  ItemStatusData,
  HoldDto,
  LoanDto,
} from '../constants';
import { getCheckoutsStatusMessage } from './getCheckoutsStatusesData';

export type ItemWithStatus = HoldDto | LoanDto;

const convertStatusToStatusData = (data, intl, locale) => {
  if (Object.keys(HOLD_STATUS_MESSAGE_MAP).includes(data.status)) {
    return {
      icon: STATUS_ICON[ITEM_STATUS.onHold],
      status: ITEM_STATUS.onHold,
      statusMessage: intl.$t(HOLD_STATUS_MESSAGE_MAP[data.status], {
        value: data.queuePosition,
      }),
      actionButtonText: intl.$t(buttonMessages.goToBookshelf),
    };
  }

  const status = data.overdue ? ITEM_STATUS.overdue : ITEM_STATUS.checkedOut;
  const date = getFormattedDate({ date: data.dueDate, locale });
  const isoCurrencyCode = data.isoCurrencyCode;
  const feesValue =
    data.amount &&
    isoCurrencyCode &&
    intl.formatNumber(data.amount, {
      style: 'currency',
      currency: isoCurrencyCode,
    });

  return {
    icon: STATUS_ICON[status],
    status,
    statusMessage: getCheckoutsStatusMessage({
      date,
      intl,
    }),
    actionButtonText: intl.$t(buttonMessages.goToBookshelf),
    feesTitle: intl.$t(checkoutMessages.fees),
    feesValue: feesValue || '',
  };
};

export const getItemStatusData = (
  itemWithStatus: ItemWithStatus,
  intl: IntlShape,
  locale: string
): ItemStatusData => {
  const { item, ...rest } = itemWithStatus;
  const convertedStatusData = convertStatusToStatusData(rest, intl, locale);

  return {
    instanceId: item.instanceId,
    itemId: item.itemId,
    ...convertedStatusData,
  };
};
