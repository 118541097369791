import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Dropdown } from '@app/components';
import { OPERATOR, SEARCH_OPTION } from '@app/search';
import { sharedMessages } from '@app/translations';

import css from './FieldsDropdown.module.scss';

export const FIELDS = [
  SEARCH_OPTION.keyword,
  SEARCH_OPTION.title,
  SEARCH_OPTION.author,
  SEARCH_OPTION.isbn,
  SEARCH_OPTION.subject,
];

export const FIELDS_SEARCH_MAP = {
  [SEARCH_OPTION.keyword]: `keyword ${OPERATOR.ALL} `,
  [SEARCH_OPTION.title]: `title${OPERATOR.EQUAL}`,
  [SEARCH_OPTION.author]: `contributors${OPERATOR.EQUAL}`,
  [SEARCH_OPTION.isbn]: `isbns${OPERATOR.EQUAL}`,
  [SEARCH_OPTION.subject]: `subjects.value${OPERATOR.EQUAL}`,
};

interface FieldsDropdownProps {
  option: SEARCH_OPTION;
  id: string;
  onSelect: (updatedField: SEARCH_OPTION) => void;
}

export const FieldsDropdown: FC<FieldsDropdownProps> = ({
  option = SEARCH_OPTION.keyword,
  id,
  onSelect,
}) => {
  const { $t } = useIntl();

  const options = useMemo(
    () =>
      FIELDS.map(field => ({
        label: $t(sharedMessages[field]),
        value: field,
      })),
    [$t]
  );

  return (
    <Dropdown
      id={id}
      items={options}
      hideLabel
      styleType="standard"
      selectedItem={{
        label: $t(sharedMessages[option]),
        value: option,
      }}
      className={css.dropdown}
      onSelectedItemChange={({ selectedItem }) =>
        onSelect(selectedItem?.value as SEARCH_OPTION)
      }
    />
  );
};
