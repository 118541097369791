import { To } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

import { URLQuery } from '@app/constants';

interface PushParams {
  pathname: string;
  query?: URLQuery;
}

interface RouteParams {
  ({ pathname, query }: PushParams): To;
}

export const generateRouteParams: RouteParams = ({ pathname, query }) => ({
  pathname,
  ...(query &&
    !isEmpty(query) && {
      search: `?${queryString.stringify(query)}`,
    }),
});
