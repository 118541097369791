import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { axios } from '@app/utils';

export const useResetPinStep1Mutation = (): UseMutationResult<
  unknown,
  unknown,
  { barcode: string }
> =>
  useMutation(['resetPinStep1'], barcode =>
    axios
      .post<void>('/opac-users/reset-pin', { barcode })
      .then(response => response.data)
  );

export const useResetPinStep2Mutation = (): UseMutationResult<
  unknown,
  unknown,
  {
    pin: number;
    token: string;
  }
> =>
  useMutation(['resetPinStep2'], ({ pin, token }) =>
    axios
      .post<void>(
        '/opac-users/patron-pin',
        { pin },
        { headers: { 'X-Okapi-Token': token } }
      )
      .then(response => response.data)
  );

export const useValidateBarcodeMutation = (): UseMutationResult<
  unknown,
  unknown,
  string
> =>
  useMutation(['validateBarcode'], token =>
    axios
      .post<void>('/opac-auth/credential-reset-action/validate', undefined, {
        headers: { 'X-Okapi-Token': token },
      })
      .then(response => response.data)
  );
