import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { Button, createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { Icon, StepsBar } from '@app/components';
import { sharedMessages } from '@app/translations';
import { ROUTE } from '@app/constants';

import '../../../components/AuthPageWrapper/AuthPageWrapper.scss';

const cnBem = createBemBlockBuilder(['authPageWrapper']);

export const ResetPinSuccess: FC = () => {
  const { $t } = useIntl();

  return (
    <div
      data-testid="reset-pin-success"
      className={cn(cnBem('__form'), cnBem('__resetPinForm'))}
    >
      <StepsBar stepsAmount={3} currentStepIndex={2} />
      <div className={cnBem('__resetSuccess')}>
        <Icon icon={faCheckCircle} />
        <h2>
          {$t({
            id: 'resetPin.pinReset',
            defaultMessage: 'PIN reset',
          })}
        </h2>
        <div>
          {$t({
            id: 'resetPin.pinHasBeenReset',
            defaultMessage:
              'Your PIN has been reset. Please sign in with your new PIN.',
          })}
        </div>
        <Link to={ROUTE.login}>
          <Button styleType="solid">{$t(sharedMessages.signIn)}</Button>
        </Link>
      </div>
    </div>
  );
};
