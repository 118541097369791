import { FC } from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import './Carousel.scss';

interface CarouselButtonsProps {
  currentSlideIndex: number;
  lastVisibleSlideIndex: number;
  setCurrentSlide: (currentSlide: number) => void;
  onPreviousButtonClick: () => void;
  onNextButtonClick: () => void;
}

const cnBem = createBemBlockBuilder(['carousel']);

export const CarouselButtons: FC<CarouselButtonsProps> = ({
  currentSlideIndex,
  lastVisibleSlideIndex,
  setCurrentSlide,
  onPreviousButtonClick,
  onNextButtonClick,
}) => {
  const { $t } = useIntl();

  const prevSlide = currentSlideIndex === 0 ? 0 : currentSlideIndex - 1;
  const nextSlide =
    currentSlideIndex === lastVisibleSlideIndex
      ? lastVisibleSlideIndex
      : currentSlideIndex + 1;

  return (
    <div className={cnBem('__buttons')}>
      <button
        type="button"
        className={cnBem('__button')}
        aria-label={$t({
          id: 'carousel.prevButton',
          defaultMessage: 'go to previous slide',
        })}
        disabled={currentSlideIndex === 0}
        onClick={() => {
          setCurrentSlide(prevSlide);
          onPreviousButtonClick();
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button
        type="button"
        className={cnBem('__button')}
        aria-label={$t({
          id: 'carousel.nextButton',
          defaultMessage: 'go to next slide',
        })}
        disabled={currentSlideIndex === lastVisibleSlideIndex}
        onClick={() => {
          setCurrentSlide(nextSlide);
          onNextButtonClick();
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};
