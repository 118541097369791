import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { BackToRoute, QuickMarkView } from '@app/components';
import { useInstanceDetailsRoutePath } from '@app/hooks';
import { sharedMessages } from '@app/translations';

import './StaffViewPage.scss';

const cnBem = createBemBlockBuilder(['staffView']);

export const StaffViewPage: FC = () => {
  const { $t } = useIntl();
  const params = useParams();
  const { getInstanceDetailsRoutePath } = useInstanceDetailsRoutePath();

  const { pathname, search } = getInstanceDetailsRoutePath(params.id as string);

  return (
    <div className={cn(cnBem(), 'container')}>
      <Helmet>
        <title>
          {$t({
            id: 'staffView.documentTitle',
            defaultMessage: 'Staff view - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <BackToRoute
        to={`${pathname}/?${search}`}
        id="marcRecord.backToItem"
        defaultMessage="Back to item"
      />
      <h1>{$t(sharedMessages.staffView)}</h1>
      <QuickMarkView />
    </div>
  );
};
