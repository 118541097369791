import { defineMessages } from 'react-intl';

export const buttonMessages = defineMessages({
  removeHold: {
    id: 'itemStatus.removeHold',
    defaultMessage: 'Remove hold',
  },
  renew: {
    id: 'itemStatus.renew',
    defaultMessage: 'Renew',
  },
  goToBookshelf: {
    id: 'itemStatus.goToBookshelf',
    defaultMessage: 'Go to bookshelf',
  },
});
