import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isEmpty, omit } from 'lodash';
import cn from 'classnames';

import { Button } from '@ebsco-ui/ebsco-ui';

import {
  Navigation,
  SearchOptionsDropdown,
  AutoComplete,
  AdvancedSearchModal,
} from '@app/components';
import { useSearchContext } from '@app/contexts';
import { useBranding, useQueryParams } from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { sharedMessages } from '@app/translations';

import css from './Header.module.scss';

export const Header: FC = () => {
  const [isAdvancedSearchModalOpen, setIsAdvancedSearchModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const queryParams = useQueryParams();
  const ADVANCED_SEARCH_QUERY_PARAM = 'advancedSearch';

  const { searchBar } = useBranding();
  const {
    state: { query },
    searchOption,
    setSearchOption,
  } = useSearchContext();
  const { $t } = useIntl();

  useEffect(() => {
    setIsAdvancedSearchModalOpen(
      search.includes(`${ADVANCED_SEARCH_QUERY_PARAM}=true`)
    );
  }, [search]);

  const handleSortOptionsSelect = useCallback(
    ({ value }) => {
      setSearchOption(value);
    },
    [setSearchOption]
  );

  const handleUrlQuery = ({
    isModalOpen = false,
  }: {
    isModalOpen?: boolean;
  } = {}) => {
    navigate(
      generateRouteParams({
        pathname,
        query: {
          ...(!isEmpty(queryParams) &&
            omit(queryParams, ADVANCED_SEARCH_QUERY_PARAM)),
          ...(isModalOpen && {
            [ADVANCED_SEARCH_QUERY_PARAM]: `${isModalOpen}`,
          }),
        },
      })
    );
  };

  return (
    <header
      aria-label={$t({
        id: 'navigation.title',
        defaultMessage: 'Site navigation and search',
      })}
    >
      <Navigation />
      <div className={css.searchBarWrapper}>
        <div
          className={cn(css.searchBar, 'container')}
          role="search"
          data-testid="search-bar"
        >
          <SearchOptionsDropdown
            option={searchOption}
            onSelect={handleSortOptionsSelect}
          />
          <AutoComplete value={query} placeholder={searchBar.text} />
          <Button
            styleType="flat-icon"
            className={css.advancedSearchButton}
            aria-expanded={isAdvancedSearchModalOpen}
            onClick={() => {
              setIsAdvancedSearchModalOpen(true);
              handleUrlQuery({ isModalOpen: true });
            }}
          >
            {$t(sharedMessages.advancedSearch)}
          </Button>
        </div>
      </div>
      <AdvancedSearchModal
        isOpen={isAdvancedSearchModalOpen}
        onModalClose={() => {
          setIsAdvancedSearchModalOpen(false);
          handleUrlQuery();
        }}
      />
    </header>
  );
};
