import { defineMessages } from 'react-intl';

export const langMessages = defineMessages({
  undefined: {
    id: 'languages.undetermined',
    defaultMessage: 'Undetermined',
  },
  aar: {
    id: 'languages.aar',
    defaultMessage: 'Afar',
  },
  abk: {
    id: 'languages.abk',
    defaultMessage: 'Abkhazian',
  },
  ace: {
    id: 'languages.ace',
    defaultMessage: 'Achinese',
  },
  ach: {
    id: 'languages.ach',
    defaultMessage: 'Acoli',
  },
  ada: {
    id: 'languages.ada',
    defaultMessage: 'Adangme',
  },
  ady: {
    id: 'languages.ady',
    defaultMessage: 'Adyghe; Adygei',
  },
  afa: {
    id: 'languages.afa',
    defaultMessage: 'Afro-Asiatic languages',
  },
  afh: {
    id: 'languages.afh',
    defaultMessage: 'Afrihili',
  },
  afr: {
    id: 'languages.afr',
    defaultMessage: 'Afrikaans',
  },
  ain: {
    id: 'languages.ain',
    defaultMessage: 'Ainu',
  },
  aka: {
    id: 'languages.aka',
    defaultMessage: 'Akan',
  },
  akk: {
    id: 'languages.akk',
    defaultMessage: 'Akkadian',
  },
  alb: {
    id: 'languages.alb',
    defaultMessage: 'Albanian',
  },
  ale: {
    id: 'languages.ale',
    defaultMessage: 'Aleut',
  },
  alg: {
    id: 'languages.alg',
    defaultMessage: 'Algonquian languages',
  },
  alt: {
    id: 'languages.alt',
    defaultMessage: 'Southern Altai',
  },
  amh: {
    id: 'languages.amh',
    defaultMessage: 'Amharic',
  },
  ang: {
    id: 'languages.ang',
    defaultMessage: 'English, Old (ca.450-1100)',
  },
  anp: {
    id: 'languages.anp',
    defaultMessage: 'Angika',
  },
  apa: {
    id: 'languages.apa',
    defaultMessage: 'Apache languages',
  },
  ara: {
    id: 'languages.ara',
    defaultMessage: 'Arabic',
  },
  arc: {
    id: 'languages.arc',
    defaultMessage:
      'Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)',
  },
  arg: {
    id: 'languages.arg',
    defaultMessage: 'Aragonese',
  },
  arm: {
    id: 'languages.arm',
    defaultMessage: 'Armenian',
  },
  arn: {
    id: 'languages.arn',
    defaultMessage: 'Mapudungun; Mapuche',
  },
  arp: {
    id: 'languages.arp',
    defaultMessage: 'Arapaho',
  },
  art: {
    id: 'languages.art',
    defaultMessage: 'Artificial languages',
  },
  arw: {
    id: 'languages.arw',
    defaultMessage: 'Arawak',
  },
  asm: {
    id: 'languages.asm',
    defaultMessage: 'Assamese',
  },
  ast: {
    id: 'languages.ast',
    defaultMessage: 'Asturian; Bable; Leonese; Asturleonese',
  },
  ath: {
    id: 'languages.ath',
    defaultMessage: 'Athapascan languages',
  },
  aus: {
    id: 'languages.aus',
    defaultMessage: 'Australian languages',
  },
  ava: {
    id: 'languages.ava',
    defaultMessage: 'Avaric',
  },
  ave: {
    id: 'languages.ave',
    defaultMessage: 'Avestan',
  },
  awa: {
    id: 'languages.awa',
    defaultMessage: 'Awadhi',
  },
  aym: {
    id: 'languages.aym',
    defaultMessage: 'Aymara',
  },
  aze: {
    id: 'languages.aze',
    defaultMessage: 'Azerbaijani',
  },
  bad: {
    id: 'languages.bad',
    defaultMessage: 'Banda languages',
  },
  bai: {
    id: 'languages.bai',
    defaultMessage: 'Bamileke languages',
  },
  bak: {
    id: 'languages.bak',
    defaultMessage: 'Bashkir',
  },
  bal: {
    id: 'languages.bal',
    defaultMessage: 'Baluchi',
  },
  bam: {
    id: 'languages.bam',
    defaultMessage: 'Bambara',
  },
  ban: {
    id: 'languages.ban',
    defaultMessage: 'Balinese',
  },
  baq: {
    id: 'languages.baq',
    defaultMessage: 'Basque',
  },
  bas: {
    id: 'languages.bas',
    defaultMessage: 'Basa',
  },
  bat: {
    id: 'languages.bat',
    defaultMessage: 'Baltic languages',
  },
  bej: {
    id: 'languages.bej',
    defaultMessage: 'Beja; Bedawiyet',
  },
  bel: {
    id: 'languages.bel',
    defaultMessage: 'Belarusian',
  },
  bem: {
    id: 'languages.bem',
    defaultMessage: 'Bemba',
  },
  ben: {
    id: 'languages.ben',
    defaultMessage: 'Bengali',
  },
  ber: {
    id: 'languages.ber',
    defaultMessage: 'Berber languages',
  },
  bho: {
    id: 'languages.bho',
    defaultMessage: 'Bhojpuri',
  },
  bih: {
    id: 'languages.bih',
    defaultMessage: 'Bihari languages',
  },
  bik: {
    id: 'languages.bik',
    defaultMessage: 'Bikol',
  },
  bin: {
    id: 'languages.bin',
    defaultMessage: 'Bini; Edo',
  },
  bis: {
    id: 'languages.bis',
    defaultMessage: 'Bislama',
  },
  bla: {
    id: 'languages.bla',
    defaultMessage: 'Siksika',
  },
  bnt: {
    id: 'languages.bnt',
    defaultMessage: 'Bantu languages',
  },
  bos: {
    id: 'languages.bos',
    defaultMessage: 'Bosnian',
  },
  bra: {
    id: 'languages.bra',
    defaultMessage: 'Braj',
  },
  bre: {
    id: 'languages.bre',
    defaultMessage: 'Breton',
  },
  btk: {
    id: 'languages.btk',
    defaultMessage: 'Batak languages',
  },
  bua: {
    id: 'languages.bua',
    defaultMessage: 'Buriat',
  },
  bug: {
    id: 'languages.bug',
    defaultMessage: 'Buginese',
  },
  bul: {
    id: 'languages.bul',
    defaultMessage: 'Bulgarian',
  },
  bur: {
    id: 'languages.bur',
    defaultMessage: 'Burmese',
  },
  byn: {
    id: 'languages.byn',
    defaultMessage: 'Blin; Bilin',
  },
  cad: {
    id: 'languages.cad',
    defaultMessage: 'Caddo',
  },
  cai: {
    id: 'languages.cai',
    defaultMessage: 'Central American Indian languages',
  },
  car: {
    id: 'languages.car',
    defaultMessage: 'Galibi Carib',
  },
  cat: {
    id: 'languages.cat',
    defaultMessage: 'Catalan; Valencian',
  },
  cau: {
    id: 'languages.cau',
    defaultMessage: 'Caucasian languages',
  },
  ceb: {
    id: 'languages.ceb',
    defaultMessage: 'Cebuano',
  },
  cel: {
    id: 'languages.cel',
    defaultMessage: 'Celtic languages',
  },
  cha: {
    id: 'languages.cha',
    defaultMessage: 'Chamorro',
  },
  chb: {
    id: 'languages.chb',
    defaultMessage: 'Chibcha',
  },
  che: {
    id: 'languages.che',
    defaultMessage: 'Chechen',
  },
  chg: {
    id: 'languages.chg',
    defaultMessage: 'Chagatai',
  },
  chi: {
    id: 'languages.chi',
    defaultMessage: 'Chinese',
  },
  chk: {
    id: 'languages.chk',
    defaultMessage: 'Chuukese',
  },
  chm: {
    id: 'languages.chm',
    defaultMessage: 'Mari',
  },
  chn: {
    id: 'languages.chn',
    defaultMessage: 'Chinook jargon',
  },
  cho: {
    id: 'languages.cho',
    defaultMessage: 'Choctaw',
  },
  chp: {
    id: 'languages.chp',
    defaultMessage: 'Chipewyan; Dene Suline',
  },
  chr: {
    id: 'languages.chr',
    defaultMessage: 'Cherokee',
  },
  chu: {
    id: 'languages.chu',
    defaultMessage:
      'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  },
  chv: {
    id: 'languages.chv',
    defaultMessage: 'Chuvash',
  },
  chy: {
    id: 'languages.chy',
    defaultMessage: 'Cheyenne',
  },
  cmc: {
    id: 'languages.cmc',
    defaultMessage: 'Chamic languages',
  },
  cnr: {
    id: 'languages.cnr',
    defaultMessage: 'Montenegrin',
  },
  cop: {
    id: 'languages.cop',
    defaultMessage: 'Coptic',
  },
  cor: {
    id: 'languages.cor',
    defaultMessage: 'Cornish',
  },
  cos: {
    id: 'languages.cos',
    defaultMessage: 'Corsican',
  },
  cpe: {
    id: 'languages.cpe',
    defaultMessage: 'Creoles and pidgins, English based',
  },
  cpf: {
    id: 'languages.cpf',
    defaultMessage: 'Creoles and pidgins, French-based',
  },
  cpp: {
    id: 'languages.cpp',
    defaultMessage: 'Creoles and pidgins, Portuguese-based',
  },
  cre: {
    id: 'languages.cre',
    defaultMessage: 'Cree',
  },
  crh: {
    id: 'languages.crh',
    defaultMessage: 'Crimean Tatar; Crimean Turkish',
  },
  crp: {
    id: 'languages.crp',
    defaultMessage: 'Creoles and pidgins',
  },
  csb: {
    id: 'languages.csb',
    defaultMessage: 'Kashubian',
  },
  cus: {
    id: 'languages.cus',
    defaultMessage: 'Cushitic languages',
  },
  cze: {
    id: 'languages.cze',
    defaultMessage: 'Czech',
  },
  dak: {
    id: 'languages.dak',
    defaultMessage: 'Dakota',
  },
  dan: {
    id: 'languages.dan',
    defaultMessage: 'Danish',
  },
  dar: {
    id: 'languages.dar',
    defaultMessage: 'Dargwa',
  },
  day: {
    id: 'languages.day',
    defaultMessage: 'Land Dayak languages',
  },
  del: {
    id: 'languages.del',
    defaultMessage: 'Delaware',
  },
  den: {
    id: 'languages.den',
    defaultMessage: 'Slave (Athapascan)',
  },
  dgr: {
    id: 'languages.dgr',
    defaultMessage: 'Dogrib',
  },
  din: {
    id: 'languages.din',
    defaultMessage: 'Dinka',
  },
  div: {
    id: 'languages.div',
    defaultMessage: 'Divehi; Dhivehi; Maldivian',
  },
  doi: {
    id: 'languages.doi',
    defaultMessage: 'Dogri',
  },
  dra: {
    id: 'languages.dra',
    defaultMessage: 'Dravidian languages',
  },
  dsb: {
    id: 'languages.dsb',
    defaultMessage: 'Lower Sorbian',
  },
  dua: {
    id: 'languages.dua',
    defaultMessage: 'Duala',
  },
  dum: {
    id: 'languages.dum',
    defaultMessage: 'Dutch, Middle (ca.1050-1350)',
  },
  dut: {
    id: 'languages.dut',
    defaultMessage: 'Dutch; Flemish',
  },
  dyu: {
    id: 'languages.dyu',
    defaultMessage: 'Dyula',
  },
  dzo: {
    id: 'languages.dzo',
    defaultMessage: 'Dzongkha',
  },
  efi: {
    id: 'languages.efi',
    defaultMessage: 'Efik',
  },
  egy: {
    id: 'languages.egy',
    defaultMessage: 'Egyptian (Ancient)',
  },
  eka: {
    id: 'languages.eka',
    defaultMessage: 'Ekajuk',
  },
  elx: {
    id: 'languages.elx',
    defaultMessage: 'Elamite',
  },
  eng: {
    id: 'languages.eng',
    defaultMessage: 'English',
  },
  enm: {
    id: 'languages.enm',
    defaultMessage: 'English, Middle (1100-1500)',
  },
  epo: {
    id: 'languages.epo',
    defaultMessage: 'Esperanto',
  },
  est: {
    id: 'languages.est',
    defaultMessage: 'Estonian',
  },
  ewe: {
    id: 'languages.ewe',
    defaultMessage: 'Ewe',
  },
  ewo: {
    id: 'languages.ewo',
    defaultMessage: 'Ewondo',
  },
  fan: {
    id: 'languages.fan',
    defaultMessage: 'Fang',
  },
  fao: {
    id: 'languages.fao',
    defaultMessage: 'Faroese',
  },
  fat: {
    id: 'languages.fat',
    defaultMessage: 'Fanti',
  },
  fij: {
    id: 'languages.fij',
    defaultMessage: 'Fijian',
  },
  fil: {
    id: 'languages.fil',
    defaultMessage: 'Filipino; Pilipino',
  },
  fin: {
    id: 'languages.fin',
    defaultMessage: 'Finnish',
  },
  fiu: {
    id: 'languages.fiu',
    defaultMessage: 'Finno-Ugrian languages',
  },
  fon: {
    id: 'languages.fon',
    defaultMessage: 'Fon',
  },
  fre: {
    id: 'languages.fre',
    defaultMessage: 'French',
  },
  frm: {
    id: 'languages.frm',
    defaultMessage: 'French, Middle (ca.1400-1600)',
  },
  fro: {
    id: 'languages.fro',
    defaultMessage: 'French, Old (842-ca.1400)',
  },
  frr: {
    id: 'languages.frr',
    defaultMessage: 'Northern Frisian',
  },
  frs: {
    id: 'languages.frs',
    defaultMessage: 'Eastern Frisian',
  },
  fry: {
    id: 'languages.fry',
    defaultMessage: 'Western Frisian',
  },
  ful: {
    id: 'languages.ful',
    defaultMessage: 'Fulah',
  },
  fur: {
    id: 'languages.fur',
    defaultMessage: 'Friulian',
  },
  gaa: {
    id: 'languages.gaa',
    defaultMessage: 'Ga',
  },
  gay: {
    id: 'languages.gay',
    defaultMessage: 'Gayo',
  },
  gba: {
    id: 'languages.gba',
    defaultMessage: 'Gbaya',
  },
  gem: {
    id: 'languages.gem',
    defaultMessage: 'Germanic languages',
  },
  geo: {
    id: 'languages.geo',
    defaultMessage: 'Georgian',
  },
  ger: {
    id: 'languages.ger',
    defaultMessage: 'German',
  },
  gez: {
    id: 'languages.gez',
    defaultMessage: 'Geez',
  },
  gil: {
    id: 'languages.gil',
    defaultMessage: 'Gilbertese',
  },
  gla: {
    id: 'languages.gla',
    defaultMessage: 'Gaelic; Scottish Gaelic',
  },
  gle: {
    id: 'languages.gle',
    defaultMessage: 'Irish',
  },
  glg: {
    id: 'languages.glg',
    defaultMessage: 'Galician',
  },
  glv: {
    id: 'languages.glv',
    defaultMessage: 'Manx',
  },
  gmh: {
    id: 'languages.gmh',
    defaultMessage: 'German, Middle High (ca.1050-1500)',
  },
  goh: {
    id: 'languages.goh',
    defaultMessage: 'German, Old High (ca.750-1050)',
  },
  gon: {
    id: 'languages.gon',
    defaultMessage: 'Gondi',
  },
  gor: {
    id: 'languages.gor',
    defaultMessage: 'Gorontalo',
  },
  got: {
    id: 'languages.got',
    defaultMessage: 'Gothic',
  },
  grb: {
    id: 'languages.grb',
    defaultMessage: 'Grebo',
  },
  grc: {
    id: 'languages.grc',
    defaultMessage: 'Greek, Ancient (to 1453)',
  },
  gre: {
    id: 'languages.gre',
    defaultMessage: 'Greek, Modern (1453-)',
  },
  grn: {
    id: 'languages.grn',
    defaultMessage: 'Guarani',
  },
  gsw: {
    id: 'languages.gsw',
    defaultMessage: 'Swiss German; Alemannic; Alsatian',
  },
  guj: {
    id: 'languages.guj',
    defaultMessage: 'Gujarati',
  },
  gwi: {
    id: 'languages.gwi',
    defaultMessage: "Gwich'in",
  },
  hai: {
    id: 'languages.hai',
    defaultMessage: 'Haida',
  },
  hat: {
    id: 'languages.hat',
    defaultMessage: 'Haitian; Haitian Creole',
  },
  hau: {
    id: 'languages.hau',
    defaultMessage: 'Hausa',
  },
  haw: {
    id: 'languages.haw',
    defaultMessage: 'Hawaiian',
  },
  heb: {
    id: 'languages.heb',
    defaultMessage: 'Hebrew',
  },
  her: {
    id: 'languages.her',
    defaultMessage: 'Herero',
  },
  hil: {
    id: 'languages.hil',
    defaultMessage: 'Hiligaynon',
  },
  him: {
    id: 'languages.him',
    defaultMessage: 'Himachali languages; Western Pahari languages',
  },
  hin: {
    id: 'languages.hin',
    defaultMessage: 'Hindi',
  },
  hit: {
    id: 'languages.hit',
    defaultMessage: 'Hittite',
  },
  hmn: {
    id: 'languages.hmn',
    defaultMessage: 'Hmong; Mong',
  },
  hmo: {
    id: 'languages.hmo',
    defaultMessage: 'Hiri Motu',
  },
  hrv: {
    id: 'languages.hrv',
    defaultMessage: 'Croatian',
  },
  hsb: {
    id: 'languages.hsb',
    defaultMessage: 'Upper Sorbian',
  },
  hun: {
    id: 'languages.hun',
    defaultMessage: 'Hungarian',
  },
  hup: {
    id: 'languages.hup',
    defaultMessage: 'Hupa',
  },
  iba: {
    id: 'languages.iba',
    defaultMessage: 'Iban',
  },
  ibo: {
    id: 'languages.ibo',
    defaultMessage: 'Igbo',
  },
  ice: {
    id: 'languages.ice',
    defaultMessage: 'Icelandic',
  },
  ido: {
    id: 'languages.ido',
    defaultMessage: 'Ido',
  },
  iii: {
    id: 'languages.iii',
    defaultMessage: 'Sichuan Yi; Nuosu',
  },
  ijo: {
    id: 'languages.ijo',
    defaultMessage: 'Ijo languages',
  },
  iku: {
    id: 'languages.iku',
    defaultMessage: 'Inuktitut',
  },
  ile: {
    id: 'languages.ile',
    defaultMessage: 'Interlingue; Occidental',
  },
  ilo: {
    id: 'languages.ilo',
    defaultMessage: 'Iloko',
  },
  ina: {
    id: 'languages.ina',
    defaultMessage:
      'Interlingua (International Auxiliary Language Association)',
  },
  inc: {
    id: 'languages.inc',
    defaultMessage: 'Indic languages',
  },
  ind: {
    id: 'languages.ind',
    defaultMessage: 'Indonesian',
  },
  ine: {
    id: 'languages.ine',
    defaultMessage: 'Indo-European languages',
  },
  inh: {
    id: 'languages.inh',
    defaultMessage: 'Ingush',
  },
  ipk: {
    id: 'languages.ipk',
    defaultMessage: 'Inupiaq',
  },
  ira: {
    id: 'languages.ira',
    defaultMessage: 'Iranian languages',
  },
  iro: {
    id: 'languages.iro',
    defaultMessage: 'Iroquoian languages',
  },
  ita: {
    id: 'languages.ita',
    defaultMessage: 'Italian',
  },
  jav: {
    id: 'languages.jav',
    defaultMessage: 'Javanese',
  },
  jbo: {
    id: 'languages.jbo',
    defaultMessage: 'Lojban',
  },
  jpn: {
    id: 'languages.jpn',
    defaultMessage: 'Japanese',
  },
  jpr: {
    id: 'languages.jpr',
    defaultMessage: 'Judeo-Persian',
  },
  jrb: {
    id: 'languages.jrb',
    defaultMessage: 'Judeo-Arabic',
  },
  kaa: {
    id: 'languages.kaa',
    defaultMessage: 'Kara-Kalpak',
  },
  kab: {
    id: 'languages.kab',
    defaultMessage: 'Kabyle',
  },
  kac: {
    id: 'languages.kac',
    defaultMessage: 'Kachin; Jingpho',
  },
  kal: {
    id: 'languages.kal',
    defaultMessage: 'Kalaallisut; Greenlandic',
  },
  kam: {
    id: 'languages.kam',
    defaultMessage: 'Kamba',
  },
  kan: {
    id: 'languages.kan',
    defaultMessage: 'Kannada',
  },
  kar: {
    id: 'languages.kar',
    defaultMessage: 'Karen languages',
  },
  kas: {
    id: 'languages.kas',
    defaultMessage: 'Kashmiri',
  },
  kau: {
    id: 'languages.kau',
    defaultMessage: 'Kanuri',
  },
  kaw: {
    id: 'languages.kaw',
    defaultMessage: 'Kawi',
  },
  kaz: {
    id: 'languages.kaz',
    defaultMessage: 'Kazakh',
  },
  kbd: {
    id: 'languages.kbd',
    defaultMessage: 'Kabardian',
  },
  kha: {
    id: 'languages.kha',
    defaultMessage: 'Khasi',
  },
  khi: {
    id: 'languages.khi',
    defaultMessage: 'Khoisan languages',
  },
  khm: {
    id: 'languages.khm',
    defaultMessage: 'Central Khmer',
  },
  kho: {
    id: 'languages.kho',
    defaultMessage: 'Khotanese; Sakan',
  },
  kik: {
    id: 'languages.kik',
    defaultMessage: 'Kikuyu; Gikuyu',
  },
  kin: {
    id: 'languages.kin',
    defaultMessage: 'Kinyarwanda',
  },
  kir: {
    id: 'languages.kir',
    defaultMessage: 'Kirghiz; Kyrgyz',
  },
  kmb: {
    id: 'languages.kmb',
    defaultMessage: 'Kimbundu',
  },
  kok: {
    id: 'languages.kok',
    defaultMessage: 'Konkani',
  },
  kom: {
    id: 'languages.kom',
    defaultMessage: 'Komi',
  },
  kon: {
    id: 'languages.kon',
    defaultMessage: 'Kongo',
  },
  kor: {
    id: 'languages.kor',
    defaultMessage: 'Korean',
  },
  kos: {
    id: 'languages.kos',
    defaultMessage: 'Kosraean',
  },
  kpe: {
    id: 'languages.kpe',
    defaultMessage: 'Kpelle',
  },
  krc: {
    id: 'languages.krc',
    defaultMessage: 'Karachay-Balkar',
  },
  krl: {
    id: 'languages.krl',
    defaultMessage: 'Karelian',
  },
  kro: {
    id: 'languages.kro',
    defaultMessage: 'Kru languages',
  },
  kru: {
    id: 'languages.kru',
    defaultMessage: 'Kurukh',
  },
  kua: {
    id: 'languages.kua',
    defaultMessage: 'Kuanyama; Kwanyama',
  },
  kum: {
    id: 'languages.kum',
    defaultMessage: 'Kumyk',
  },
  kur: {
    id: 'languages.kur',
    defaultMessage: 'Kurdish',
  },
  kut: {
    id: 'languages.kut',
    defaultMessage: 'Kutenai',
  },
  lad: {
    id: 'languages.lad',
    defaultMessage: 'Ladino',
  },
  lah: {
    id: 'languages.lah',
    defaultMessage: 'Lahnda',
  },
  lam: {
    id: 'languages.lam',
    defaultMessage: 'Lamba',
  },
  lao: {
    id: 'languages.lao',
    defaultMessage: 'Lao',
  },
  lat: {
    id: 'languages.lat',
    defaultMessage: 'Latin',
  },
  lav: {
    id: 'languages.lav',
    defaultMessage: 'Latvian',
  },
  lez: {
    id: 'languages.lez',
    defaultMessage: 'Lezghian',
  },
  lim: {
    id: 'languages.lim',
    defaultMessage: 'Limburgan; Limburger; Limburgish',
  },
  lin: {
    id: 'languages.lin',
    defaultMessage: 'Lingala',
  },
  lit: {
    id: 'languages.lit',
    defaultMessage: 'Lithuanian',
  },
  lol: {
    id: 'languages.lol',
    defaultMessage: 'Mongo',
  },
  loz: {
    id: 'languages.loz',
    defaultMessage: 'Lozi',
  },
  ltz: {
    id: 'languages.ltz',
    defaultMessage: 'Luxembourgish; Letzeburgesch',
  },
  lua: {
    id: 'languages.lua',
    defaultMessage: 'Luba-Lulua',
  },
  lub: {
    id: 'languages.lub',
    defaultMessage: 'Luba-Katanga',
  },
  lug: {
    id: 'languages.lug',
    defaultMessage: 'Ganda',
  },
  lui: {
    id: 'languages.lui',
    defaultMessage: 'Luiseno',
  },
  lun: {
    id: 'languages.lun',
    defaultMessage: 'Lunda',
  },
  luo: {
    id: 'languages.luo',
    defaultMessage: 'Luo (Kenya and Tanzania)',
  },
  lus: {
    id: 'languages.lus',
    defaultMessage: 'Lushai',
  },
  mac: {
    id: 'languages.mac',
    defaultMessage: 'Macedonian',
  },
  mad: {
    id: 'languages.mad',
    defaultMessage: 'Madurese',
  },
  mag: {
    id: 'languages.mag',
    defaultMessage: 'Magahi',
  },
  mah: {
    id: 'languages.mah',
    defaultMessage: 'Marshallese',
  },
  mai: {
    id: 'languages.mai',
    defaultMessage: 'Maithili',
  },
  mak: {
    id: 'languages.mak',
    defaultMessage: 'Makasar',
  },
  mal: {
    id: 'languages.mal',
    defaultMessage: 'Malayalam',
  },
  man: {
    id: 'languages.man',
    defaultMessage: 'Mandingo',
  },
  mao: {
    id: 'languages.mao',
    defaultMessage: 'Maori',
  },
  map: {
    id: 'languages.map',
    defaultMessage: 'Austronesian languages',
  },
  mar: {
    id: 'languages.mar',
    defaultMessage: 'Marathi',
  },
  mas: {
    id: 'languages.mas',
    defaultMessage: 'Masai',
  },
  may: {
    id: 'languages.may',
    defaultMessage: 'Malay',
  },
  mdf: {
    id: 'languages.mdf',
    defaultMessage: 'Moksha',
  },
  mdr: {
    id: 'languages.mdr',
    defaultMessage: 'Mandar',
  },
  men: {
    id: 'languages.men',
    defaultMessage: 'Mende',
  },
  mga: {
    id: 'languages.mga',
    defaultMessage: 'Irish, Middle (900-1200)',
  },
  mic: {
    id: 'languages.mic',
    defaultMessage: "Mi'kmaq; Micmac",
  },
  min: {
    id: 'languages.min',
    defaultMessage: 'Minangkabau',
  },
  mis: {
    id: 'languages.mis',
    defaultMessage: 'Uncoded languages',
  },
  mkh: {
    id: 'languages.mkh',
    defaultMessage: 'Mon-Khmer languages',
  },
  mlg: {
    id: 'languages.mlg',
    defaultMessage: 'Malagasy',
  },
  mlt: {
    id: 'languages.mlt',
    defaultMessage: 'Maltese',
  },
  mnc: {
    id: 'languages.mnc',
    defaultMessage: 'Manchu',
  },
  mni: {
    id: 'languages.mni',
    defaultMessage: 'Manipuri',
  },
  mno: {
    id: 'languages.mno',
    defaultMessage: 'Manobo languages',
  },
  moh: {
    id: 'languages.moh',
    defaultMessage: 'Mohawk',
  },
  mon: {
    id: 'languages.mon',
    defaultMessage: 'Mongolian',
  },
  mos: {
    id: 'languages.mos',
    defaultMessage: 'Mossi',
  },
  mul: {
    id: 'languages.mul',
    defaultMessage: 'Multiple languages',
  },
  mun: {
    id: 'languages.mun',
    defaultMessage: 'Munda languages',
  },
  mus: {
    id: 'languages.mus',
    defaultMessage: 'Creek',
  },
  mwl: {
    id: 'languages.mwl',
    defaultMessage: 'Mirandese',
  },
  mwr: {
    id: 'languages.mwr',
    defaultMessage: 'Marwari',
  },
  myn: {
    id: 'languages.myn',
    defaultMessage: 'Mayan languages',
  },
  myv: {
    id: 'languages.myv',
    defaultMessage: 'Erzya',
  },
  nah: {
    id: 'languages.nah',
    defaultMessage: 'Nahuatl languages',
  },
  nai: {
    id: 'languages.nai',
    defaultMessage: 'North American Indian languages',
  },
  nap: {
    id: 'languages.nap',
    defaultMessage: 'Neapolitan',
  },
  nau: {
    id: 'languages.nau',
    defaultMessage: 'Nauru',
  },
  nav: {
    id: 'languages.nav',
    defaultMessage: 'Navajo; Navaho',
  },
  nbl: {
    id: 'languages.nbl',
    defaultMessage: 'Ndebele, South; South Ndebele',
  },
  nde: {
    id: 'languages.nde',
    defaultMessage: 'Ndebele, North; North Ndebele',
  },
  ndo: {
    id: 'languages.ndo',
    defaultMessage: 'Ndonga',
  },
  nds: {
    id: 'languages.nds',
    defaultMessage: 'Low German; Low Saxon; German, Low; Saxon, Low',
  },
  nep: {
    id: 'languages.nep',
    defaultMessage: 'Nepali',
  },
  new: {
    id: 'languages.new',
    defaultMessage: 'Nepal Bhasa; Newari',
  },
  nia: {
    id: 'languages.nia',
    defaultMessage: 'Nias',
  },
  nic: {
    id: 'languages.nic',
    defaultMessage: 'Niger-Kordofanian languages',
  },
  niu: {
    id: 'languages.niu',
    defaultMessage: 'Niuean',
  },
  nno: {
    id: 'languages.nno',
    defaultMessage: 'Norwegian Nynorsk; Nynorsk, Norwegian',
  },
  nob: {
    id: 'languages.nob',
    defaultMessage: 'Bokmål, Norwegian; Norwegian Bokmål',
  },
  nog: {
    id: 'languages.nog',
    defaultMessage: 'Nogai',
  },
  non: {
    id: 'languages.non',
    defaultMessage: 'Norse, Old',
  },
  nor: {
    id: 'languages.nor',
    defaultMessage: 'Norwegian',
  },
  nqo: {
    id: 'languages.nqo',
    defaultMessage: "N'Ko",
  },
  nso: {
    id: 'languages.nso',
    defaultMessage: 'Pedi; Sepedi; Northern Sotho',
  },
  nub: {
    id: 'languages.nub',
    defaultMessage: 'Nubian languages',
  },
  nwc: {
    id: 'languages.nwc',
    defaultMessage: 'Classical Newari; Old Newari; Classical Nepal Bhasa',
  },
  nya: {
    id: 'languages.nya',
    defaultMessage: 'Chichewa; Chewa; Nyanja',
  },
  nym: {
    id: 'languages.nym',
    defaultMessage: 'Nyamwezi',
  },
  nyn: {
    id: 'languages.nyn',
    defaultMessage: 'Nyankole',
  },
  nyo: {
    id: 'languages.nyo',
    defaultMessage: 'Nyoro',
  },
  nzi: {
    id: 'languages.nzi',
    defaultMessage: 'Nzima',
  },
  oci: {
    id: 'languages.oci',
    defaultMessage: 'Occitan (post 1500)',
  },
  oji: {
    id: 'languages.oji',
    defaultMessage: 'Ojibwa',
  },
  ori: {
    id: 'languages.ori',
    defaultMessage: 'Oriya',
  },
  orm: {
    id: 'languages.orm',
    defaultMessage: 'Oromo',
  },
  osa: {
    id: 'languages.osa',
    defaultMessage: 'Osage',
  },
  oss: {
    id: 'languages.oss',
    defaultMessage: 'Ossetian; Ossetic',
  },
  ota: {
    id: 'languages.ota',
    defaultMessage: 'Turkish, Ottoman (1500-1928)',
  },
  oto: {
    id: 'languages.oto',
    defaultMessage: 'Otomian languages',
  },
  paa: {
    id: 'languages.paa',
    defaultMessage: 'Papuan languages',
  },
  pag: {
    id: 'languages.pag',
    defaultMessage: 'Pangasinan',
  },
  pal: {
    id: 'languages.pal',
    defaultMessage: 'Pahlavi',
  },
  pam: {
    id: 'languages.pam',
    defaultMessage: 'Pampanga; Kapampangan',
  },
  pan: {
    id: 'languages.pan',
    defaultMessage: 'Panjabi; Punjabi',
  },
  pap: {
    id: 'languages.pap',
    defaultMessage: 'Papiamento',
  },
  pau: {
    id: 'languages.pau',
    defaultMessage: 'Palauan',
  },
  peo: {
    id: 'languages.peo',
    defaultMessage: 'Persian, Old (ca.600-400 B.C.)',
  },
  per: {
    id: 'languages.per',
    defaultMessage: 'Persian',
  },
  phi: {
    id: 'languages.phi',
    defaultMessage: 'Philippine languages',
  },
  phn: {
    id: 'languages.phn',
    defaultMessage: 'Phoenician',
  },
  pli: {
    id: 'languages.pli',
    defaultMessage: 'Pali',
  },
  pol: {
    id: 'languages.pol',
    defaultMessage: 'Polish',
  },
  pon: {
    id: 'languages.pon',
    defaultMessage: 'Pohnpeian',
  },
  por: {
    id: 'languages.por',
    defaultMessage: 'Portuguese',
  },
  pra: {
    id: 'languages.pra',
    defaultMessage: 'Prakrit languages',
  },
  pro: {
    id: 'languages.pro',
    defaultMessage: 'Provençal, Old (to 1500); Occitan, Old (to 1500)',
  },
  pus: {
    id: 'languages.pus',
    defaultMessage: 'Pushto; Pashto',
  },
  que: {
    id: 'languages.que',
    defaultMessage: 'Quechua',
  },
  raj: {
    id: 'languages.raj',
    defaultMessage: 'Rajasthani',
  },
  rap: {
    id: 'languages.rap',
    defaultMessage: 'Rapanui',
  },
  rar: {
    id: 'languages.rar',
    defaultMessage: 'Rarotongan; Cook Islands Maori',
  },
  roa: {
    id: 'languages.roa',
    defaultMessage: 'Romance languages',
  },
  roh: {
    id: 'languages.roh',
    defaultMessage: 'Romansh',
  },
  rom: {
    id: 'languages.rom',
    defaultMessage: 'Romany',
  },
  rum: {
    id: 'languages.rum',
    defaultMessage: 'Romanian; Moldavian; Moldovan',
  },
  run: {
    id: 'languages.run',
    defaultMessage: 'Rundi',
  },
  rup: {
    id: 'languages.rup',
    defaultMessage: 'Aromanian; Arumanian; Macedo-Romanian',
  },
  rus: {
    id: 'languages.rus',
    defaultMessage: 'Russian',
  },
  sad: {
    id: 'languages.sad',
    defaultMessage: 'Sandawe',
  },
  sag: {
    id: 'languages.sag',
    defaultMessage: 'Sango',
  },
  sah: {
    id: 'languages.sah',
    defaultMessage: 'Yakut',
  },
  sai: {
    id: 'languages.sai',
    defaultMessage: 'South American Indian languages',
  },
  sal: {
    id: 'languages.sal',
    defaultMessage: 'Salishan languages',
  },
  sam: {
    id: 'languages.sam',
    defaultMessage: 'Samaritan Aramaic',
  },
  san: {
    id: 'languages.san',
    defaultMessage: 'Sanskrit',
  },
  sas: {
    id: 'languages.sas',
    defaultMessage: 'Sasak',
  },
  sat: {
    id: 'languages.sat',
    defaultMessage: 'Santali',
  },
  scn: {
    id: 'languages.scn',
    defaultMessage: 'Sicilian',
  },
  sco: {
    id: 'languages.sco',
    defaultMessage: 'Scots',
  },
  sel: {
    id: 'languages.sel',
    defaultMessage: 'Selkup',
  },
  sem: {
    id: 'languages.sem',
    defaultMessage: 'Semitic languages',
  },
  sga: {
    id: 'languages.sga',
    defaultMessage: 'Irish, Old (to 900)',
  },
  sgn: {
    id: 'languages.sgn',
    defaultMessage: 'Sign Languages',
  },
  shn: {
    id: 'languages.shn',
    defaultMessage: 'Shan',
  },
  sid: {
    id: 'languages.sid',
    defaultMessage: 'Sidamo',
  },
  sin: {
    id: 'languages.sin',
    defaultMessage: 'Sinhala; Sinhalese',
  },
  sio: {
    id: 'languages.sio',
    defaultMessage: 'Siouan languages',
  },
  sit: {
    id: 'languages.sit',
    defaultMessage: 'Sino-Tibetan languages',
  },
  sla: {
    id: 'languages.sla',
    defaultMessage: 'Slavic languages',
  },
  slo: {
    id: 'languages.slo',
    defaultMessage: 'Slovak',
  },
  slv: {
    id: 'languages.slv',
    defaultMessage: 'Slovenian',
  },
  sma: {
    id: 'languages.sma',
    defaultMessage: 'Southern Sami',
  },
  sme: {
    id: 'languages.sme',
    defaultMessage: 'Northern Sami',
  },
  smi: {
    id: 'languages.smi',
    defaultMessage: 'Sami languages',
  },
  smj: {
    id: 'languages.smj',
    defaultMessage: 'Lule Sami',
  },
  smn: {
    id: 'languages.smn',
    defaultMessage: 'Inari Sami',
  },
  smo: {
    id: 'languages.smo',
    defaultMessage: 'Samoan',
  },
  sms: {
    id: 'languages.sms',
    defaultMessage: 'Skolt Sami',
  },
  sna: {
    id: 'languages.sna',
    defaultMessage: 'Shona',
  },
  snd: {
    id: 'languages.snd',
    defaultMessage: 'Sindhi',
  },
  snk: {
    id: 'languages.snk',
    defaultMessage: 'Soninke',
  },
  sog: {
    id: 'languages.sog',
    defaultMessage: 'Sogdian',
  },
  som: {
    id: 'languages.som',
    defaultMessage: 'Somali',
  },
  son: {
    id: 'languages.son',
    defaultMessage: 'Songhai languages',
  },
  sot: {
    id: 'languages.sot',
    defaultMessage: 'Sotho, Southern',
  },
  spa: {
    id: 'languages.spa',
    defaultMessage: 'Spanish; Castilian',
  },
  srd: {
    id: 'languages.srd',
    defaultMessage: 'Sardinian',
  },
  srn: {
    id: 'languages.srn',
    defaultMessage: 'Sranan Tongo',
  },
  srp: {
    id: 'languages.srp',
    defaultMessage: 'Serbian',
  },
  srr: {
    id: 'languages.srr',
    defaultMessage: 'Serer',
  },
  ssa: {
    id: 'languages.ssa',
    defaultMessage: 'Nilo-Saharan languages',
  },
  ssw: {
    id: 'languages.ssw',
    defaultMessage: 'Swati',
  },
  suk: {
    id: 'languages.suk',
    defaultMessage: 'Sukuma',
  },
  sun: {
    id: 'languages.sun',
    defaultMessage: 'Sundanese',
  },
  sus: {
    id: 'languages.sus',
    defaultMessage: 'Susu',
  },
  sux: {
    id: 'languages.sux',
    defaultMessage: 'Sumerian',
  },
  swa: {
    id: 'languages.swa',
    defaultMessage: 'Swahili',
  },
  swe: {
    id: 'languages.swe',
    defaultMessage: 'Swedish',
  },
  syc: {
    id: 'languages.syc',
    defaultMessage: 'Classical Syriac',
  },
  syr: {
    id: 'languages.syr',
    defaultMessage: 'Syriac',
  },
  tah: {
    id: 'languages.tah',
    defaultMessage: 'Tahitian',
  },
  tai: {
    id: 'languages.tai',
    defaultMessage: 'Tai languages',
  },
  tam: {
    id: 'languages.tam',
    defaultMessage: 'Tamil',
  },
  tat: {
    id: 'languages.tat',
    defaultMessage: 'Tatar',
  },
  tel: {
    id: 'languages.tel',
    defaultMessage: 'Telugu',
  },
  tem: {
    id: 'languages.tem',
    defaultMessage: 'Timne',
  },
  ter: {
    id: 'languages.ter',
    defaultMessage: 'Tereno',
  },
  tet: {
    id: 'languages.tet',
    defaultMessage: 'Tetum',
  },
  tgk: {
    id: 'languages.tgk',
    defaultMessage: 'Tajik',
  },
  tgl: {
    id: 'languages.tgl',
    defaultMessage: 'Tagalog',
  },
  tha: {
    id: 'languages.tha',
    defaultMessage: 'Thai',
  },
  tib: {
    id: 'languages.tib',
    defaultMessage: 'Tibetan',
  },
  tig: {
    id: 'languages.tig',
    defaultMessage: 'Tigre',
  },
  tir: {
    id: 'languages.tir',
    defaultMessage: 'Tigrinya',
  },
  tiv: {
    id: 'languages.tiv',
    defaultMessage: 'Tiv',
  },
  tkl: {
    id: 'languages.tkl',
    defaultMessage: 'Tokelau',
  },
  tlh: {
    id: 'languages.tlh',
    defaultMessage: 'Klingon; tlhIngan-Hol',
  },
  tli: {
    id: 'languages.tli',
    defaultMessage: 'Tlingit',
  },
  tmh: {
    id: 'languages.tmh',
    defaultMessage: 'Tamashek',
  },
  tog: {
    id: 'languages.tog',
    defaultMessage: 'Tonga (Nyasa)',
  },
  ton: {
    id: 'languages.ton',
    defaultMessage: 'Tonga (Tonga Islands)',
  },
  tpi: {
    id: 'languages.tpi',
    defaultMessage: 'Tok Pisin',
  },
  tsi: {
    id: 'languages.tsi',
    defaultMessage: 'Tsimshian',
  },
  tsn: {
    id: 'languages.tsn',
    defaultMessage: 'Tswana',
  },
  tso: {
    id: 'languages.tso',
    defaultMessage: 'Tsonga',
  },
  tuk: {
    id: 'languages.tuk',
    defaultMessage: 'Turkmen',
  },
  tum: {
    id: 'languages.tum',
    defaultMessage: 'Tumbuka',
  },
  tup: {
    id: 'languages.tup',
    defaultMessage: 'Tupi languages',
  },
  tur: {
    id: 'languages.tur',
    defaultMessage: 'Turkish',
  },
  tut: {
    id: 'languages.tut',
    defaultMessage: 'Altaic languages',
  },
  tvl: {
    id: 'languages.tvl',
    defaultMessage: 'Tuvalu',
  },
  twi: {
    id: 'languages.twi',
    defaultMessage: 'Twi',
  },
  tyv: {
    id: 'languages.tyv',
    defaultMessage: 'Tuvinian',
  },
  udm: {
    id: 'languages.udm',
    defaultMessage: 'Udmurt',
  },
  uga: {
    id: 'languages.uga',
    defaultMessage: 'Ugaritic',
  },
  uig: {
    id: 'languages.uig',
    defaultMessage: 'Uighur; Uyghur',
  },
  ukr: {
    id: 'languages.ukr',
    defaultMessage: 'Ukrainian',
  },
  umb: {
    id: 'languages.umb',
    defaultMessage: 'Umbundu',
  },
  urd: {
    id: 'languages.urd',
    defaultMessage: 'Urdu',
  },
  uzb: {
    id: 'languages.uzb',
    defaultMessage: 'Uzbek',
  },
  vai: {
    id: 'languages.vai',
    defaultMessage: 'Vai',
  },
  ven: {
    id: 'languages.ven',
    defaultMessage: 'Venda',
  },
  vie: {
    id: 'languages.vie',
    defaultMessage: 'Vietnamese',
  },
  vol: {
    id: 'languages.vol',
    defaultMessage: 'Volapük',
  },
  vot: {
    id: 'languages.vot',
    defaultMessage: 'Votic',
  },
  wak: {
    id: 'languages.wak',
    defaultMessage: 'Wakashan languages',
  },
  wal: {
    id: 'languages.wal',
    defaultMessage: 'Wolaitta; Wolaytta',
  },
  war: {
    id: 'languages.war',
    defaultMessage: 'Waray',
  },
  was: {
    id: 'languages.was',
    defaultMessage: 'Washo',
  },
  wel: {
    id: 'languages.wel',
    defaultMessage: 'Welsh',
  },
  wen: {
    id: 'languages.wen',
    defaultMessage: 'Sorbian languages',
  },
  wln: {
    id: 'languages.wln',
    defaultMessage: 'Walloon',
  },
  wol: {
    id: 'languages.wol',
    defaultMessage: 'Wolof',
  },
  xal: {
    id: 'languages.xal',
    defaultMessage: 'Kalmyk; Oirat',
  },
  xho: {
    id: 'languages.xho',
    defaultMessage: 'Xhosa',
  },
  yao: {
    id: 'languages.yao',
    defaultMessage: 'Yao',
  },
  yap: {
    id: 'languages.yap',
    defaultMessage: 'Yapese',
  },
  yid: {
    id: 'languages.yid',
    defaultMessage: 'Yiddish',
  },
  yor: {
    id: 'languages.yor',
    defaultMessage: 'Yoruba',
  },
  ypk: {
    id: 'languages.ypk',
    defaultMessage: 'Yupik languages',
  },
  zap: {
    id: 'languages.zap',
    defaultMessage: 'Zapotec',
  },
  zbl: {
    id: 'languages.zbl',
    defaultMessage: 'Blissymbols; Blissymbolics; Bliss',
  },
  zen: {
    id: 'languages.zen',
    defaultMessage: 'Zenaga',
  },
  zgh: {
    id: 'languages.zgh',
    defaultMessage: 'Standard Moroccan Tamazight',
  },
  zha: {
    id: 'languages.zha',
    defaultMessage: 'Zhuang; Chuang',
  },
  znd: {
    id: 'languages.znd',
    defaultMessage: 'Zande languages',
  },
  zul: {
    id: 'languages.zul',
    defaultMessage: 'Zulu',
  },
  zun: {
    id: 'languages.zun',
    defaultMessage: 'Zuni',
  },
  zxx: {
    id: 'languages.zxx',
    defaultMessage: 'No linguistic content; Not applicable',
  },
  zza: {
    id: 'languages.zza',
    defaultMessage: 'Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki',
  },
});
