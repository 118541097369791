import { defineMessages } from 'react-intl';

export const sortByItemsMessages = defineMessages({
  amount: {
    id: 'sortByDropdown.amount',
    defaultMessage:
      '{order, select, ascending {Fee (lowest)} descending {Fee (highest)} other {}}',
  },
  author: {
    id: 'sortByDropdown.author',
    defaultMessage:
      '{order, select, ascending {Author (ascending)} descending {Author (descending)} other {}}',
  },
  dueDate: {
    id: 'sortByDropdown.dueDate',
    defaultMessage:
      '{order, select, ascending {Due date (earliest)} descending {Due date (latest)} other {}}',
  },
  publicationYear: {
    id: 'sortByDropdown.publicationYear',
    defaultMessage:
      '{order, select, ascending {Year (oldest)} descending {Year (newest)} other {}}',
  },
  relevance: {
    id: 'sortByDropdown.relevance',
    defaultMessage: 'Relevance',
  },
  title: {
    id: 'sortByDropdown.title',
    defaultMessage:
      '{order, select, ascending {Title (ascending)} descending {Title (descending)} other {}}',
  },
  courseName: {
    id: 'sortByDropdown.courseName',
    defaultMessage:
      '{order, select, ascending {Course name (A-Z)} descending {Course name (Z-A)} other {}}',
  },
});
