import { useEffect } from 'react';

import { setPageRecords, RecordsData } from '@app/utils';

export interface PagingData<T> extends RecordsData<T> {
  areRecordsFetched: boolean;
}

export const usePaging = <T>({
  areRecordsFetched,
  records,
  pageNumber,
  setPages,
}: PagingData<T>): void => {
  useEffect(() => {
    if (areRecordsFetched) {
      setPageRecords({ records, pageNumber, setPages });
    }
  }, [areRecordsFetched, records, pageNumber, setPages]);
};
