import defaultAxios from 'axios';

export const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    'X-Okapi-Tenant': process.env.REACT_APP_API_TENANT as string,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const setAxiosHeaders = (session: { token: string } | null): void => {
  if (session?.token) {
    axios.defaults.headers.common['X-Okapi-Token'] = session.token;
  } else {
    delete axios.defaults.headers.common['X-Okapi-Token'];
  }
};
