import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { uniqBy } from 'lodash';

import { BrowseByField } from '@app/components';
import { DepartmentInstructorItemDto } from '@app/pages/constants';
import { ALL_RECORDS_CQL } from '@app/search';
import { axios } from '@app/utils';
import { SORT_DIRECTION } from '@app/constants';

export const useDepartmentsInstructorsFetch = (
  fieldName: BrowseByField
): UseQueryResult<DepartmentInstructorItemDto[]> => {
  return useQuery([`${fieldName}List`], () =>
    axios
      .get<Record<BrowseByField, DepartmentInstructorItemDto[]>>(
        `/opac-courses/${fieldName}`,
        {
          params: {
            limit: 1000,
            query: `${ALL_RECORDS_CQL} sortBy name/sort.${SORT_DIRECTION.asc}`,
          },
        }
      )
      .then(response => uniqBy(response.data?.[fieldName], 'name'))
  );
};
