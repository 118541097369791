import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { NovelistProps } from '@app/components';

export interface NovelistData {
  isSelectLoading: boolean;
  isScriptLoaded: boolean;
}

export const useNovelistSelect = ({
  isbn,
  config = { profile: '', password: '', url: '' },
}: NovelistProps): NovelistData => {
  const [isSelectLoading, setSelectLoading] = useState(false);
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  const onScriptLoaded = () => setScriptLoaded(true);

  useEffect(() => {
    try {
      if (!config.profile || !config.password || !config.url) {
        return setScriptLoaded(false);
      }

      if (!isEmpty(window.novSelect) && Boolean(isbn)) {
        return setScriptLoaded(true);
      }

      const script = document.createElement('script');

      script.setAttribute('src', config.url);
      document.body.appendChild(script);
      script.addEventListener('load', onScriptLoaded, false);

      return () => script.removeEventListener('load', onScriptLoaded);
    } catch (error) {
      return setScriptLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isScriptLoaded && isbn) {
      setSelectLoading(true);

      try {
        window.novSelect.loadContentForQuery(
          {
            ClientIdentifier: isbn,
            ISBN: isbn,
            version: '2.3',
          },
          config.profile,
          config.password,
          () => {
            setSelectLoading(false);
          }
        );
      } catch (error) {
        setScriptLoaded(false);
        setSelectLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded, isbn]);

  return {
    isSelectLoading,
    isScriptLoaded,
  };
};
