import { createContext, useContext } from 'react';

import { FEATURE } from '@app/constants';

export interface FeatureFlag {
  name: FEATURE;
  isActive: boolean;
  config?: Record<string, string>;
}

export const FeatureFlagsContext = createContext<FeatureFlag[]>(null!);

export const useFeatureFlagsContext = (): FeatureFlag[] => {
  return useContext(FeatureFlagsContext);
};
