import { useEffect, useState } from 'react';

import { PatronSessionState } from '@app/components';
import { setAxiosHeaders } from '@app/utils';
import { SetState, USER_SESSION } from '@app/constants';

import { useSessionValidation } from './requests';

export type authenticateToken = (token: string, redirectRoute: string) => void;

interface AppUsersProps {
  patronSession: PatronSessionState;
  isConsortiaLogin: boolean;
  setPatronSession: SetState<PatronSessionState>;
  setIsOkapiSessionDown: SetState<boolean>;
}

interface UserState {
  isPatronUserLoggedIn: boolean;
  authenticateToken: authenticateToken;
}

export const useAppUsers = ({
  patronSession,
  isConsortiaLogin,
  setPatronSession,
  setIsOkapiSessionDown,
}: AppUsersProps): UserState => {
  const [isPatronUserLoggedIn, setIsPatronUserLoggedIn] = useState(false);
  const patronUser = useSessionValidation({
    userSessionKey: USER_SESSION.patron,
    isConsortiaLogin,
    setIsOkapiSessionDown,
    setPatronSession,
  });

  useEffect(() => {
    if (patronUser.isLoggedIn && Boolean(patronUser.session?.user)) {
      setAxiosHeaders(patronUser.session);
      setPatronSession(patronUser.session);
      setIsPatronUserLoggedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patronUser.isLoggedIn, setPatronSession]);

  const authenticateToken: authenticateToken = (token, redirectRoute) => {
    patronUser.validateToken({ token, redirectRoute });
  };

  useEffect(() => {
    setIsPatronUserLoggedIn(Boolean(patronSession?.user));
  }, [patronSession?.user]);

  return {
    isPatronUserLoggedIn,
    authenticateToken,
  };
};
