import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { useCoverArtFetch, useInstanceDetailsRoutePath } from '@app/hooks';

import { CoverImageContainer } from '..';

import './Carousel.scss';

interface CarouselItemProps {
  id: string;
  title: string;
  index: number;
  totalItems: number;
  isVisible: boolean;
  contributors?: { name: string }[];
  imagePath?: string;
  sourceTypes?: string;
  isbns?: string[];
}

const cnBem = createBemBlockBuilder(['carousel']);

export const CarouselItem: FC<CarouselItemProps> = ({
  isVisible,
  id,
  title,
  index,
  totalItems,
  contributors,
  sourceTypes,
  isbns,
}) => {
  const { $t } = useIntl();
  const { getInstanceDetailsRoutePath } = useInstanceDetailsRoutePath();
  const { data: coverArtSrc, isInitialLoading: isCoverArtLoading } =
    useCoverArtFetch(isbns);
  const tabIndex = isVisible ? 0 : -1;

  return (
    <Link
      to={getInstanceDetailsRoutePath(id)}
      className={cnBem('__item-link')}
      tabIndex={tabIndex}
    >
      <div
        className={cnBem('__item')}
        role="group"
        aria-label={$t(
          {
            id: 'carousel.item',
            defaultMessage: '{title}. {index} slide of {totalItems}',
          },
          { title, index, totalItems }
        )}
        data-testid="carousel-item"
      >
        <div className={cnBem('__imageBox')} data-testid="carousel-item-image">
          <CoverImageContainer
            src={coverArtSrc}
            sourceTypes={sourceTypes}
            isLoading={isCoverArtLoading}
            className={cnBem('__defaultImageWrapper')}
          />
        </div>
        {sourceTypes && (
          <span
            data-testid="carousel-item-source-type"
            className={cnBem('__truncated')}
          >
            {sourceTypes}
          </span>
        )}
        {title && (
          <h3 className={cnBem('__title')} data-testid="carousel-item-title">
            {title}
          </h3>
        )}
        {contributors && !isEmpty(contributors) && (
          <span
            className={cnBem('__truncated')}
            data-testid="carousel-item-contributor"
          >
            {contributors.map(({ name }) => name).join('; ')}
          </span>
        )}
      </div>
    </Link>
  );
};
