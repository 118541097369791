import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { useRecordSummaryContext } from '@app/components';
import {
  useInstanceDetailsRoutePath,
  useInstanceRelationships,
} from '@app/hooks';

import '../RecordSummary/RecordSummary.scss';

const cnBem = createBemBlockBuilder(['recordSummary']);

export const ParentField: FC = () => {
  const { instance } = useRecordSummaryContext();
  const { getInstanceDetailsRoutePath } = useInstanceDetailsRoutePath();
  const parents = useInstanceRelationships(instance.parentInstances);

  return !isEmpty(parents) ? (
    <div className={cnBem('__publication')} dir="ltr" data-testid="part-of">
      <span className={cnBem('__contributorsBy')}>
        <FormattedMessage id="resultsList.partOf" defaultMessage="Part of:" />{' '}
      </span>
      <ul className={cnBem('__contributorsList')}>
        {parents.map(({ title, id }) => (
          <li key={title}>
            <Link
              to={getInstanceDetailsRoutePath(id)}
              key={title}
              className={cn('underlinedLink', cnBem('__link'))}
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};
