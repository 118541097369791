import { useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryString from 'query-string';

import { SEARCH_OPTION } from '@app/search';
import { axios } from '@app/utils';
import { MIN_SUGGESTION_TERM_LENGTH } from '@app/constants';

import { useDebounce } from '..';

export interface InstanceTermDto {
  id: string;
  term: string;
  index?: number;
}

export type SuggestionDtoValue = {
  terms: InstanceTermDto[];
  totalRecords: number;
};

export type SuggestionDto = {
  [Property in SEARCH_OPTION]?: SuggestionDtoValue;
};

export interface SuggestionsDto {
  suggestions: SuggestionDto;
  totalRecords: number;
}

const AUTOCOMPLETE_SUGGESTIONS_DEFAULT_LIMIT = 5;
const AUTOCOMPLETE_SUGGESTIONS_LIMIT = {
  [SEARCH_OPTION.keyword]: 3,
};

const supportedOptions = {
  keyword: SEARCH_OPTION.keyword,
  title: SEARCH_OPTION.title,
  author: SEARCH_OPTION.author,
  subject: SEARCH_OPTION.subject,
};

export const useSuggestionsFetch = (
  searchOption: SEARCH_OPTION
): {
  query: UseQueryResult<SuggestionsDto> | undefined;
  setSearchTerm: (value: string) => void;
} => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedTerm = useDebounce<string>(searchTerm, 300);

  const isTermLongEnough = debouncedTerm.length >= MIN_SUGGESTION_TERM_LENGTH;
  const isSupportedOption = Boolean(supportedOptions[searchOption]);
  const isDebouncedTermUpToDate =
    debouncedTerm
      .toLocaleLowerCase()
      .startsWith(searchTerm.toLocaleLowerCase()) ||
    searchTerm
      .toLocaleLowerCase()
      .startsWith(debouncedTerm.toLocaleLowerCase());

  const suggestionsQuery = useQuery<SuggestionsDto>(
    ['suggestions', searchOption, debouncedTerm],
    () => {
      return axios
        .get<SuggestionsDto>(`/search/instances/suggestions/${searchOption}`, {
          params: {
            query: debouncedTerm,
            limit:
              AUTOCOMPLETE_SUGGESTIONS_LIMIT[searchOption] ||
              AUTOCOMPLETE_SUGGESTIONS_DEFAULT_LIMIT,
          },
          paramsSerializer: queryString.stringify,
        })
        .then(response => response.data);
    },
    {
      enabled: Boolean(debouncedTerm) && isTermLongEnough && isSupportedOption,
      staleTime: 1000 * 60 * 5,
    }
  );

  return {
    query: isDebouncedTermUpToDate ? suggestionsQuery : undefined,
    setSearchTerm,
  };
};
