import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';

import { Button, emitScrollTo } from '@ebsco-ui/ebsco-ui';

import { useOnScroll } from '@app/hooks';
import { setFocusOn } from '@app/utils';
import { BACK_TO_TOP_TRIGGER_POSITION } from '@app/constants';

import css from './BackToTopButton.module.scss';

interface BackToTopButtonProps {
  triggerPosition?: number;
}

const scrollToTop = () => {
  setFocusOn();
  emitScrollTo(0, undefined);
};

export const BackToTopButton: FC<BackToTopButtonProps> = ({
  triggerPosition = BACK_TO_TOP_TRIGGER_POSITION,
}) => {
  const { $t } = useIntl();
  const scrollDepth = useOnScroll();

  if (scrollDepth <= triggerPosition) {
    return null;
  }

  return (
    <Button
      data-testid="backToTopButton"
      aria-label={$t({
        id: 'messages.backToTop',
        defaultMessage: 'Back to top',
      })}
      className={cn(css.backToTopButton, css.visible)}
      styleType="solid"
      onClick={scrollToTop}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  );
};
