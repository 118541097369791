import { createContext, ReactElement, useContext } from 'react';

import { RecordSummaryProps } from '@app/components';
import { SetState } from '@app/constants';

export interface RecordSummaryContextProps extends RecordSummaryProps {
  isInstanceDetails: boolean;
  isMobileInstanceDetails: boolean;
  isBookshelf: boolean;
  isMobileBookshelf: boolean;
  isAvailabilityLoadingForTheFirstTime: boolean;
  getItemStatusPanel: () => ReactElement;
  setPlaceHoldModalOpen: SetState<boolean>;
}

export const RecordSummaryContext = createContext<RecordSummaryContextProps>(
  null!
);

export const useRecordSummaryContext = (): RecordSummaryContextProps =>
  useContext(RecordSummaryContext);
