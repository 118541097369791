import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UseSelectStateChange } from 'downshift';

import { Button, Dropdown } from '@ebsco-ui/ebsco-ui';

import { useAppContext } from '@app/contexts';
import { sharedMessages } from '@app/translations';

import supportedLocales from './supportedLocales.json';

import css from './LocaleDropdown.module.scss';

export const LocaleDropdown: FC = () => {
  const { locale, setSelectedLocale } = useAppContext();
  const [selectedDropdownLocale, setSelectedDropdownLocale] = useState(locale);

  const handleSelectedLocaleChange = ({
    selectedItem,
  }: UseSelectStateChange<(typeof supportedLocales)[0]>) => {
    if (selectedItem?.id) {
      setSelectedDropdownLocale(selectedItem.id);
    }
  };

  return (
    <>
      <Dropdown
        items={supportedLocales}
        downshiftOptions={{
          selectedItem: supportedLocales.find(
            ({ id }) => id === selectedDropdownLocale
          ),
          onSelectedItemChange: handleSelectedLocaleChange,
        }}
        className={css.localeDropdown}
        styleType="standard"
      >
        <div>
          <Dropdown.Label>
            <FormattedMessage
              id="selectYourLanguage"
              defaultMessage="Select your language"
            />
          </Dropdown.Label>
          <Dropdown.Toggle />
        </div>
        <Dropdown.Menu>
          {supportedLocales.map((item, index) => (
            <Dropdown.Item key={item.id} item={item} index={index}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Button
        className={css.saveButton}
        styleType="default"
        onClick={() => setSelectedLocale(selectedDropdownLocale)}
      >
        <FormattedMessage {...sharedMessages.save} />
      </Button>
    </>
  );
};
