import { FC, ReactNode, Fragment } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import './RecordMetadata.scss';

export type MetadataItem = {
  content: ReactNode;
  title?: string;
  testId?: string;
};

interface RecordMetadataProps {
  items: MetadataItem[];
}

const cnBem = createBemBlockBuilder(['recordMetadata']);

export const RecordMetadata: FC<RecordMetadataProps> = ({ items }) => {
  return (
    <article>
      <dl>
        {items.map(item => {
          const { title = '', content, testId = kebabCase(title) } = item;

          return (
            <Fragment key={title}>
              {title && (
                <dt
                  className={cn(cnBem('__title'), cnBem(`__${testId}Title`))}
                  data-testid={`record-${testId}-title`}
                >
                  <h3>{item.title}</h3>
                </dt>
              )}
              <dd
                className={cn(cnBem('__content'), cnBem(`__${testId}Content`))}
                dir="ltr"
                data-testid={`record-${testId}-content`}
              >
                {content}
              </dd>
            </Fragment>
          );
        })}
      </dl>
    </article>
  );
};
