import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { PasswordInput } from '@ebsco-ui/ebsco-ui';

import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';

export const PasswordField = ({
  component,
  ...props
}: FieldWrapperProps): ReactElement => {
  const { $t } = useIntl();

  return (
    <FieldWrapper
      showPasswordButtonText={$t({
        id: 'login.show',
        defaultMessage: 'Show',
      })}
      hidePasswordButtonText={$t({
        id: 'login.hide',
        defaultMessage: 'Hide',
      })}
      component={PasswordInput}
      {...props}
    />
  );
};
