import { FormattedMessage } from 'react-intl';
import { mapValues } from 'lodash';

import { sharedMessages } from '@app/translations';

export const fieldDescriptions = mapValues(
  {
    'BASIC/UNIQUE-ID+NAME': [
      <FormattedMessage id="login.uniqueID" defaultMessage="Unique ID" />,
      <FormattedMessage {...sharedMessages.name} />,
    ],
    'BASIC/BARCODE+NAME': [
      <FormattedMessage {...sharedMessages.barcode} />,
      <FormattedMessage {...sharedMessages.name} />,
    ],
    'BASIC/BARCODE+PASSWORD': [
      <FormattedMessage {...sharedMessages.barcode} />,
      <FormattedMessage {...sharedMessages.password} />,
    ],
    'BASIC/BARCODE+PIN': [
      <FormattedMessage {...sharedMessages.barcode} />,
      <FormattedMessage {...sharedMessages.pin} />,
    ],
  },
  ([field1, field2], key) => [
    { label: field1, name: 'barcode' },
    { label: field2, name: 'pin', isPasswordField: true },
  ]
);

export const usernamePasswordFieldsDescription = [
  {
    label: <FormattedMessage id="login.username" defaultMessage="Username" />,
    name: 'username',
  },
  {
    label: <FormattedMessage id="login.password" defaultMessage="Password" />,
    name: 'password',
    isPasswordField: true,
  },
];
