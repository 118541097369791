import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isEmpty, sortBy } from 'lodash';

import { RecordAvailabilityStatus } from '@app/components';
import {
  HoldingAvailability,
  HoldingsAvailabilityDto,
} from '@app/pages/constants';
import { AvailabilityContext, RecordAvailability } from '@app/contexts';
import { axios } from '@app/utils';

export const AvailabilityProvider: FC = ({ children }) => {
  const [availability, setAvailability] = useState<
    Record<string, RecordAvailability>
  >({});
  const [ids, setIds] = useState<string[]>([]);
  const [isFirstPage, setIsFirstPage] = useState<boolean>(false);

  const getAvailability = useCallback(
    (requestedId: string[], currentPageNumber) => {
      setIds(requestedId);
      setIsFirstPage(currentPageNumber === 1);
    },
    []
  );

  const availabilityQuery = useQuery(
    ['availability', ids],
    () =>
      axios
        .get('/opac-rtac/rtac', {
          params: {
            fullPeriodicals: true,
            instanceIds: ids.join(','),
          },
        })
        .then(response => response.data),
    { enabled: !isEmpty(ids) }
  );

  const processAvailability = useCallback(
    (holdings: HoldingsAvailabilityDto[] = []) => {
      return ids.reduce((newAvailability, id) => {
        const currentInstanceAvailability = holdings.find(
          holding => holding?.instanceId === id
        );
        const holdingsAvailabilityData: HoldingAvailability[] =
          currentInstanceAvailability?.holdings ?? [];
        const sortedHoldingsAvailabilityData = sortBy(
          holdingsAvailabilityData,
          ['status', 'callNumber']
        ).filter(
          holding => holding.status !== RecordAvailabilityStatus.unknown
        );
        const availableHoldings = holdingsAvailabilityData.filter(
          holding => holding.status === RecordAvailabilityStatus.available
        );

        const getSummaryAvailabilityStatus = () => {
          if (availabilityQuery.isError) {
            return RecordAvailabilityStatus.error;
          }

          return !isEmpty(availableHoldings)
            ? RecordAvailabilityStatus.available
            : RecordAvailabilityStatus.notAvailable;
        };

        const summaryAvailability = {
          status: getSummaryAvailabilityStatus(),
          isRequestable: currentInstanceAvailability?.requestable,
          isRTACApplicable: currentInstanceAvailability?.isRTACApplicable,
          hasVolumes: holdingsAvailabilityData.some(holdingAvailability =>
            Boolean(holdingAvailability.volume)
          ),
          ...(!availabilityQuery.isError && {
            copiesRemaining: {
              available: availableHoldings.length,
              total: sortedHoldingsAvailabilityData.length,
            },
          }),
        };

        newAvailability[id] = {
          availabilityData: sortedHoldingsAvailabilityData,
          summaryAvailability,
        };

        return newAvailability;
      }, {});
    },
    [availabilityQuery.isError, ids]
  );

  useEffect(() => {
    if (availabilityQuery.isFetched) {
      setAvailability(prevState => {
        const processedAvailability = processAvailability(
          availabilityQuery.data?.holdings
        );

        return isFirstPage
          ? processedAvailability
          : Object.assign({}, prevState, processedAvailability);
      });
    }
  }, [
    availabilityQuery.data,
    availabilityQuery.isFetched,
    ids,
    isFirstPage,
    processAvailability,
  ]);

  const value = useMemo(
    () => ({
      getAvailability,
      availability,
      isAvailabilityLoading: availabilityQuery.isLoading,
    }),
    [availability, getAvailability, availabilityQuery.isLoading]
  );

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};
