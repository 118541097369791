export interface OpacConfig {
  module: string;
  configName: string;
  code: string;
  getConfigValue: (config: OpacConfig) => object;
  enabled?: boolean;
  value?: string;
}

export const getOpacConfigurationsQuery = (config: OpacConfig[]): string => {
  return config
    .map(
      ({ module, configName, code }) =>
        `(module==${module} AND configName==${configName} AND code==${code})`
    )
    .join(' OR ');
};
