import { FC, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Field, Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { isFinite, toNumber } from 'lodash';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

import {
  Button,
  createBemBlockBuilder,
  IndeterminateSpinner,
  InPageNotification,
  Loading,
} from '@ebsco-ui/ebsco-ui';

import {
  AuthPageWrapper,
  Icon,
  PasswordField,
  StepsBar,
} from '@app/components';
import {
  useQueryParams,
  useValidator,
  useResetPinStep2Mutation,
  useValidateBarcodeMutation,
} from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { ROUTE } from '@app/constants';

import { ResetPinSuccess } from '../ResetPinSuccess';

import '../../../components/AuthPageWrapper/AuthPageWrapper.scss';

const cnBem = createBemBlockBuilder(['authPageWrapper']);

const minDigitsForPIN = 6;

export const EnterNewPinPage: FC = () => {
  const { $t } = useIntl();
  const [pinValidationFlags, setPinValidationFlags] = useState({
    numbers: false,
    sixDigits: false,
  });
  const { validateRequiredField, validateDigitsField } = useValidator();
  const queryParams = useQueryParams();
  const resetPinStep2Mutation = useResetPinStep2Mutation();
  const validateBarcodeMutation = useValidateBarcodeMutation();
  const notification = useMemo(
    () =>
      resetPinStep2Mutation.isError
        ? {
            variant: InPageNotification.VARIANT.error,
            header: $t(sharedMessages.somethingWentWrong),
            description: $t(sharedMessages.weAreNotAbleToProcessRequest),
          }
        : undefined,
    [resetPinStep2Mutation.isError, $t]
  );

  const validatePinField = value => {
    const isPinValid = validateDigitsField(value, minDigitsForPIN);

    setPinValidationFlags({
      numbers: isFinite(toNumber(value)),
      sixDigits: value?.length === minDigitsForPIN,
    });

    return isPinValid
      ? undefined
      : $t({
          id: 'resetPin.enterValidPIN',
          defaultMessage: 'Please enter a valid PIN.',
        });
  };

  const validateBarcode = validateBarcodeMutation.mutate;

  useEffect(() => {
    validateBarcode(queryParams.token as string);
  }, [queryParams.token, validateBarcode]);

  const validateConfirmField = (value = '') => {
    const requiredMessage = validateRequiredField(value);

    return requiredMessage
      ? $t({
          id: 'resetPin.confirmNewPin',
          defaultMessage: 'Please confirm your new PIN.',
        })
      : null;
  };

  const getField = (type: 'PIN' | 'CONFIRM') => {
    const fieldProps = {
      PIN: {
        label: $t({
          id: 'resetPin.newPin',
          defaultMessage: 'New PIN',
        }),
        name: 'pin',
        validate: validatePinField,
      },
      CONFIRM: {
        label: $t({
          id: 'resetPin.confirmPin',
          defaultMessage: 'Confirm new PIN',
        }),
        name: 'confirmPin',
        validate: validateConfirmField,
      },
    };

    const props = fieldProps[type];

    return (
      <div className={cnBem('__row')}>
        <Field
          label={props.label}
          name={props.name}
          //TODO: Remove it once Password input is fixed
          data-testid={props.name}
          component={PasswordField}
          validate={props.validate}
        />
        {type === 'PIN' && (
          <div className={cnBem('__validationFlags')}>
            <span
              data-testid={`pin-validation-numbers-${pinValidationFlags.numbers}`}
            >
              {getValidationIcon(pinValidationFlags.numbers)}
              {$t({
                id: 'resetPin.onlyNumbersAllowed',
                defaultMessage: 'Only numbers allowed',
              })}
            </span>
            <span
              data-testid={`pin-validation-length-${pinValidationFlags.sixDigits}`}
            >
              {getValidationIcon(pinValidationFlags.sixDigits)}
              {$t({
                id: 'resetPin.mustBeSixDigits',
                defaultMessage: 'Must be six digits',
              })}
            </span>
          </div>
        )}
      </div>
    );
  };

  const getValidationIcon = isValid => {
    return <Icon icon={isValid ? faCheck : faTimes} />;
  };

  const renderContent = () => {
    if (validateBarcodeMutation.isError) {
      return <Navigate to={ROUTE.notFound} />;
    }

    if (!validateBarcodeMutation.isSuccess) {
      return (
        <div data-testid="spinner">
          <Loading
            size="large"
            loadingMessage={$t(sharedMessages.loadingMessage)}
          />
        </div>
      );
    }

    if (resetPinStep2Mutation.isSuccess) {
      return <ResetPinSuccess />;
    }

    return (
      <Form
        render={({
          handleSubmit,
          submitting,
          pristine,
          dirtySinceLastSubmit,
          hasSubmitErrors,
          hasValidationErrors,
        }) => (
          <form className={cnBem('__form')} onSubmit={handleSubmit}>
            {resetPinStep2Mutation.isLoading && (
              <div data-testid="spinner">
                <IndeterminateSpinner
                  level="component"
                  className={cnBem('__spinner')}
                />
              </div>
            )}
            <StepsBar stepsAmount={3} currentStepIndex={1} />
            {getField('PIN')}
            {getField('CONFIRM')}
            <div className={cnBem('__row')}>
              <Button
                className={cnBem('__submitButton')}
                styleType="solid"
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  hasValidationErrors ||
                  (!dirtySinceLastSubmit && hasSubmitErrors)
                }
              >
                {$t(sharedMessages.resetPin)}
              </Button>
            </div>
          </form>
        )}
        onSubmit={({ pin, confirmPin }) => {
          if (pin !== confirmPin) {
            return {
              confirmPin: $t({
                id: 'collectPin.pinsDoNotMatch',
                defaultMessage: 'PINs do not match.',
              }),
            };
          }

          resetPinStep2Mutation.mutate({
            pin,
            token: queryParams.token as string,
          });

          return undefined;
        }}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'resetPin.documentTitle',
            defaultMessage: 'Reset PIN - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <AuthPageWrapper
        title={$t(sharedMessages.resetPin)}
        containerClassName={cnBem('__resetPinContainer')}
        notification={notification}
      >
        {renderContent()}
      </AuthPageWrapper>
    </>
  );
};
