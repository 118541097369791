import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppContext } from '@app/contexts';
import { ROUTE } from '@app/constants';

export const AuthRoute: FC = ({ children }) => {
  const { isLoggedIn } = useAppContext();

  if (isLoggedIn) {
    return <Navigate replace to={ROUTE.search} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
