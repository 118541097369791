import { FC, useCallback, useMemo, useState } from 'react';
import {
  matchPath,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';

import { Tabs } from '@ebsco-ui/ebsco-ui';

import { TabContent } from '@app/components';
import { sharedMessages } from '@app/translations';
import { ROUTE } from '@app/constants';

import { BookshelfTab } from './bookshelfTypes';
import { tabsConfig } from './constants';

import css from './BookshelfPage.module.scss';

type TabsRoutes = {
  [Property in BookshelfTab]: (typeof ROUTE)[Property];
};

const tabsTypes = Object.keys(tabsConfig) as BookshelfTab[];

export const BookshelfPage: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [tabsCounters, setTabsCounters] = useState(
    Object.fromEntries(tabsTypes.map(type => [type, 0]))
  );

  const isBookshelfRootRoute = useMemo(
    () => matchPath(pathname, ROUTE.bookshelf),
    [pathname]
  );
  const isBookshelfDescendantRoute = useMemo(
    () =>
      tabsTypes
        .map(type => ROUTE[type])
        .includes(pathname as TabsRoutes[keyof TabsRoutes]),
    [pathname]
  );

  const tabs = useMemo(
    () =>
      tabsTypes.map(type => ({
        title: $t(tabsConfig[type].title, {
          value: tabsCounters[type],
        }),
        route: ROUTE[type],
      })),
    [$t, tabsCounters]
  );

  const i18n = useMemo(
    () => ({
      scrollControlStartTitle: $t(sharedMessages.scrollBackward),
      scrollControlEndTitle: $t(sharedMessages.scrollForward),
    }),
    [$t]
  );

  const handleTabSelection = useCallback(
    (index: number) => navigate(tabs[index].route),
    [navigate, tabs]
  );

  const getSelectedTabIndex = () => {
    const tabIndex = tabs.findIndex(({ route }) => route === pathname);

    return tabIndex > -1 ? tabIndex : 0;
  };

  const renderTabPanels = () =>
    tabsTypes.map(type => (
      <Tabs.TabPanel key={type} className={`${type}-tab`}>
        <Helmet>
          <title>{$t(tabsConfig[type].documentTitle)}</title>
        </Helmet>
        <TabContent
          type={type}
          totalRecordsNumber={tabsCounters[type]}
          setTabsCounters={setTabsCounters}
        />
      </Tabs.TabPanel>
    ));

  if (isBookshelfRootRoute) {
    return <Navigate replace to={ROUTE[tabsTypes[0]]} />;
  }

  if (!isBookshelfDescendantRoute) {
    return <Navigate replace to={ROUTE.notFound} />;
  }

  return (
    <div
      className={cn(css.container, 'container')}
      data-testid="bookshelf-container"
    >
      <h1 className={css.title}>
        {$t({
          defaultMessage: 'My bookshelf',
          id: 'navigation.myBookshelf',
        })}
      </h1>
      <Tabs
        selectedIndex={getSelectedTabIndex()}
        i18n={i18n}
        tabs={tabs}
        onSelect={handleTabSelection}
      >
        {renderTabPanels()}
      </Tabs>
    </div>
  );
};
