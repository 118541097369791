import { FC, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import cn from 'classnames';

import { PageErrorMessage } from '@ebsco-ui/ebsco-ui';

import {
  FeatureFlagsProvider,
  AppSetup,
  AppReady,
  Image,
} from '@app/components';
import { NotFoundImage } from '@app/pages';
import { FeatureFlag } from '@app/contexts';
import { useLocale } from '@app/hooks';

export interface InitialAppState {
  featureFlags: FeatureFlag[];
  pageBannerMessage: string;
  isGuestUserLoggedIn: boolean;
}

export const App: FC = () => {
  const [isOkapiSessionDown, setIsOkapiSessionDown] = useState(false);
  const [initialAppState, setInitialAppState] = useState<InitialAppState>();
  const { locale, translationMessages, setSelectedLocale } = useLocale();

  if (isOkapiSessionDown && locale) {
    return (
      <IntlProvider locale={locale} messages={translationMessages}>
        <PageErrorMessage
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title={
            <FormattedMessage
              tagName="div"
              id="serverIsDown"
              defaultMessage="Server is down"
            />
          }
          image={<Image src={NotFoundImage} altText="404" />}
          imagePlacement="block-end"
          className={cn('container', 'serverIsDown')}
        />
      </IntlProvider>
    );
  }

  return !initialAppState?.isGuestUserLoggedIn ? (
    <AppSetup
      setInitialAppState={setInitialAppState}
      setIsOkapiSessionDown={setIsOkapiSessionDown}
    />
  ) : (
    <FeatureFlagsProvider flags={initialAppState.featureFlags}>
      <AppReady
        locale={locale}
        translationMessages={translationMessages}
        bannerMessage={initialAppState.pageBannerMessage}
        setSelectedLocale={setSelectedLocale}
        setIsOkapiSessionDown={setIsOkapiSessionDown}
      />
    </FeatureFlagsProvider>
  );
};
