import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useAppContext } from '@app/contexts';
import { axios } from '@app/utils';

import { HoldActionErrorDto } from '.';

interface RemoveHoldRequestProps {
  holdId: string;
}

export const useRemoveHoldMutation = (): UseMutationResult<
  unknown,
  HoldActionErrorDto,
  RemoveHoldRequestProps
> => {
  const appContext = useAppContext();

  return useMutation(({ holdId }: RemoveHoldRequestProps) => {
    const userId = appContext?.patronSession?.user?.externalSystemId;
    const patronPath = `/opac-patron/account/${userId}`;
    const holdPath = `/hold/${holdId}/cancel`;

    return axios
      .post<void>(`${patronPath}${holdPath}`, { holdId })
      .then(response => response.data);
  });
};
