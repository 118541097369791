import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { createBemBlockBuilder, Hyperlink } from '@ebsco-ui/ebsco-ui';

import {
  ModalNotification,
  ModalNotificationType,
  getErrorNotificationProps,
} from '@app/components';
import { useAppContext } from '@app/contexts';
import { useFeatureFlag } from '@app/hooks';
import { ROUTE, NOTIFICATION_STATUS, FEATURE } from '@app/constants';

import './PlaceHoldNotification.scss';

interface PlaceHoldNotificationProps {
  type: ModalNotificationType;
  queuePosition?: number;
  errorMessage?: string;
}

const cnBem = createBemBlockBuilder(['holdModalNotification']);

export const PlaceHoldNotification: FC<PlaceHoldNotificationProps> = ({
  type,
  queuePosition = 1,
  errorMessage = '',
}) => {
  const appContext = useAppContext();
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);
  const isConsortiaLogin = Boolean(consortiaLoginFlowFlag?.isActive);

  const modalNotifications = {
    success: {
      type: NOTIFICATION_STATUS.success,
      header: (
        <FormattedMessage
          id="placeHoldModal.holdHasBeenPlaced"
          defaultMessage="Hold has been placed"
        />
      ),
      children: !isConsortiaLogin && (
        <FormattedMessage
          id="placeHoldModal.youAreCurrentlyInLine"
          defaultMessage="You are currently {numberInLine, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} in line."
          values={{ numberInLine: queuePosition }}
        />
      ),
    },
    information: {
      type: NOTIFICATION_STATUS.information,
      header: (
        <FormattedMessage id="placeHoldModal.onHold" defaultMessage="On hold" />
      ),
      children: (
        <FormattedMessage
          id="placeHoldModal.alreadyOnHold"
          defaultMessage="You already have this item on hold. Manage your hold in your <a>bookshelf</a>."
          values={{
            a: chunks => (
              <Hyperlink href={ROUTE.holds} target="_self" styleType="title">
                {chunks}
              </Hyperlink>
            ),
          }}
        />
      ),
    },
    error: getErrorNotificationProps({
      title: (
        <FormattedMessage
          id="placeHoldModal.unableToPlaceHold"
          defaultMessage="We were unable to place a hold on this book."
        />
      ),
      message: errorMessage,
    }),
  };

  return (
    <>
      <ModalNotification type={type} modalNotifications={modalNotifications} />
      {type === 'success' && !isConsortiaLogin && (
        <p className={cnBem('__notificationText')}>
          <FormattedMessage
            id="placeHoldModal.wellLetYouKnow"
            defaultMessage="When the item is available we’ll let you know at this email address: {email}"
            values={{
              email: (
                <Hyperlink
                  href={`mailto:${appContext.patronSession?.user?.email}`}
                  target="_self"
                  styleType="title"
                >
                  {appContext.patronSession?.user?.email}
                </Hyperlink>
              ),
            }}
          />
        </p>
      )}
    </>
  );
};
