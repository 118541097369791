import { defineMessages } from 'react-intl';

export const footerLinksMessages = defineMessages({
  help: {
    id: 'footerLinks.help',
    defaultMessage: 'Help',
  },
  disclaimer: {
    id: 'footerLinks.disclaimer',
    defaultMessage: 'Disclaimer',
  },
  privacyPolicy: {
    id: 'footerLinks.privacyPolicy',
    defaultMessage: 'Privacy policy',
  },
  termsOfUse: {
    id: 'footerLinks.termsOfUse',
    defaultMessage: 'Terms of use',
  },
});
