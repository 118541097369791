import { FC, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { compact } from 'lodash';
import cn from 'classnames';

import { createBemBlockBuilder, Tabs } from '@ebsco-ui/ebsco-ui';

import { SearchList, SavedInstancesList } from '@app/components';
import { useFeatureFlag } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { FEATURE } from '@app/constants';

import './MyDashboardPage.scss';

const cnBem = createBemBlockBuilder(['myDashboardPage']);

export const MyDashboardPage: FC = () => {
  const { $t } = useIntl();
  const [selectedTab, setSelectedTab] = useState(0);
  const saveInstancesFlag = useFeatureFlag(FEATURE.saveInstances);
  const searchesMessage = $t({
    id: 'myDashboard.searches',
    defaultMessage: 'Searches',
  });

  const tabs = compact([
    {
      title: searchesMessage,
      component: (
        <>
          {!saveInstancesFlag?.isActive && <h2>{searchesMessage}</h2>}
          <SearchList />
        </>
      ),
    },
    saveInstancesFlag?.isActive && {
      title: $t({
        id: 'myDashboard.saved',
        defaultMessage: 'Saved',
      }),
      component: <SavedInstancesList />,
    },
  ]);
  const i18n = useMemo(
    () => ({
      scrollControlStartTitle: $t(sharedMessages.scrollBackward),
      scrollControlEndTitle: $t(sharedMessages.scrollForward),
    }),
    [$t]
  );

  return (
    <div className={cn('container', cnBem('__container'))}>
      <Helmet>
        <title>
          {$t({
            id: 'myDashboard.documentTitle',
            defaultMessage: 'My dashboard - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <h1 className={cnBem('__title')}>
        {$t({
          id: 'myDashboard.myDashboard',
          defaultMessage: 'My dashboard',
        })}
      </h1>
      <Tabs
        selectedIndex={selectedTab}
        i18n={i18n}
        tabs={tabs}
        onSelect={index => setSelectedTab(index)}
      >
        {tabs.map(tab => (
          <Tabs.TabPanel key={tab.title}>{tab.component}</Tabs.TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
