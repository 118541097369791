import { defineMessages } from 'react-intl';

export const holdMessages = defineMessages({
  queue: {
    id: 'itemStatus.queue',
    defaultMessage:
      '{value, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} in line',
  },
  readyForPickup: {
    id: 'itemStatus.readyForPickup',
    defaultMessage: 'Ready for pickup',
  },
});
