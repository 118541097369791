import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';

import { ShowMore, Button } from '@ebsco-ui/ebsco-ui';

import {
  AvailabilityStatus,
  ShowMoreButton,
  StackMapModal,
} from '@app/components';
import { HoldingAvailability } from '@app/pages/constants';
import { useMappingsContext } from '@app/contexts';
import { axios } from '@app/utils';

import css from './AvailabilityTable.module.scss';

interface AvailabilityTableProps {
  records: HoldingAvailability[];
  maxAmountToDisplay: number;
}

export interface StackMapDto {
  mapurl: string;
  callNo: string;
  directions: string;
  ranges: {
    rangename: string;
  }[];
  printMapurl: string;
}

export const AvailabilityTable: FC<AvailabilityTableProps> = ({
  records,
  maxAmountToDisplay,
}) => {
  const [isStackMapModalOpen, setIsStackMapModalOpen] = useState(true);
  const [stackMapModalData, setStackMapModalData] = useState<StackMapDto>();
  const { $t } = useIntl();
  const {
    configurations: { isStackMapEnabled, libraryLocationMap },
  } = useMappingsContext();
  const hasHoldingsStatements = records.some(
    record => !isEmpty(record.holdingsStatements)
  );

  const stackMapQuery = useQuery(
    ['stackMap', records],
    () =>
      axios
        .post(
          '/opac-tools/map',
          records.map(record => ({
            callno: record.callNumber,
            library: record.library[libraryLocationMap.library],
            location: record.location[libraryLocationMap.location],
          }))
        )
        .then(response => response.data),
    {
      enabled:
        isStackMapEnabled &&
        Boolean(libraryLocationMap.location) &&
        Boolean(libraryLocationMap.library),
    }
  );

  const wrapCellContent = useCallback(
    ({ column, columnIndex, children, className = '', ...props }) => (
      <td
        data-label={column.label}
        className={cn(css.cell, className, {
          [css.withHoldingsStatement]: hasHoldingsStatements,
        })}
        key={columnIndex}
        {...props}
      >
        {children}
      </td>
    ),
    [hasHoldingsStatements]
  );

  const handleStackMapModalOpen = stackMapData => {
    setStackMapModalData(stackMapData);
    setIsStackMapModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        label: $t({
          id: 'holdingsTable.status',
          defaultMessage: 'Status:',
        }),
        formatter: (record, column, columnIndex) =>
          wrapCellContent({
            column,
            columnIndex,
            children: (
              <AvailabilityStatus
                status={record.status}
                dueDate={record.dueDate}
              />
            ),
          }),
      },
      {
        label: $t({
          id: 'holdingsTable.Library',
          defaultMessage: 'Library:',
        }),
        formatter: (record, column, columnIndex) =>
          wrapCellContent({
            column,
            columnIndex,
            children: record.library.name,
          }),
      },
      {
        label: $t({
          id: 'holdingsTable.location',
          defaultMessage: 'Location:',
        }),
        formatter: (record, column, columnIndex) =>
          wrapCellContent({
            column,
            columnIndex,
            children: record.location.name,
          }),
      },
      {
        label: $t({
          id: 'holdingsTable.callNumber',
          defaultMessage: 'Call number:',
        }),
        headerRowProps: {
          colSpan: 3,
        },
        formatter: (record, column, columnIndex) => {
          const { callNumber = '', volume = '' } = record;
          const stackMapData = stackMapQuery.data?.find(
            stackMapRecord => stackMapRecord.callNo === callNumber
          );

          const hasStackMapData = Boolean(stackMapData?.mapurl);
          const callNumberCell = wrapCellContent({
            column,
            columnIndex,
            children: `${callNumber} ${volume}`,
            className: css.stackMapCell,
            colSpan: 2,
          });

          return (
            <Fragment key={columnIndex}>
              {callNumberCell}
              <td>
                {isStackMapEnabled && hasStackMapData && (
                  <Button
                    className={css.mapItButton}
                    onClick={() => handleStackMapModalOpen(stackMapData)}
                  >
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    {$t({
                      id: 'holdingsTable.mapIt',
                      defaultMessage: 'Map it',
                    })}
                  </Button>
                )}
              </td>
            </Fragment>
          );
        },
      },
      ...(hasHoldingsStatements
        ? [
            {
              label: $t({
                id: 'holdingsTable.holdingsStatements',
                defaultMessage: 'Holdings statement:',
              }),
              formatter: (record, column, columnIndex) =>
                wrapCellContent({
                  column,
                  columnIndex,
                  children: record.holdingsStatements
                    .map(holdingsStatement => holdingsStatement.statement)
                    .join(', '),
                  className: css.cell,
                }),
            },
          ]
        : []),
    ],
    [
      hasHoldingsStatements,
      $t,
      isStackMapEnabled,
      stackMapQuery.data,
      wrapCellContent,
    ]
  );

  const getHeaderRow = () => {
    return (
      <tr className={css.row}>
        {columns.map((column, index) => (
          <th
            className={css.headerCell}
            key={index}
            scope="col"
            {...column.headerRowProps}
          >
            {column.label}
          </th>
        ))}
      </tr>
    );
  };

  const getBodyRows = () => {
    return records.map(record => (
      <tr
        className={css.row}
        key={record.id}
        data-testid="availability-table-content"
      >
        {columns.map((column, columnIndex) =>
          column.formatter(record, column, columnIndex)
        )}
      </tr>
    ));
  };

  const items = getBodyRows();

  return (
    <ShowMore limit={maxAmountToDisplay}>
      {stackMapModalData && (
        <StackMapModal
          isOpen={isStackMapModalOpen}
          stackMapData={stackMapModalData}
          onModalClose={() => setIsStackMapModalOpen(false)}
        />
      )}
      <div className={css.wrapper}>
        <table
          className={css.table}
          aria-label={$t({
            id: 'holdingsTable.libraryHoldings',
            defaultMessage: 'Library holdings',
          })}
        >
          <thead className={css.head}>{getHeaderRow()}</thead>
          <tbody className={css.body}>
            <ShowMore.Items>{items}</ShowMore.Items>
          </tbody>
        </table>
        <ShowMoreButton
          showMoreLimit={maxAmountToDisplay}
          dataLength={records.length}
          styleType="link"
          className={css.toggle}
        />
      </div>
    </ShowMore>
  );
};
