import { FC } from 'react';

import { LoginSwitchPage, NativeLoginPage } from '@app/pages';
import { useFeatureFlag } from '@app/hooks';
import { FEATURE } from '@app/constants';

export const LoginPage: FC = () => {
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);

  return consortiaLoginFlowFlag?.isActive ? (
    <LoginSwitchPage />
  ) : (
    <NativeLoginPage />
  );
};
