import { ReactElement } from 'react';

import { TextInput as TextFieldComponent } from '@ebsco-ui/ebsco-ui';

import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';

export const TextField = ({
  component,
  ...props
}: FieldWrapperProps): ReactElement => (
  <FieldWrapper component={TextFieldComponent} {...props} />
);
