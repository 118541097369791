import { noop } from 'lodash';
import { QueryClient } from '@tanstack/react-query';
import { IS_TEST_ENV } from '@app/constants';

const cacheTimeMinutes = 5;

export const createReactQueryClient = (): QueryClient =>
  new QueryClient({
    logger: {
      // eslint-disable-next-line no-console
      log: console.log,
      warn: console.warn,
      // turn off react-query errors in console for testing scenarios with failed requests
      error: IS_TEST_ENV ? noop : console.error,
    },
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * cacheTimeMinutes,
      },
    },
  });
