import { FC, ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';

import { ShowMore } from '@ebsco-ui/ebsco-ui';

import { sharedMessages } from '@app/translations';

interface ShowMoreButtonProps
  extends Partial<ComponentProps<typeof ShowMore.Button>> {
  showMoreLimit: number;
  dataLength: number;
}

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({
  showMoreLimit,
  dataLength,
  ...props
}) => {
  return (
    <ShowMore.Button
      {...props}
      expandContent={
        <FormattedMessage
          {...sharedMessages.plusMore}
          values={{
            amount: dataLength - showMoreLimit,
          }}
        />
      }
      collapseContent={<FormattedMessage {...sharedMessages.showLess} />}
    />
  );
};
