import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { without } from 'lodash';

import { Dropdown } from '@app/components';
import { SEARCH_OPTION, SEARCH_OPTIONS } from '@app/search';
import { useFeatureFlag } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { FEATURE } from '@app/constants';

import css from './SearchOptionsDropdown.module.scss';

interface SearchOptionsDropdownProps {
  option: string;
  onSelect: (searchOption: string) => void;
}

export const SearchOptionsDropdown: FC<SearchOptionsDropdownProps> = ({
  option,
  onSelect,
}) => {
  const intl = useIntl();
  const LCCNSearchFlag = useFeatureFlag(FEATURE.LCCNSearch);

  const searchOptions = LCCNSearchFlag?.isActive
    ? SEARCH_OPTIONS
    : without(SEARCH_OPTIONS, SEARCH_OPTION.lccn);

  const selectedOption = useMemo(
    () => ({
      label: intl.$t(sharedMessages[option]),
      value: option,
    }),
    [intl, option]
  );

  const options = useMemo(
    () =>
      searchOptions.map(searchOption => ({
        label: intl.$t(sharedMessages[searchOption]),
        value: searchOption,
      })),
    [intl, searchOptions]
  );

  const handleSelectedItemChange = useCallback(
    ({ selectedItem }) => {
      onSelect(selectedItem);
    },
    [onSelect]
  );

  return (
    <Dropdown
      id="searchOptionsDropdown"
      items={options}
      label={intl.$t({
        id: 'searchOptionsDropdown.label',
        defaultMessage: 'Search option',
      })}
      hideLabel
      className={css.searchOptionsDropdown}
      selectedItem={selectedOption}
      onSelectedItemChange={handleSelectedItemChange}
    />
  );
};
