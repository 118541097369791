import { FC } from 'react';
import cn from 'classnames';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { Icon } from '@app/components';
import { useAppContext } from '@app/contexts';
import { useFeatureFlag, UserPersonalInfo } from '@app/hooks';
import { FEATURE, PropsWithClassName } from '@app/constants';

import { getUsersInitials } from './getUsersInitials';

import './AccountIcon.scss';

const cnBem = createBemBlockBuilder(['accountIcon']);

export const AccountIcon: FC<PropsWithClassName> = ({ className }) => {
  const { patronSession, isLoggedIn } = useAppContext();
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);

  const getUnauthorizedUser = () => (
    <Icon
      icon={faUser}
      className={cn(cnBem('__icon'), {
        [cnBem('__icon--loggedIn')]: isLoggedIn,
      })}
    />
  );

  const getAuthorizedUser = () => (
    <div className={cnBem('__username')}>
      {consortiaLoginFlowFlag?.isActive
        ? getUnauthorizedUser()
        : getUsersInitials(patronSession?.user as Partial<UserPersonalInfo>)}
    </div>
  );

  return (
    <div className={cn(cnBem('__wrapper'), className)}>
      {isLoggedIn ? getAuthorizedUser() : getUnauthorizedUser()}
    </div>
  );
};
