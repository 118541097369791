import { createElement, FC } from 'react';
import { Outlet, useLocation, matchPath } from 'react-router-dom';
import rtlDetect from 'rtl-detect';

import { ToastContainer } from '@ebsco-ui/ebsco-ui';

import { BackToTopButton, Header, Footer } from '@app/components';
import { useAppContext } from '@app/contexts';
import { ROUTE } from '@app/constants';

import css from './NavigationLayout.module.scss';

export const NavigationLayout: FC = () => {
  const { locale } = useAppContext();
  const { pathname } = useLocation();

  const getMainWrapper = children => {
    const isSearchResultsPage = pathname === ROUTE.search;
    const isInstanceDetailsPage = Boolean(
      matchPath(ROUTE.instanceDetails, pathname)
    );

    return createElement(
      isSearchResultsPage || isInstanceDetailsPage ? 'div' : 'main',
      {
        className: css.mainWrapper,
        'data-focus': 'focusedNode',
        tabIndex: -1,
      },
      children
    );
  };

  return (
    <>
      <Header />
      {getMainWrapper(<Outlet />)}
      <div className={css.pageButtonsWrapper}>
        <BackToTopButton />
      </div>
      <Footer />
      <ToastContainer rtl={rtlDetect.isRtlLang(locale)} />
    </>
  );
};
