import { FC } from 'react';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { SortByDropdown, SortByDropdownProps } from '@app/components';

import './SortByDropdownWithIcon.scss';

const cnBem = createBemBlockBuilder(['sortByWithIcon']);

export const SortByDropdownWithIcon: FC<SortByDropdownProps> = ({
  options,
  sortField,
  sortDirection,
  onSelect,
}) => {
  return (
    <div className={cnBem()}>
      <SortByDropdown
        options={options}
        styleType="standard"
        sortField={sortField}
        sortDirection={sortDirection}
        hideLabel
        onSelect={onSelect}
      />
    </div>
  );
};
