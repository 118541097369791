import { FeatureFlag, useFeatureFlagsContext } from '@app/contexts';
import { FEATURE } from '@app/constants';

export const useFeatureFlag = (
  flagName: FEATURE,
  isActiveIfNotFound = false
): FeatureFlag | undefined => {
  const featureFlags = useFeatureFlagsContext();

  return (
    featureFlags.find(flag => flag.name === flagName) ?? {
      name: flagName as FEATURE,
      isActive: isActiveIfNotFound,
    }
  );
};
