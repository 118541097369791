import { FC } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import { PropsWithClassName } from '@app/constants';

import css from './Icon.module.scss';

interface IconProps {
  icon?: IconDefinition;
}

export const Icon: FC<PropsWithClassName<IconProps>> = ({
  icon,
  className,
  children,
}) => {
  return (
    <>
      {icon && (
        <FontAwesomeIcon className={cn(css.icon, className)} icon={icon} />
      )}
      {children}
    </>
  );
};
