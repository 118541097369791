import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import {
  ChildInstancesDto,
  ParentInstancesDto,
} from '@app/pages/SearchResultsPage';
import { useFeatureFlag } from '@app/hooks';
import { axios } from '@app/utils';
import { FEATURE } from '@app/constants';

interface RelatedInstance {
  id: string;
  title: string;
}

export const useInstanceRelationships = (
  relatedInstances: ParentInstancesDto[] | ChildInstancesDto[] = []
): RelatedInstance[] => {
  const instanceRelationshipFlag = useFeatureFlag(FEATURE.instanceRelationship);

  return useQuery(
    ['instancesBatch', relatedInstances],
    () =>
      axios
        .post('/opac-inventory/instances/batch', {
          instanceIds: relatedInstances.flatMap(
            instance =>
              instance?.superInstanceId || instance?.subInstanceId || []
          ),
          fields: ['id', 'title'],
        })
        .then(response => response.data.instances),
    {
      enabled: !isEmpty(relatedInstances) && instanceRelationshipFlag?.isActive,
    }
  ).data;
};
