import { defineMessages } from 'react-intl';

export const courseReservesMessages = defineMessages({
  courseCode: {
    id: 'courseReservesTable.coursesCode',
    defaultMessage: 'Course code',
  },
  courseName: {
    id: 'courseReservesTable.coursesName',
    defaultMessage: 'Course name',
  },
  instructor: {
    id: 'courseReservesTable.instructor',
    defaultMessage: 'Instructor',
  },
  documentTitle: {
    id: 'courseReserves.documentTitle',
    defaultMessage: 'Course reserves - EBSCO Locate',
  },
  emptyStateTitle: {
    id: 'courseReserves.noResults',
    defaultMessage: 'No course reserves found',
  },
  pageTitle: {
    id: 'courseReserves.title',
    defaultMessage: 'Course reserves',
  },
  paginationTitleWithRecords: {
    id: 'courseReserves.paginationTitleWithRecords',
    defaultMessage:
      '{totalRecords, plural, one {1-{loadedRecordsAmount} of # course} other {1-{loadedRecordsAmount} of # courses}}',
  },
  paginationTitleNoRecords: {
    id: 'courseReserves.paginationTitleNoRecords',
    defaultMessage: '{totalRecords, plural, one {# course} other {# courses}}',
  },
  searchBarPlaceholder: {
    id: 'courseReserves.searchBarPlaceholder',
    defaultMessage:
      'Search by course code, course name, instructor, or department',
  },
  showMoreButtonTitle: {
    id: 'courseReservesTable.showMoreButtonTitle',
    defaultMessage: 'Show more courses',
  },
  browseByDepartment: {
    id: 'courseReserves.browseByDepartment',
    defaultMessage: 'Browse by department',
  },
  browseByInstructor: {
    id: 'courseReserves.browseByInstructor',
    defaultMessage: 'Browse by instructor',
  },
});
