import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  CourseReserveDetailsData,
  CourseReservesItemDto,
  ReserveListingsDto,
} from '@app/pages/CourseReservesPage/courseReservesTypes';
import { SearchInstanceDto } from '@app/pages/SearchResultsPage/searchResultsTypes';
import { SearchState } from '@app/pages/CourseReserveDetailsPage/courseReserveTypes';
import { ALL_RECORDS_CQL, SORT_FIELD } from '@app/search';
import { axios } from '@app/utils';
import { RECORDS_PER_PAGE, SORT_DIRECTION } from '@app/constants';

import { usePaging } from '..';

interface ReserveListings {
  reserves: ReserveListingsDto[];
  totalRecords: number;
}

interface CourseReserveDetailsDto {
  courses: CourseReservesItemDto[];
}

interface ReserveListingsTransformed {
  reserves: CourseReserveDetailsData[];
  totalRecords: number;
}

interface CourseReserveDetails {
  courseQuery: UseQueryResult<CourseReserveDetailsDto>;
  courseListingsQuery: UseQueryResult<ReserveListingsTransformed>;
  courseReserve: CourseReservesItemDto;
  totalRecords: number;
  pages: SearchInstanceDto[][];
  currentPage?: CourseReserveDetailsData[];
}

export const sortFieldMap = {
  [SORT_FIELD.title]: 'copiedItem.title',
  [SORT_FIELD.author]: 'copiedItem.contributors',
  [SORT_FIELD.publicationYear]: 'copiedItem.publicationYear',
};

const formatCourseListingsData = (
  data: ReserveListingsDto[] = []
): CourseReserveDetailsData[] =>
  data.map(
    ({
      copiedItem: {
        instanceId,
        title,
        contributors,
        publication,
        sourceTypes,
        isbns,
      },
    }) => ({
      id: instanceId,
      title,
      publication,
      contributors,
      sourceTypes,
      isbns,
    })
  );

export const useCourseReserveDetailsFetch = (
  state: SearchState
): CourseReserveDetails => {
  const { id } = useParams();
  const [courseReserve, setCourseReserve] = useState<CourseReservesItemDto>(
    null!
  );
  const [courseListingId, setCourseListingId] = useState('');
  const [pages, setPages] = useState<SearchInstanceDto[][]>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const courseReserveDetailsQuery = useQuery(
    ['courseReserveDetails', id],
    async () =>
      axios
        .get<CourseReserveDetailsDto>('/opac-courses/courses', {
          params: {
            query: `id==${id}`,
          },
        })
        .then(response => response.data)
  );

  useEffect(() => {
    if (courseReserveDetailsQuery.data) {
      setCourseReserve(courseReserveDetailsQuery.data.courses[0]);
      setCourseListingId(
        courseReserveDetailsQuery.data.courses[0].courseListingId
      );
    }
  }, [courseReserveDetailsQuery.data]);

  const courseListingsQuery = useQuery<
    ReserveListings,
    unknown,
    ReserveListingsTransformed
  >(
    ['courseListings', courseListingId, state],
    () =>
      axios
        .get<ReserveListings>(
          `/opac-courses/courselistings/${courseListingId}/reserves`,
          {
            params: {
              query: `${ALL_RECORDS_CQL} sortBy ${
                sortFieldMap[state.sortField ?? SORT_FIELD.title]
              }/sort.${state.sortDirection ?? SORT_DIRECTION.asc}`,
              limit: RECORDS_PER_PAGE,
              ...(state.pageNumber > 1 && {
                offset: RECORDS_PER_PAGE * (state.pageNumber - 1),
              }),
            },
          }
        )
        .then(response => response.data),
    {
      enabled: Boolean(courseListingId),
      cacheTime: 0,
      select: data => ({
        ...data,
        reserves: formatCourseListingsData(data.reserves),
      }),
    }
  );

  useEffect(() => {
    if (courseListingsQuery.data?.totalRecords !== undefined) {
      setTotalRecords(courseListingsQuery.data.totalRecords);
    }
  }, [courseListingsQuery.data?.totalRecords]);

  usePaging<CourseReserveDetailsData>({
    areRecordsFetched: courseListingsQuery.isSuccess,
    records: courseListingsQuery.data?.reserves,
    pageNumber: state.pageNumber,
    setPages,
  });

  return {
    courseReserve,
    courseQuery: courseReserveDetailsQuery,
    courseListingsQuery,
    pages,
    totalRecords,
    currentPage: courseListingsQuery.data?.reserves,
  };
};
