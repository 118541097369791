import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';

import { useQueryParams } from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { ROUTE, SORT_DIRECTION } from '@app/constants';

import { getURLState, SearchState } from '..';

interface UrlManager {
  updateUrl: (searchState: Partial<SearchState>, pathname?: string) => void;
  getSearchStateFromUrl: (customQuery?: string | ParsedQuery) => SearchState;
}

const parseSortState = sort => {
  let sortField = sort;
  let sortDirection = SORT_DIRECTION.asc;

  if (sort.startsWith('-')) {
    sortField = sort.slice(1);
    sortDirection = SORT_DIRECTION.desc;
  }

  return {
    sortField,
    sortDirection,
  };
};

export const useUrlManager = (): UrlManager => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const updateUrl = useCallback(
    (searchState, pathname = ROUTE.search) => {
      navigate(
        generateRouteParams({
          pathname,
          query: getURLState(searchState),
        })
      );
    },
    [navigate]
  );

  const getSearchStateFromUrl = useCallback(
    (customQuery = queryParams) => {
      return {
        ...customQuery,
        ...(customQuery.sort && parseSortState(customQuery.sort)),
        ...(customQuery.facets && {
          facets: queryString.parse(customQuery.facets as string, {
            arrayFormat: 'bracket-separator',
          }),
        }),
        ...(customQuery.publicationYear && {
          publicationYear: queryString.parse(
            customQuery.publicationYear as string
          ),
        }),
      } as unknown as SearchState;
    },
    [queryParams]
  );

  return {
    updateUrl,
    getSearchStateFromUrl,
  };
};
