import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Hyperlink } from '@ebsco-ui/ebsco-ui';

import { sharedMessages } from '@app/translations';

interface ExternalLinkProps {
  href: string;
}

export const ExternalLink: FC<ExternalLinkProps> = ({ children, href }) => {
  const { $t } = useIntl();

  return (
    <Hyperlink href={href} target="_blank" rel="noreferrer">
      {children}{' '}
      <span className="visuallyHidden">{$t(sharedMessages.externalLink)}</span>
    </Hyperlink>
  );
};
