import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  CourseReservesDto,
  CourseReservesItemDto,
  Resource,
} from '@app/pages/constants';
import { axios, escapeForbiddenSymbolsInQuery } from '@app/utils';
import { RECORDS_PER_PAGE, SORT_DIRECTION } from '@app/constants';

import { usePaging, useQueryParams } from '..';

export const useCourseReservesFetch = (
  pageNumber: number,
  searchQuery: string
): Resource<CourseReservesItemDto, CourseReservesDto> => {
  const [pages, setPages] = useState<CourseReservesItemDto[][]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const escapedQuery = escapeForbiddenSymbolsInQuery(searchQuery);
  const { sort = SORT_DIRECTION.asc } = useQueryParams();

  const sortQuery = `sortBy name/sort.${sort}`;

  const courseReservesQuery = useQuery(
    ['courseReservesPage', pageNumber, searchQuery, sort],
    () =>
      axios
        .get<CourseReservesDto>('/opac-courses/courses', {
          params: {
            status: 'active',
            limit: RECORDS_PER_PAGE,
            ...(pageNumber > 1 && {
              offset: RECORDS_PER_PAGE * (pageNumber - 1),
            }),
            ...{
              query: searchQuery
                ? `name="${escapedQuery}*" OR courseListing.instructorObjects="${escapedQuery}*" OR courseNumber="${escapedQuery}*" OR department.name="${escapedQuery}*" ${sortQuery}`
                : `cql.allrecords=1 ${sortQuery}`,
            },
          },
        })
        .then(response => response.data)
  );

  useEffect(() => {
    if (courseReservesQuery.data?.totalRecords !== undefined) {
      setTotalRecords(courseReservesQuery.data.totalRecords);
    }
  }, [courseReservesQuery.data?.totalRecords]);

  usePaging<CourseReservesItemDto>({
    areRecordsFetched: courseReservesQuery.isSuccess,
    records: courseReservesQuery.data?.courses,
    pageNumber,
    setPages,
  });

  return {
    pages,
    totalRecords,
    queryResult: courseReservesQuery,
  };
};
