import { createContext, useContext } from 'react';

import { RecordAvailabilityStatus } from '@app/components';
import { HoldingAvailability } from '@app/pages/constants';

export interface SummaryAvailability {
  status: RecordAvailabilityStatus;
  isRequestable: boolean;
  isRTACApplicable: boolean;
  hasVolumes: boolean;
  hasItems?: boolean;
  copiesRemaining?: {
    available: number;
    total: number;
  };
}

export interface RecordAvailability {
  availabilityData: HoldingAvailability[];
  summaryAvailability: SummaryAvailability;
}

export interface AvailabilityContextProps {
  getAvailability: (id: string | string[], pageNumber?: number) => void;
  isAvailabilityLoading: boolean;
  availability: Record<string, RecordAvailability>;
}

export const AvailabilityContext = createContext<AvailabilityContextProps>(
  null!
);

export const useAvailabilityContext = (): AvailabilityContextProps =>
  useContext(AvailabilityContext);
