import { useCallback, useEffect, useState } from 'react';

export const useOnScroll = (): number => {
  const [depth, setDepth] = useState(0);
  const [mainContainer, setMainContainer] = useState<null | HTMLElement>(null);

  const updateDepth = useCallback(() => {
    if (mainContainer) {
      setDepth(mainContainer.scrollTop);
    }
  }, [mainContainer]);

  useEffect(() => {
    setMainContainer(document.getElementById('mainContainer'));
  }, []);

  useEffect(() => {
    if (mainContainer) {
      mainContainer.addEventListener('scroll', updateDepth);
    }

    return () => mainContainer?.removeEventListener('scroll', updateDepth);
  }, [mainContainer, updateDepth]);

  return depth;
};
