import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useAppContext } from '@app/contexts';
import { useFeatureFlag } from '@app/hooks';
import { axios } from '@app/utils';
import { FEATURE, INVOKE_PATH_PREFIX } from '@app/constants';

interface PlaceHoldRequestProps {
  instanceId: string;
  pickupLocationId: string;
  itemId?: string;
}

export interface PlaceHoldDto {
  requestId: string;
  item: {
    instanceId: string;
    itemId: string;
    title: string;
  };
  requestDate: string;
  status: string;
  pickupLocationId: string;
  queuePosition: number;
}

export interface HoldActionErrorDto {
  response: {
    data:
      | string
      | {
          errorMessage: string;
        };
  };
}

export const usePlaceHoldMutation = (): UseMutationResult<
  PlaceHoldDto,
  HoldActionErrorDto,
  PlaceHoldRequestProps
> => {
  const appContext = useAppContext();
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);

  return useMutation(
    ({ instanceId, pickupLocationId, itemId }: PlaceHoldRequestProps) => {
      const isConsortiaLogin = Boolean(consortiaLoginFlowFlag?.isActive);
      const pathPrefix = isConsortiaLogin ? INVOKE_PATH_PREFIX : '';
      const patronPath = `${pathPrefix}/opac-patron/account/${appContext?.patronSession?.user?.externalSystemId}`;
      const holdPath = itemId
        ? `/item/${itemId}/hold`
        : `/instance/${instanceId}/hold`;

      return axios
        .post(
          `${patronPath}${holdPath}`,
          {
            pickupLocationId,
          },
          {
            withCredentials: isConsortiaLogin,
          }
        )
        .then(response => response.data);
    }
  );
};
