import { useEffect, useState } from 'react';
import rtlDetect from 'rtl-detect';

import supportedLocales from '@app/components/LocaleDropdown/supportedLocales.json';
import { ENGLISH_LOCALE_KEY, SetState } from '@app/constants';

export const getIntlMessages = (
  locale: string
): Promise<Record<string, string>> => {
  return locale === ENGLISH_LOCALE_KEY
    ? Promise.resolve({})
    : import(`../lang/${locale}.json`);
};

const getUserLocale = (): string => {
  const selectedLocale = localStorage.getItem('locale');

  if (!selectedLocale) {
    const browserLocale = navigator.languages
      ? navigator.languages[0]
      : navigator.language;

    return (
      supportedLocales.find(({ id }) => browserLocale.startsWith(id))?.id ||
      ENGLISH_LOCALE_KEY
    );
  }

  return selectedLocale;
};

interface AppLocale {
  locale: string;
  translationMessages: Record<string, string>;
  setSelectedLocale: SetState<string>;
}

export const useLocale = (): AppLocale => {
  const [selectedLocale, setSelectedLocale] = useState<string>(getUserLocale());
  const [locale, setLocale] = useState<string>('');
  const [translationMessages, setTranslationMessages] = useState({});

  useEffect(() => {
    const updateLocale = (updatedLocale, updatedMessages) => {
      setLocale(updatedLocale);
      setTranslationMessages(updatedMessages);

      localStorage.setItem('locale', updatedLocale);

      document.documentElement.setAttribute('lang', updatedLocale);
      document.documentElement.setAttribute(
        'dir',
        rtlDetect.getLangDir(updatedLocale)
      );
    };

    if (selectedLocale) {
      getIntlMessages(selectedLocale)
        .then(translation => updateLocale(selectedLocale, translation.default))
        .catch(() => updateLocale(ENGLISH_LOCALE_KEY, {}));
    }
  }, [selectedLocale]);

  return {
    locale,
    translationMessages,
    setSelectedLocale,
  };
};
