import { defineMessages } from 'react-intl';

export const callNumbersMessages = defineMessages({
  authors: {
    id: 'callNumbersTable.authors',
    defaultMessage: 'Author(s)',
  },
  documentTitle: {
    id: 'callNumbers.documentTitle',
    defaultMessage: 'Call numbers - EBSCO Locate',
  },
  callNumber: {
    id: 'callNumbersTable.callNumber',
    defaultMessage: 'Call number',
  },
  emptyStateTitle: {
    id: 'callNumbers.emptyState',
    defaultMessage:
      'Browse call numbers by entering a complete or partial call number.',
  },
  pageTitle: {
    id: 'callNumbers.title',
    defaultMessage: 'Call number browse',
  },
  searchBarPlaceholder: {
    id: 'callNumbers.searchBarPlaceholder',
    defaultMessage: 'Enter a partial or full call number',
  },
  showMoreButtonTitle: {
    id: 'callNumbersTable.showMoreButtonTitle',
    defaultMessage: 'Show more call numbers',
  },
  year: {
    id: 'callNumbersTable.publicationYear',
    defaultMessage: 'Year',
  },
  yourCallNumber: {
    id: 'callNumbersTable.yourCallNumber',
    defaultMessage: 'Your call number: <b>{callNumber}</b>',
  },
  yourCallNumberWouldBeHere: {
    id: 'callNumbersTable.yourCallNumberWouldBeHere',
    defaultMessage: 'Your call number {callNumber} would be here',
  },
});
