import { ComponentProps, FC } from 'react';
import { useIntl } from 'react-intl';
import { useCombobox } from 'downshift';

import { SearchAutocomplete } from '@ebsco-ui/ebsco-ui';

import { FieldsDropdown, AutoCompleteGroups } from '@app/components';
import { SEARCH_OPTION } from '@app/search';
import { useAutoComplete } from '@app/hooks';
import { sharedMessages } from '@app/translations';

import css from './SearchRow.module.scss';

export interface RowValues {
  value: string;
  searchOption: SEARCH_OPTION;
  booleanChip?: BOOLEAN_CHIP;
}

interface SearchRowProps extends RowValues {
  index: number;
  totalRowsCount: number;
  updateRow: (data: RowValues) => void;
}

export type BOOLEAN_CHIP = Exclude<
  ComponentProps<typeof SearchAutocomplete>['booleanChipProps'],
  undefined
>['value'];

export const booleanChips = {
  and: 'and' as BOOLEAN_CHIP,
  or: 'or' as BOOLEAN_CHIP,
  not: 'not' as BOOLEAN_CHIP,
};

export const SearchRow: FC<SearchRowProps> = ({
  value,
  searchOption,
  booleanChip,
  index,
  totalRowsCount,
  updateRow,
}) => {
  const { $t } = useIntl();

  const onSelectItem = ({ selectedValue, selectedOption }) => {
    updateRow({
      value: selectedValue,
      searchOption: selectedOption,
      booleanChip,
    });
  };

  const { inputValue, suggestions, handleSelectItem, handleInputValueChange } =
    useAutoComplete({ value, searchOption, onSelectItem });

  const hasBooleanChip = Boolean(booleanChip);
  const isKeywordSearch = searchOption === SEARCH_OPTION.keyword;

  const handleInputChange = changes => {
    handleInputValueChange(changes);

    if (
      changes.type === useCombobox.stateChangeTypes.InputChange ||
      changes.type === useCombobox.stateChangeTypes.FunctionReset
    ) {
      updateRow({
        value: changes.inputValue,
        booleanChip,
        searchOption,
      });
    }
  };

  const handleBooleanChipChange = (updatedChip: BOOLEAN_CHIP) => {
    updateRow({ value, searchOption, booleanChip: updatedChip });
  };

  const handleFieldsChange = updatedSearchOption => {
    updateRow({ value, booleanChip, searchOption: updatedSearchOption });
  };

  const autocompleteLabel = $t({
    id: 'advancedSearch.inputField',
    defaultMessage: 'Search input field',
  });
  const legendId = `row-${index}-legend`;

  return (
    <fieldset
      role="group"
      aria-label={$t({
        id: 'advancedSearch.row',
        defaultMessage: 'Row',
      })}
      aria-describedby={legendId}
      className={css.searchRow}
    >
      <legend id={legendId} className={css.legend}>
        {$t(
          {
            id: 'advancedSearch.rowTitle',
            defaultMessage: 'Row {number} of {total}.',
          },
          {
            number: index + 1,
            total: totalRowsCount,
          }
        )}
      </legend>
      <div className={css.wrapper}>
        <SearchAutocomplete
          items={suggestions}
          {...(isKeywordSearch && {
            groupItemsBy: item => item.category,
            groups: AutoCompleteGroups,
          })}
          value={inputValue}
          messages={{
            autocompleteMessages: {
              input: {
                'aria-label': autocompleteLabel,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'aria-describedby': legendId,
              },
              clearButton: {
                'aria-label': $t(sharedMessages.clear),
              },
              menu: {
                'aria-label': autocompleteLabel,
              },
            },
          }}
          hasBooleanChip={hasBooleanChip}
          booleanChipProps={{
            messages: {
              ...booleanChips,
              ariaRoledescription: $t({
                id: 'advancedSearch.booleanChipDescription',
                defaultMessage: 'Boolean operator toggle button',
              }),
              getPosition: (currentPosition, total) =>
                `${currentPosition} of ${total}`,
            },
            value: booleanChip || booleanChips.and,
            onChange: handleBooleanChipChange,
          }}
          displayHighlightedValue
          focusedByDefault={index === 0}
          className={css.autocomplete}
          downshiftOptions={{
            menuId: `row-${index}-autocomplete-menu`,
            onSelectedItemChange: handleSelectItem,
          }}
          onChange={handleInputChange}
        />
        <FieldsDropdown
          option={searchOption}
          id={`row-${index}-dropdown`}
          onSelect={handleFieldsChange}
        />
      </div>
    </fieldset>
  );
};
