import { FC } from 'react';

import { useBranding } from '@app/hooks';

export const ThemingConfig: FC = () => {
  const {
    colors: { primary },
  } = useBranding();

  return (
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: `:root {--eb-theme-brand: ${primary.main}; }`,
      }}
    />
  );
};
