import { ALL_RECORDS_CQL, searchOptionMap, SEARCH_OPTION } from '@app/search';

export const forbiddenCharacters = ['=', ';', ',', '%', '@', '#', '!'];

const sanitizeQuery = (query: string) => {
  const escapedQuery = query?.replace(/[\\"]/g, '\\$&');
  const forbiddenCharactersRegex = new RegExp(
    `[${forbiddenCharacters.join('\\')}]`,
    'g'
  );

  return escapedQuery.replace(forbiddenCharactersRegex, '').trim();
};

export const getQueryCQL = (query: string, option: string): string => {
  const sanitizedQuery = sanitizeQuery(query);

  if (
    searchOptionMap[option] === searchOptionMap[SEARCH_OPTION.keyword] &&
    (!query || query === '*')
  ) {
    return ALL_RECORDS_CQL;
  }

  if (query && option === SEARCH_OPTION.advanced) {
    return query;
  }

  return query && `${searchOptionMap[option]} "${sanitizedQuery}"`;
};
