import { Dispatch, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { PatronSessionState } from '@app/components';
import { axios } from '@app/utils';
import { INVOKE_PATH_PREFIX, USER_SESSION } from '@app/constants';

import { useLogin, PatronSession } from './useLogin';

interface SessionValidation {
  userSessionKey: USER_SESSION;
  isConsortiaLogin?: boolean;
  setIsOkapiSessionDown: Dispatch<boolean>;
  setPatronSession?: Dispatch<PatronSessionState>;
}

interface ValidatedSession {
  isLoggedIn: boolean;
  session: PatronSession | null;
  validateToken: ({ token, redirectRoute }) => void;
}

export const getOkapiSessionFromStorage = async (
  key: USER_SESSION
): Promise<PatronSession | null> => {
  return JSON.parse(sessionStorage.getItem(key) || 'null');
};

export const useSessionValidation = ({
  userSessionKey,
  isConsortiaLogin = false,
  setIsOkapiSessionDown,
  setPatronSession,
}: SessionValidation): ValidatedSession => {
  const [session, setSession] = useState<PatronSession | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [shouldGetGuestToken, setShouldGetGuestToken] = useState(false);
  const [isUserValidation, setIsUserValidation] = useState(false);
  const [SSORedirectRoute, setSSORedirectRoute] = useState();
  const { processPatronSession } = useLogin({
    isConsortiaLogin,
    setPatronSession,
  });
  const isGuestSession = userSessionKey === USER_SESSION.guest;

  const validateToken = ({ token, redirectRoute }) => {
    setIsUserValidation(true);
    setSSORedirectRoute(redirectRoute);
    setSession({ token });
  };

  const handleValidationError = error => {
    if ([401, 403, 404].includes(error.request.status)) {
      sessionStorage.removeItem(userSessionKey);

      if (isGuestSession) {
        setShouldGetGuestToken(true);
      }
    } else {
      setIsOkapiSessionDown(true);
    }
  };

  useQuery(
    ['validateUser', session],
    () => {
      const patronSession = session as PatronSession;

      return axios
        .get('/bl-users/_self', {
          headers: {
            'X-Okapi-Token': patronSession.token,
          },
        })
        .then(response => {
          if (isUserValidation) {
            processPatronSession(
              patronSession.token,
              response.data,
              SSORedirectRoute
            );
          }

          setIsLoggedIn(true);

          return null;
        })
        .catch(handleValidationError);
    },
    {
      enabled: Boolean(session) && !isConsortiaLogin,
    }
  );

  useQuery(
    ['validateConsortiaUser', session],
    () => {
      const patronSession = session as PatronSession;

      return axios
        .post(`${INVOKE_PATH_PREFIX}/opac-auth/patron-info`, undefined, {
          headers: {
            'X-Okapi-Token': patronSession.token,
          },
          withCredentials: true,
        })
        .then(response => {
          if (isUserValidation) {
            processPatronSession(
              patronSession.token,
              {
                user: {
                  externalSystemId: response.data.localPatronId[0],
                },
              },
              SSORedirectRoute
            );
          }

          setIsLoggedIn(true);

          return null;
        })
        .catch(handleValidationError);
    },
    {
      enabled: Boolean(session) && isConsortiaLogin,
    }
  );

  useQuery(
    ['guestToken', shouldGetGuestToken],
    () =>
      axios
        .get('/opac-auth/guest-token')
        .then(response => {
          const token = response.headers['x-okapi-token'];

          sessionStorage.setItem(USER_SESSION.guest, JSON.stringify({ token }));
          setSession({ token });

          return token;
        })
        .catch(() => setIsOkapiSessionDown(true)),
    {
      enabled: shouldGetGuestToken,
    }
  );

  useEffect(() => {
    getOkapiSessionFromStorage(userSessionKey).then(okapiSession => {
      if (isGuestSession && isEmpty(okapiSession)) {
        setShouldGetGuestToken(true);
      } else {
        setSession(okapiSession);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSessionKey]);

  return {
    isLoggedIn,
    session,
    validateToken,
  };
};
