import { FC, Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { isEmpty, isEqual, omit, isNumber } from 'lodash';
import queryString from 'query-string';

import {
  FACETS_ORDER,
  formatSelectedFacets,
  SelectedFacetAttributes,
} from '@app/components';
import {
  SearchInstanceDto,
  SearchResultsDto,
  SearchResultsErrorsDto,
} from '@app/pages/constants';
import { SearchContext, SearchContextProps } from '@app/contexts';
import {
  useInstanceDetailsRoutePath,
  usePaging,
  useQueryParams,
} from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { ROUTE, SORT_DIRECTION, URLQuery } from '@app/constants';

import { SEARCH_OPTION, SORT_FIELD, useSearchRequest, useUrlManager } from '..';

export type SelectedFacets = {
  [propName in (typeof FACETS_ORDER)[0]]?: string[];
};

export type PublicationYear = {
  minDate?: string;
  maxDate?: string;
};

export interface SearchState {
  option: SEARCH_OPTION;
  query?: string;
  advancedModal?: string;
  pageNumber?: number;
  sortField?: SORT_FIELD;
  sortDirection?: SORT_DIRECTION;
  facets?: SelectedFacets;
  isInnerUpdate?: boolean;
  publicationYear?: PublicationYear;
}

export type SearchStateInitialized = SearchState &
  Required<Pick<SearchState, 'isInnerUpdate' | 'query' | 'pageNumber'>>;

type SearchProviderProps = {
  searchContext?: Partial<SearchContextProps>;
};

export const SearchProvider: FC<SearchProviderProps> = ({
  children,
  searchContext,
}) => {
  const [totalRecords, setTotalRecords] = useState<number | undefined>();
  const [searchOption, setSearchOption] = useState<SEARCH_OPTION>(
    SEARCH_OPTION.keyword
  );
  const [selectedFacets, setSelectedFacets] = useState<SelectedFacetAttributes>(
    {}
  );
  const [selectedPublicationYear, setSelectedPublicationYear] =
    useState<PublicationYear>({});
  const [instanceIdScrollTo, setInstanceIdScrollTo] = useState(null);
  const [isPageFromBackButton, setIsPageFromBackButton] = useState(false);

  const initialSearchState = {
    pageNumber: 1,
    query: '',
    sortDirection: SORT_DIRECTION.asc,
    sortField: SORT_FIELD.relevance,
    option: SEARCH_OPTION.keyword,
    facets: {},
    publicationYear: {},
    isInnerUpdate: true,
  };

  const [state, updateState] = useReducer<
    Reducer<SearchStateInitialized, Partial<SearchState>>
  >(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
      pageNumber: newState.pageNumber || 1,
      isInnerUpdate: Boolean(newState.isInnerUpdate),
    }),
    initialSearchState
  );

  const [isStatePristine, setStatePristine] = useState(true);
  const { getSearchStateFromUrl } = useUrlManager();
  const { pathname, search: urlSearchQuery } = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const isMountedRef = useRef(false);
  const searchQueryRef = useRef<string>('');
  const shouldRefocusRef = useRef(false);
  const { checkIsInstanceDetailsRoute } = useInstanceDetailsRoutePath();
  const isSearchRoute = pathname === ROUTE.search;

  const [searchResults, setSearchResults] = useState<SearchInstanceDto[][]>([]);
  const fetchSearch = useSearchRequest(state);
  const searchResultsQuery = useQuery<SearchResultsDto, SearchResultsErrorsDto>(
    ['searchResults', omit(state, 'isInnerUpdate')],
    fetchSearch,
    {
      cacheTime: 0,
      enabled:
        !isStatePristine &&
        (Boolean(state.query) ||
          !isEmpty(state.facets) ||
          !isEmpty(state.publicationYear)),
    }
  );

  useEffect(() => {
    const newTotalRecords = searchResultsQuery.data?.totalRecords;

    if (isNumber(newTotalRecords)) {
      setTotalRecords(newTotalRecords);

      if (newTotalRecords === 1 && isSearchRoute) {
        navigate(
          `${generatePath(ROUTE.instanceDetails, {
            id: searchResultsQuery.data?.instances?.[0].id as string,
          })}${urlSearchQuery}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultsQuery.data?.totalRecords]);

  useEffect(() => {
    setSearchOption(state.option);
  }, [state.option]);

  useEffect(() => {
    const isQueryUpdatedFromSearchBar = state.query && !state.isInnerUpdate;
    const isSearchFromSearchResults =
      isSearchRoute &&
      (!isEmpty(state.facets) ||
        !isEmpty(state.publicationYear) ||
        state.query);

    // isMounted makes sure that useQuery doesn't fire before the actual changes to search state were made.
    if (isMountedRef.current) {
      if (
        isStatePristine &&
        (isSearchFromSearchResults || isQueryUpdatedFromSearchBar)
      ) {
        setStatePristine(false);
      }

      if (
        !isStatePristine &&
        isQueryUpdatedFromSearchBar &&
        searchQueryRef.current === state.query
      ) {
        searchResultsQuery.refetch();
      }

      searchQueryRef.current = state.query;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchRoute, isStatePristine, state]);

  useEffect(() => {
    // Reset isInnerUpdate after every search fetch to avoid re-fetching results after navigating away from search results page
    updateState({ isInnerUpdate: true, pageNumber: state.pageNumber });

    // eslint-disable-next-line
  }, [searchResultsQuery.dataUpdatedAt, searchResultsQuery.errorUpdatedAt]);

  const syncUrlSelectedFacets = updatedFacets => {
    setSelectedFacets(() =>
      FACETS_ORDER.reduce((result, facetKey) => {
        const selectedFacetsForKey = updatedFacets[facetKey]?.reduce(
          (newSelectedFacets, currentFacet) => {
            newSelectedFacets[currentFacet] = true;

            return newSelectedFacets;
          },
          {}
        );

        result[facetKey] = { ...selectedFacetsForKey };

        return result;
      }, {})
    );
  };

  const updateSearchStateFromUrl = (
    locationSearchQuery?: queryString.ParsedQuery
  ) => {
    const {
      query: updatedQuery = '',
      option: updatedOption = SEARCH_OPTION.keyword,
      advancedModal: updatedAdvancedModal = 'false',
      sortField: updatedSortField = SORT_FIELD.relevance,
      sortDirection: updatedSortDirection = SORT_DIRECTION.asc,
      facets: updatedFacets = {},
      publicationYear: updatedPublicationYear = {},
    } = getSearchStateFromUrl(locationSearchQuery);

    const newState = {
      query: updatedQuery,
      advancedModal: updatedAdvancedModal,
      facets: updatedFacets,
      isInnerUpdate: true,
      publicationYear: updatedPublicationYear,
    };

    syncUrlSelectedFacets(updatedFacets);
    setSearchOption(updatedOption);
    setSelectedPublicationYear(updatedPublicationYear);

    if (
      updatedQuery ||
      !isEmpty(updatedFacets) ||
      !isEmpty(updatedPublicationYear) ||
      updatedAdvancedModal
    ) {
      Object.assign(newState, {
        option: updatedOption,
        sortField: updatedSortField,
        sortDirection: updatedSortDirection,
      });
    } else {
      // update the url to remove parameters: option, sortField and sortDirection after page reload
      // as search request depends only on query and facets parameters in search state
      if (isSearchRoute) {
        navigate(
          generateRouteParams({
            pathname: ROUTE.search,
            query: {},
          })
        );
      }
    }

    updateState(newState);
    searchQueryRef.current = updatedQuery;
  };

  useEffect(() => {
    isMountedRef.current = true;

    updateSearchStateFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (pathname === ROUTE.search || checkIsInstanceDetailsRoute(pathname)) &&
      state.isInnerUpdate
    ) {
      if (urlSearchQuery && urlSearchQuery !== '?') {
        updateSearchStateFromUrl(queryString.parse(urlSearchQuery));
      } else {
        syncUrlSelectedFacets({});
        setSelectedPublicationYear({});
        setSearchOption(SEARCH_OPTION.keyword);
        updateState({ ...initialSearchState, isInnerUpdate: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchQuery]);

  usePaging<SearchInstanceDto>({
    areRecordsFetched: searchResultsQuery.isSuccess,
    records: searchResultsQuery.data?.instances,
    pageNumber: state.pageNumber,
    setPages: setSearchResults,
  });

  const updateFacets = (updatedFacets, key: 'facets' | 'publicationYear') => {
    const urlState = getSearchStateFromUrl();

    if (!isEqual(updatedFacets, state[key]) && isMountedRef.current) {
      // remove empty facets from the URL if last facet was unchecked manually
      if (isEmpty(updatedFacets) && queryParams[key]) {
        let updatedQueryParams = queryParams;

        if (!queryParams.query) {
          updatedQueryParams = { ...omit(updatedQueryParams, 'option') };
        }

        updatedQueryParams = {
          ...omit(updatedQueryParams, key),
        };

        navigate(
          generateRouteParams({
            pathname: ROUTE.search,
            query: updatedQueryParams as URLQuery,
          })
        );
      } else {
        updateState({
          [key]: updatedFacets,
          option: searchOption,
          isInnerUpdate: isEqual(urlState[key] ?? {}, updatedFacets),
        });
      }
    }
  };

  useEffect(() => {
    const formattedFacets = formatSelectedFacets(selectedFacets);

    updateFacets(formattedFacets, 'facets');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacets, state.facets, updateState]);

  useEffect(() => {
    updateFacets(selectedPublicationYear, 'publicationYear');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublicationYear, state.publicationYear, updateState]);

  return (
    <SearchContext.Provider
      value={{
        state,
        searchOption,
        searchResults: {
          searchResultsQuery,
          currentPage: searchResultsQuery.data?.instances,
          totalRecords,
          pages: searchResults,
        },
        shouldRefocusRef,
        selectedFacets,
        selectedPublicationYear,
        instanceIdScrollTo,
        isPageFromBackButton,
        updateState,
        setSelectedFacets,
        setSelectedPublicationYear,
        setSearchOption,
        setInstanceIdScrollTo,
        setIsPageFromBackButton,
        ...searchContext,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
