import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty, compact } from 'lodash';

import { Button, DateFilters } from '@ebsco-ui/ebsco-ui';

import { FACET } from '@app/search';
import { useSearchContext } from '@app/contexts';
import { sharedMessages } from '@app/translations';

import {
  Facet,
  validateDateRange,
  DATE_FORMAT,
  initialDateRange,
  QUICK_FILTER_TYPE,
  PUBLICATION_YEAR_OPTION,
  dateFiltersMessages,
} from '..';

interface PublicationYearFacetProps {
  dateFacetCount: number;
  setDateFacetCount: (count: number) => void;
}

export const PublicationYearFacet: FC<PublicationYearFacetProps> = ({
  dateFacetCount,
  setDateFacetCount,
}) => {
  const { $t } = useIntl();
  const [selectedFilterType, setSelectedFilterType] = useState<
    keyof typeof QUICK_FILTER_TYPE
  >(QUICK_FILTER_TYPE.allTime);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isApplyButtonDisabled, setApplyButtonDisabled] = useState(false);
  const { selectedPublicationYear, setSelectedPublicationYear } =
    useSearchContext();
  const quickFilters = useMemo(
    () =>
      Object.values(QUICK_FILTER_TYPE).map(filterType => ({
        label: $t(dateFiltersMessages[filterType]),
        value: filterType,
      })),
    [$t]
  );

  const dateFiltersLabel = $t({
    id: 'facets.publicationYear',
    defaultMessage: 'Publication year',
  });

  const dateFiltersI18n = {
    groupTitle: dateFiltersLabel,
    minDateLabel: $t(sharedMessages.from),
    maxDateLabel: $t(sharedMessages.to),
  };

  const formatDateRange = useCallback(
    customDateRange => {
      const { minDateErrorMessage, maxDateErrorMessage, isSharedError } =
        validateDateRange(customDateRange);

      return {
        minDate: {
          value: customDateRange.minDate,
          error: minDateErrorMessage ? $t(minDateErrorMessage) : '',
        },
        maxDate: {
          value: customDateRange.maxDate,
          error: maxDateErrorMessage ? $t(maxDateErrorMessage) : '',
        },
        isSharedError,
      };
    },
    [$t]
  );

  useEffect(() => {
    const currentSelectedFilterType = Object.keys(PUBLICATION_YEAR_OPTION).find(
      key => PUBLICATION_YEAR_OPTION[key] === selectedPublicationYear.minDate
    ) as keyof typeof PUBLICATION_YEAR_OPTION;

    if (selectedPublicationYear?.maxDate) {
      setSelectedFilterType(QUICK_FILTER_TYPE.custom);
      setDateRange(formatDateRange(selectedPublicationYear));
    } else if (selectedPublicationYear?.minDate) {
      setSelectedFilterType(currentSelectedFilterType);
    }

    if (!isEmpty(selectedPublicationYear)) {
      setDateFacetCount(1);
    } else {
      setDateFacetCount(0);
      setSelectedFilterType(QUICK_FILTER_TYPE.allTime);
    }
  }, [
    formatDateRange,
    selectedPublicationYear,
    setSelectedPublicationYear,
    setDateFacetCount,
  ]);

  const handleFilterTypeChange = (
    filterType: keyof typeof QUICK_FILTER_TYPE
  ): void => {
    const dateFiltersCount = filterType === QUICK_FILTER_TYPE.allTime ? 0 : 1;
    const dateFilterValue = PUBLICATION_YEAR_OPTION[filterType];

    if (filterType !== QUICK_FILTER_TYPE.custom) {
      if (dateFilterValue) {
        setSelectedPublicationYear({ minDate: dateFilterValue });
      } else {
        setSelectedPublicationYear({});
      }
    }

    setDateFacetCount(dateFiltersCount);
    setSelectedFilterType(filterType);
    setDateRange(initialDateRange);
    setApplyButtonDisabled(false);
  };

  const handleDateRangeChange = (
    customDateRange: Record<string, string>
  ): void => {
    const { minDateErrorMessage, maxDateErrorMessage } =
      validateDateRange(customDateRange);

    const validatedDateRange = formatDateRange(customDateRange);
    const hasDateError = Boolean(minDateErrorMessage || maxDateErrorMessage);
    const dateFiltersCount = hasDateError ? 0 : 1;

    setDateFacetCount(dateFiltersCount);
    setDateRange(validatedDateRange);
    setApplyButtonDisabled(hasDateError);
  };

  const handleDateFiltersChange = ({ selectedFilter, customRange }) => {
    const hasFilterTypeChanged = selectedFilter !== selectedFilterType;

    if (hasFilterTypeChanged) {
      handleFilterTypeChange(selectedFilter);
    } else if (selectedFilter === QUICK_FILTER_TYPE.custom) {
      handleDateRangeChange(customRange);
    }
  };

  const handleApplyButtonClick = () => {
    setSelectedPublicationYear({
      minDate: dateRange.minDate.value,
      maxDate: dateRange.maxDate.value,
    });
  };

  const applyButton =
    selectedFilterType === QUICK_FILTER_TYPE.custom ? (
      <Button
        key="applyButton"
        disabled={isApplyButtonDisabled}
        onClick={handleApplyButtonClick}
      >
        {$t(sharedMessages.apply)}
      </Button>
    ) : null;

  return (
    <Facet
      label={dateFiltersLabel}
      facetKey={FACET.PUBLICATION_YEAR}
      selectedCount={dateFacetCount}
      isInitiallyExpanded={dateFacetCount > 0}
      facetAttributes={compact([
        <DateFilters
          className="publication-filters"
          key={FACET.PUBLICATION_YEAR}
          i18n={dateFiltersI18n}
          dateRange={dateRange}
          isSharedError={dateRange.isSharedError}
          quickFilters={quickFilters}
          selectedFilter={selectedFilterType}
          showDateRange={selectedFilterType === QUICK_FILTER_TYPE.custom}
          placeholder={DATE_FORMAT.YYYY}
          onChange={handleDateFiltersChange}
        />,
        applyButton,
      ])}
    />
  );
};
