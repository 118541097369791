import { FC, Children, ReactElement } from 'react';

import { ShowMore } from '@ebsco-ui/ebsco-ui';

import { ShowMoreButton } from '@app/components';

import { FACET_SHOW_MORE_LIMIT } from '..';

import css from '../Facet/Facet.module.scss';

interface FacetShowMoreProps {
  children: ReactElement[];
  limit?: number;
}

export const FacetShowMore: FC<FacetShowMoreProps> = ({
  children,
  limit = FACET_SHOW_MORE_LIMIT,
}) => (
  <fieldset>
    <ShowMore limit={limit}>
      <ShowMore.Items>{children}</ShowMore.Items>
      <div key="toggle-row" className="facet-attribute">
        <ShowMoreButton
          showMoreLimit={limit}
          dataLength={Children.count(children)}
          className={css.showMoreButton}
        />
      </div>
    </ShowMore>
  </fieldset>
);
