import { Fragment, ReactNode, useRef, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import {
  ShowMorePagination,
  Loading,
  PageErrorMessage,
  Button,
} from '@ebsco-ui/ebsco-ui';

import { SearchBar, BrowseBy } from '@app/components';
import { Resource } from '@app/pages/constants';
import { useQueryParams, usePaginationItemFocus } from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { sharedMessages, getPageMessages } from '@app/translations';
import { FOCUSED_ELEMENT_SELECTOR, SetState } from '@app/constants';

import { RECORDS_PER_PAGE } from './constants';

import css from './Browse.module.scss';

interface TableClasses {
  row?: string;
  cell?: string;
}

interface Messages {
  documentTitle: MessageDescriptor;
  pageTitle: MessageDescriptor;
  searchBarPlaceholder: MessageDescriptor;
  showMoreButtonTitle: MessageDescriptor;
  paginationTitle?: MessageDescriptor;
}

interface BrowseProps<PageType, QueryResultType> {
  messages: Messages;
  resource: Resource<PageType, QueryResultType>;
  columns: {
    label: string;
    formatter: (records: PageType) => ReactNode;
  }[];
  pageNumber: number;
  tableClasses?: TableClasses;
  isDataLoadedOnInitialRender?: boolean;
  shouldRenderBrowseBy?: boolean;
  setPageNumber: SetState<number>;
  loadMore: () => void;
  renderEmptyState: () => ReactElement;
  checkShouldRenderSpecialRow?: (...args: unknown[]) => boolean;
  renderSpecialRow?: ({ record, classes }) => ReactElement;
  renderBrowseTitle?: () => ReactElement;
}

export const Browse = <PageType, QueryResultType>({
  messages: {
    documentTitle,
    pageTitle,
    searchBarPlaceholder,
    showMoreButtonTitle,
  },
  resource: {
    queryResult: { isFetching, isError, refetch, isFetched },
    totalRecords,
    pages,
  },
  columns,
  pageNumber,
  tableClasses,
  isDataLoadedOnInitialRender = true,
  shouldRenderBrowseBy = false,
  setPageNumber,
  loadMore,
  renderEmptyState,
  checkShouldRenderSpecialRow = () => false,
  renderSpecialRow,
  renderBrowseTitle,
}: BrowseProps<PageType, QueryResultType>): ReactElement => {
  const intl = useIntl();
  const { $t } = intl;
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevSearchTermRef = useRef(queryParams.search as string);
  const { shouldBeFocused } = usePaginationItemFocus({
    pageNumber,
    totalPagesNumber: pages.length,
    selector: FOCUSED_ELEMENT_SELECTOR,
  });

  const uniquePaginationName = pageTitle.id?.replace('.title', '') as string;

  const classes = {
    headerRow: cn(
      css.headerRow,
      tableClasses?.row && {
        [tableClasses.row]: Boolean(tableClasses),
      }
    ),
    row: cn(
      css.row,
      tableClasses?.row && {
        [tableClasses.row]: Boolean(tableClasses),
      }
    ),
    cell: cn(
      css.cell,
      tableClasses?.cell && {
        [tableClasses.cell]: Boolean(tableClasses),
      }
    ),
  };

  const renderLoading = () => (
    <div data-testid="browse-spinner" className={css.pageSpinner}>
      <Loading
        direction="vertical"
        size="large"
        loadingMessage={$t(sharedMessages.loadingMessage)}
      />
    </div>
  );

  const handleSearch = (term: string): void => {
    if (
      term !== prevSearchTermRef.current ||
      term !== (queryParams.search as string)
    ) {
      prevSearchTermRef.current = term;
      setPageNumber(1);
      navigate(
        generateRouteParams({
          pathname: location.pathname,
          ...(term && {
            query: {
              ...queryParams,
              search: term,
            },
          }),
        })
      );
    }
  };

  const renderPageContent = () => {
    if (pageNumber === 1 && isFetching) {
      return renderLoading();
    }

    if (isError) {
      return (
        <PageErrorMessage
          className={css.pageErrorMessage}
          buttonText={$t(sharedMessages.refreshPage)}
          title={$t(sharedMessages.pageUnavailable)}
          text={$t(sharedMessages.searchUnavailableText)}
          titleTag="h1"
          onClick={() => refetch()}
        />
      );
    }

    if (!isDataLoadedOnInitialRender && !queryParams.search) {
      return renderEmptyState();
    }

    if (isFetched && totalRecords === 0) {
      return renderEmptyState();
    }

    return (
      !isEmpty(pages[0]) && (
        <>
          {renderBrowseTitle?.()}
          <div
            className={css.list}
            role="grid"
            aria-label={$t(pageTitle)}
            aria-colcount={columns.length}
            tabIndex={0}
          >
            <div className={css.body}>
              <ShowMorePagination
                pagesContainerTag="div"
                uniqueName={uniquePaginationName}
                className={css.scrollContainer}
                error={undefined}
                messages={getPageMessages(intl, {
                  showMoreButtonTitle: $t(showMoreButtonTitle),
                })}
                isLoading={isFetching}
                totalRecords={totalRecords}
                recordsPerPage={RECORDS_PER_PAGE}
                pages={pages}
                onLoadPage={loadMore}
              >
                {(records, currentPageNumber) => (
                  <Fragment key={currentPageNumber}>
                    {currentPageNumber === 0 && (
                      <div role="rowgroup">
                        <div className={classes.headerRow} role="row">
                          {columns.map(
                            column =>
                              column && (
                                <div
                                  className={classes.cell}
                                  role="columnheader"
                                  key={column.label}
                                >
                                  {column.label}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    )}
                    {records.map((record, index) => (
                      <div
                        role="rowgroup"
                        key={record.id}
                        {...(shouldBeFocused &&
                          !index && {
                            tabIndex: -1,
                            'data-focus': 'focusedNode',
                          })}
                      >
                        <div
                          className={cn(classes.row, {
                            [css.highlightedRow]: record.isAnchor,
                          })}
                          role="row"
                          data-testid="row"
                          aria-rowindex={
                            currentPageNumber * RECORDS_PER_PAGE + index + 1
                          }
                        >
                          {renderSpecialRow &&
                          checkShouldRenderSpecialRow(record)
                            ? renderSpecialRow({
                                record,
                                classes,
                              })
                            : columns.map(
                                (column, columnIndex) =>
                                  column && (
                                    <div
                                      role="cell"
                                      className={classes.cell}
                                      key={columnIndex}
                                    >
                                      {column?.formatter(record)}
                                    </div>
                                  )
                              )}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                )}
              </ShowMorePagination>
            </div>
          </div>
        </>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{$t(documentTitle)}</title>
      </Helmet>
      <div
        className={cn(css.container, 'container')}
        data-testid="browse-container"
      >
        <h1 className={css.pageTitle}>{$t(pageTitle)}</h1>
        <div>
          <section
            className={css.searchBarContainer}
            aria-label={$t(pageTitle)}
          >
            <SearchBar
              placeholder={$t(searchBarPlaceholder)}
              value={queryParams.search as string}
              onSearch={handleSearch}
            />
            {queryParams.search && (
              <div className={css.buttonsWrapper}>
                <Button styleType="pill" onClick={() => handleSearch('')}>
                  {$t(sharedMessages.clearResults)}
                </Button>
              </div>
            )}
          </section>
          {shouldRenderBrowseBy && (
            <>
              <BrowseBy fieldName="departments" />
              <BrowseBy fieldName="instructors" />
            </>
          )}
          {renderPageContent()}
        </div>
      </div>
    </>
  );
};
