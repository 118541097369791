import { FC } from 'react';
import { useIntl } from 'react-intl';

import { SearchAutocomplete } from '@ebsco-ui/ebsco-ui';

import { SEARCH_OPTION } from '@app/search';
import { useSearchContext } from '@app/contexts';
import { useAutoComplete } from '@app/hooks';
import { sharedMessages } from '@app/translations';

import { AutoCompleteGroups } from '.';

import css from './AutoComplete.module.scss';

interface AutoCompleteProps {
  placeholder?: string;
  value?: string;
}

export const AutoComplete: FC<AutoCompleteProps> = ({
  value = '',
  placeholder = '',
}) => {
  const { updateState, searchOption, shouldRefocusRef } = useSearchContext();

  const onSelectItem = ({ selectedValue, selectedOption }) => {
    updateState({
      query: selectedValue,
      option: selectedOption,
    });

    shouldRefocusRef.current = true;
  };

  const {
    inputValue,
    suggestions,
    handleInputValueChange,
    handleSelectItem,
    handleSearchButtonClick,
  } = useAutoComplete({ value, searchOption, onSelectItem });
  const { $t } = useIntl();

  const isKeywordSearch = searchOption === SEARCH_OPTION.keyword;

  return (
    <form
      className={css.wrapper}
      data-testid="search-bar"
      onSubmit={handleSearchButtonClick}
    >
      <SearchAutocomplete
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        items={suggestions}
        {...(isKeywordSearch && {
          groupItemsBy: item => item.category,
          groups: AutoCompleteGroups,
        })}
        value={inputValue}
        className={css.searchInput}
        messages={{
          autocompleteMessages: {
            input: {
              'aria-label': placeholder,
              placeholder,
            },
            clearButton: { 'aria-label': $t(sharedMessages.clear) },
            menu: { 'aria-label': placeholder },
          },
        }}
        searchButtonProps={{
          messages: {
            'aria-label': $t(sharedMessages.search),
            title: $t(sharedMessages.search),
          },
        }}
        downshiftOptions={{
          menuId: 'autoCompleteMenu',
          onSelectedItemChange: handleSelectItem,
        }}
        hasSearchButton
        shouldHighlightItems
        displayHighlightedValue
        onChange={handleInputValueChange}
      />
    </form>
  );
};
