import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ConditionalRoute, NavigationLayout } from '@app/components';
import {
  MyDashboardPage,
  BookshelfPage,
  InstanceDetailsPage,
  SearchResultsPage,
  CourseReservesPage,
  CourseReserveDetailsPage,
  CallNumbersPage,
  NotFoundPage,
  PreferencesPage,
  StaffViewPage,
} from '@app/pages';
import { ROUTE, FEATURE } from '@app/constants';

export const getPathWithDescendantRoutes = (route: ROUTE): string =>
  `${route}/*`;

export const MainPage: FC = () => {
  return (
    <Routes>
      <Route element={<NavigationLayout />}>
        <Route
          path={getPathWithDescendantRoutes(ROUTE.bookshelf)}
          element={
            <ConditionalRoute flags={[FEATURE.bookshelf]}>
              <BookshelfPage />
            </ConditionalRoute>
          }
        />
        <Route path={ROUTE.instanceDetails} element={<InstanceDetailsPage />} />
        <Route
          path={ROUTE.staffView}
          element={
            <ConditionalRoute flags={[FEATURE.staffView]}>
              <StaffViewPage />
            </ConditionalRoute>
          }
        />
        <Route path={ROUTE.search} element={<SearchResultsPage />} />
        <Route
          path={ROUTE.courseReserveDetails}
          element={
            <ConditionalRoute flags={[FEATURE.courseReserves]}>
              <CourseReserveDetailsPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={ROUTE.courseReserves}
          element={
            <ConditionalRoute flags={[FEATURE.courseReserves]}>
              <CourseReservesPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={ROUTE.callNumbers}
          element={
            <ConditionalRoute flags={[FEATURE.callNumbers]}>
              <CallNumbersPage />
            </ConditionalRoute>
          }
        />
        <Route
          path={ROUTE.preferences}
          element={
            <ConditionalRoute flags={[FEATURE.consortiaLoginFlow]}>
              <PreferencesPage />
            </ConditionalRoute>
          }
        />
        <Route path={ROUTE.myDashboard} element={<MyDashboardPage />} />
        <Route path={ROUTE.notFound} element={<NotFoundPage />} />
        <Route
          path={ROUTE.root}
          element={<Navigate replace to={ROUTE.search} />}
        />
        <Route path="*" element={<Navigate replace to={ROUTE.notFound} />} />
      </Route>
    </Routes>
  );
};
