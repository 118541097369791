import { isEmpty } from 'lodash';

import { facetToOperatorMap } from '@app/search';

export const getFacetsCQL = (
  facets: Record<string, string[]> | undefined
): string => {
  return facets && !isEmpty(facets)
    ? Object.keys(facets)
        .filter(facetKey => !isEmpty(facets[facetKey]))
        .map(
          facetKey =>
            `${facetKey}${facetToOperatorMap[facetKey]}(${facets[facetKey]
              .map(facetValue =>
                '"'.concat(facetValue.replace(/"/g, '\\"')).concat('"')
              )
              .join(' or ')})`
        )
        .join(' and ')
    : '';
};
