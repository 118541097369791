import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import { compact } from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBooks } from '@fortawesome/pro-duotone-svg-icons';
import { faBooks as faBooksSolid } from '@fortawesome/pro-solid-svg-icons';

import { Browse, BrowseContributors } from '@app/components';
import { useCallNumbersFetch, useQueryParams } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { ROUTE } from '@app/constants';

import { callNumbersMessages } from './callNumbersMessages';
import { CallNumbersItemDto } from './callNumbersTypes';

import css from './CallNumbersPage.module.scss';

export const CallNumbersPage: FC = () => {
  const { $t } = useIntl();
  const queryParams = useQueryParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [callNumbersShelfKey, setCallNumbersShelfKey] = useState('');

  const callNumbersResource = useCallNumbersFetch(
    pageNumber,
    callNumbersShelfKey
  );

  useEffect(() => {
    setCallNumbersShelfKey((queryParams.search as string) || '');
    setPageNumber(1);
  }, [queryParams.search]);

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
    setCallNumbersShelfKey(callNumbersResource.next || '');
  };

  const {
    documentTitle,
    callNumber,
    year,
    authors,
    pageTitle,
    emptyStateTitle,
    searchBarPlaceholder,
    showMoreButtonTitle,
    yourCallNumber,
    yourCallNumberWouldBeHere,
  } = callNumbersMessages;

  const getHighlightedCallNumber = useCallback(
    fullCallNumber => {
      return (
        <div className={css.highlightedCallNumberWrapper}>
          <FontAwesomeIcon
            icon={faBooksSolid}
            className={css.highlightedCallNumberIcon}
          />
          {fullCallNumber
            ? $t(yourCallNumber, {
                callNumber: fullCallNumber,
                b: chunks => (
                  <mark className={css.highlightedCallNumber}>{chunks}</mark>
                ),
              })
            : $t(yourCallNumberWouldBeHere, {
                callNumber: queryParams.search,
              })}
        </div>
      );
    },
    [$t, yourCallNumber, yourCallNumberWouldBeHere, queryParams.search]
  );

  const columns = useMemo(
    () => [
      {
        label: $t(callNumber),
        formatter: ({ fullCallNumber, isAnchor }: CallNumbersItemDto) =>
          isAnchor ? getHighlightedCallNumber(fullCallNumber) : fullCallNumber,
      },
      {
        label: $t(sharedMessages.title),
        formatter: ({ instance }: CallNumbersItemDto) =>
          instance && (
            <div>
              <Link
                data-testid="instance-details-link"
                className="underlinedLink"
                to={{
                  pathname: generatePath(ROUTE.instanceDetails, {
                    id: instance.id,
                  }),
                }}
              >
                {instance.title}
              </Link>
            </div>
          ),
      },
      {
        label: $t(year),
        formatter: ({ instance }: CallNumbersItemDto) =>
          instance?.publication?.[0].dateOfPublication,
      },
      {
        label: $t(authors),
        formatter: ({ instance }: CallNumbersItemDto) => (
          <BrowseContributors contributors={instance?.contributors} />
        ),
      },
    ],
    [authors, callNumber, $t, getHighlightedCallNumber, year]
  );

  const renderEmptyState = () => (
    <div className={css.emptyStateMessage} data-testid="empty-state">
      <FontAwesomeIcon icon={faBooks} className={css.emptyStateIcon} />
      <FormattedMessage {...emptyStateTitle} />
    </div>
  );

  const checkShouldRenderEmptyHighlightedRow = record =>
    record.isAnchor && !record.fullCallNumber;

  const renderEmptyHighlightedRow = ({ record, classes: browseClasses }) => (
    <div
      role="cell"
      className={cn(browseClasses.cell, css.emptyHighlightedCell)}
    >
      {columns[0].formatter(record)}
    </div>
  );

  return (
    <Browse
      messages={{
        documentTitle,
        pageTitle,
        searchBarPlaceholder,
        showMoreButtonTitle,
      }}
      resource={callNumbersResource}
      columns={compact(columns)}
      pageNumber={pageNumber}
      tableClasses={{ cell: css.tableCell }}
      isDataLoadedOnInitialRender={false}
      loadMore={loadMore}
      setPageNumber={setPageNumber}
      renderEmptyState={renderEmptyState}
      checkShouldRenderSpecialRow={checkShouldRenderEmptyHighlightedRow}
      renderSpecialRow={renderEmptyHighlightedRow}
    />
  );
};
