import { FC, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { isEmpty, filter, keyBy } from 'lodash';

import {
  ConfigurationsValues,
  LocationsDto,
  MappingsContext,
  NoteTypesDto,
  NoteTypesTransformedDto,
  PickupLocationsDto,
  useAppContext,
} from '@app/contexts';
import { useFeatureFlag } from '@app/hooks';
import {
  axios,
  getOpacConfigurationsQuery,
  OpacConfig,
  parseJSON,
} from '@app/utils';
import { FEATURE, LOGIN_METHODS } from '@app/constants';

const LIMIT = 1000;

const REQUIRED_CONFIGS: OpacConfig[] = [
  {
    module: 'OPAC_TOOLS',
    configName: 'ENABLE_STACKMAP',
    code: 'ENABLE_STACKMAP',
    getConfigValue: config => ({
      isStackMapEnabled: config?.value === 'true',
    }),
  },
  {
    module: 'OPAC_CONFIGURATION',
    configName: 'opac_auth_option',
    code: 'OPAC_AUTH_OPTION',
    getConfigValue: config => {
      const loginMethod = config?.value;

      return {
        isSSOLogin: loginMethod === LOGIN_METHODS.SSO,
        isNativeLogin: loginMethod === LOGIN_METHODS.NATIVE,
      };
    },
  },
  {
    module: 'OPAC_TOOLS',
    configName: 'library_location_map',
    code: 'LIBRARY_LOCATION_MAP',
    getConfigValue: config => ({
      libraryLocationMap: parseJSON(config?.value),
    }),
  },
];

const getResource = (path: string, params = {}, options = {}) =>
  axios.get(path, { params, ...options }).then(response => response.data);

export const MappingsProvider: FC = ({ children }) => {
  const { patronSession } = useAppContext();
  const externalSystemId = patronSession?.user?.externalSystemId;
  const bookshelfFlag = useFeatureFlag(FEATURE.bookshelf);
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);

  const createQueryKey = useCallback(
    key => [key, externalSystemId],
    [externalSystemId]
  );

  const locationsQuery = useQuery<LocationsDto>(
    createQueryKey('locations'),
    () => getResource('/opac-inventory/locations', { limit: LIMIT })
  );

  const notesTypesQuery = useQuery<NoteTypesDto, null, NoteTypesTransformedDto>(
    createQueryKey('noteTypes'),
    () => getResource('/opac-inventory/note-types-mappings'),
    {
      select: data =>
        keyBy(filter(data.noteTypesForDisplay, { active: true }), 'labelKey'),
    }
  );

  const pickupLocationsQuery = useQuery<PickupLocationsDto>(
    createQueryKey('pickupLocations'),
    () =>
      getResource(
        '/opac-inventory/pickup-locations',
        {
          ...queryString.parse(
            'query=cql.allrecords=1 sortBy discoveryDisplayName'
          ),
          limit: LIMIT,
        },
        { withCredentials: consortiaLoginFlowFlag?.isActive }
      )
  );

  const userItemStatusesQuery = useQuery(
    createQueryKey('userItemStatuses'),
    () =>
      getResource(`/opac-patron/account/${externalSystemId}`, {
        includeHolds: true,
        includeCharges: true,
        includeLoans: true,
      }),
    { enabled: !isEmpty(externalSystemId) && bookshelfFlag?.isActive }
  );

  const configurationsQuery = useQuery(createQueryKey('configurations'), () =>
    getResource('/opac-configurations/entries', {
      query: getOpacConfigurationsQuery(REQUIRED_CONFIGS),
    }).then(({ configs }) =>
      REQUIRED_CONFIGS.reduce((configsValues, { code, getConfigValue }) => {
        const currentConfig = configs?.find(config => config.code === code);

        return Object.assign(configsValues, getConfigValue(currentConfig));
      }, {})
    )
  );

  const value = useMemo(
    () => ({
      locations: locationsQuery.data?.locations,
      pickupLocations: pickupLocationsQuery.data?.servicepoints,
      userItemStatuses: userItemStatusesQuery.data,
      noteTypes: notesTypesQuery.data,
      areUserItemStatusesLoading: userItemStatusesQuery.isFetching,
      configurations: {
        ...(configurationsQuery.data as ConfigurationsValues),
        configurationsQuery,
      },
    }),
    [
      locationsQuery.data,
      pickupLocationsQuery.data,
      notesTypesQuery.data,
      userItemStatusesQuery.data,
      userItemStatusesQuery.isFetching,
      configurationsQuery,
    ]
  );

  return (
    <MappingsContext.Provider value={value}>
      {children}
    </MappingsContext.Provider>
  );
};
