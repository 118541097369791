import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';

import { LocaleDropdown } from '@app/components';
import { sharedMessages } from '@app/translations';

import css from './PreferencesPage.module.scss';

export const PreferencesPage: FC = () => {
  const { $t } = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'preferences.documentTitle',
            defaultMessage: 'Preferences - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <div
        className={cn(css.container, 'container')}
        data-testid="preferences-container"
      >
        <h1 className={css.title}>{$t(sharedMessages.preferences)}</h1>
        <LocaleDropdown />
      </div>
    </>
  );
};
