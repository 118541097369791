import { FC, useRef, useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import MultiCarousel, { CarouselInternalState } from 'react-multi-carousel';
import { noop } from 'lodash';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { VirtualShelfItem } from '@app/hooks';

import { CarouselItem, CarouselButtons } from '.';

import 'react-multi-carousel/lib/styles.css';
import './Carousel.scss';

interface CarouselProps {
  items: VirtualShelfItem[];
}

type CarouselButton = typeof noop;

const cnBem = createBemBlockBuilder(['carousel']);

export const Carousel: FC<CarouselProps> = ({ items }) => {
  const { $t } = useIntl();
  const carouselRef = useRef<MultiCarousel>(null!);
  const [carouselState, setCarouselState] = useState<CarouselInternalState>(
    null!
  );
  const [currentDeviceType, setCurrentDeviceType] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const updateDeviceType = () => {
      setCurrentDeviceType(carouselRef.current.state.deviceType ?? '');
    };

    window.addEventListener('resize', updateDeviceType);

    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  useEffect(() => {
    setCarouselState(carouselRef.current.state);
  }, [carouselRef.current?.state, currentDeviceType]);

  useEffect(() => {
    setCarouselState(prevState => ({
      ...prevState,
      currentSlide,
    }));
  }, [currentSlide]);

  const getIsSlideVisible = useCallback(
    index => {
      return carouselState
        ? index >= carouselState.currentSlide &&
            index < carouselState.currentSlide + carouselState.slidesToShow
        : false;
    },
    [carouselState]
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1023 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1023, min: 767 },
      items: 4,
    },
    phone: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };

  const lastVisibleSlideIndex =
    carouselState?.totalItems - carouselState?.slidesToShow || 0;

  return (
    <div
      className={cnBem('__wrapper')}
      role="region"
      aria-label={$t(
        {
          id: 'carousel.description',
          defaultMessage:
            '{visibleSlides, plural, one {Related items carousel. # visible slide of {totalItems} total} other {Related items carousel. # visible slides of {totalItems} total}}',
        },
        {
          visibleSlides: carouselState?.slidesToShow,
          totalItems: carouselState?.totalItems,
        }
      )}
      data-testid="related-items-carousel"
    >
      <MultiCarousel
        keyBoardControl
        renderDotsOutside={false}
        className={cnBem()}
        responsive={responsive}
        draggable={false}
        arrows={false}
        shouldResetAutoplay={false}
        ref={carouselRef}
      >
        {items.map(({ id, title, contributors, sourceTypes, isbns }, index) => {
          return (
            <CarouselItem
              key={id}
              id={id}
              index={index + 1}
              totalItems={carouselState?.totalItems}
              title={title}
              contributors={contributors}
              sourceTypes={sourceTypes}
              isVisible={getIsSlideVisible(index)}
              isbns={isbns}
            />
          );
        })}
      </MultiCarousel>
      <CarouselButtons
        currentSlideIndex={carouselState?.currentSlide}
        lastVisibleSlideIndex={lastVisibleSlideIndex}
        setCurrentSlide={setCurrentSlide}
        onPreviousButtonClick={carouselRef.current?.previous as CarouselButton}
        onNextButtonClick={carouselRef.current?.next as CarouselButton}
      />
    </div>
  );
};
