import { createElement, FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { createBemBlockBuilder, ShowMore, Truncate } from '@ebsco-ui/ebsco-ui';

import {
  CoverImageContainer,
  PlaceHoldButton,
  RecordsTools,
  RecordSummaryAvailability,
  useRecordSummaryContext,
  ParentField,
  ShowMoreButton,
} from '@app/components';
import type { SummaryPlacement } from '@app/components';
import { SEARCH_OPTION } from '@app/search';
import { useSearchContext } from '@app/contexts';
import {
  useCoverArtFetch,
  useFeatureFlag,
  useInstanceDetailsRoutePath,
  useQueryParams,
} from '@app/hooks';
import { formatArrayToString, generateRouteParams } from '@app/utils';
import { DefaultCoverImageTypes, FEATURE, ROUTE } from '@app/constants';

import '../RecordSummary/RecordSummary.scss';

interface RecordSummaryCardProps {
  isModal?: boolean;
  isRTACShown?: boolean;
}

type PlacementBemBlocks = Record<
  SummaryPlacement,
  ReturnType<typeof createBemBlockBuilder>
>;

const cnBemRecordSummary = createBemBlockBuilder(['recordSummary']);
const cnBemModal = createBemBlockBuilder(['recordSummaryModal']);
const cnBemResults = createBemBlockBuilder(['recordSummaryResults']);
const cnBemDetails = createBemBlockBuilder(['recordSummaryInstanceDetails']);
const cnBemBookshelf = createBemBlockBuilder(['recordSummaryBookshelf']);

const placementBemBlocks: PlacementBemBlocks = {
  results: cnBemResults,
  instanceDetails: cnBemDetails,
  bookshelf: cnBemBookshelf,
};

export const RecordSummaryCard: FC<RecordSummaryCardProps> = ({
  isModal = false,
  isRTACShown = true,
}) => {
  const {
    instance,
    itemStatusData,
    placement,
    testId,
    isInstanceDetails,
    isMobileBookshelf,
    isBookshelf,
    isMobileInstanceDetails,
    getItemStatusPanel,
  } = useRecordSummaryContext();
  const queryParams = useQueryParams();
  const { data: coverArtSrc, isInitialLoading: isCoverArtLoading } =
    useCoverArtFetch(instance.isbns);
  const { getInstanceDetailsRoutePath } = useInstanceDetailsRoutePath();
  const saveInstancesFlag = useFeatureFlag(FEATURE.saveInstances);
  const { setInstanceIdScrollTo } = useSearchContext();

  const cnBemModalOrPlacement = useMemo(
    () => (isModal ? cnBemModal : placementBemBlocks[placement]),
    [isModal, placement]
  );

  const cnBemWithCommon = (className: string) =>
    cn(cnBemRecordSummary(className), cnBemModalOrPlacement(className));

  const isNonCatalogItem = Boolean(itemStatusData?.isNonCatalogItem);
  const isVirtualItem = isBookshelf && Boolean(itemStatusData?.isVirtualItem);
  const sourceTypes = formatArrayToString(instance?.sourceTypes);
  const publicationDate = instance.publication?.[0]?.dateOfPublication && (
    <div
      className={cn(
        cnBemWithCommon('__publication'),
        cnBemWithCommon('__publicationYear')
      )}
      data-testid="publication-year"
    >
      <FormattedMessage
        id="resultsList.date"
        defaultMessage="Year: {date}"
        values={{
          date: <span>{instance.publication[0].dateOfPublication}</span>,
        }}
      />
    </div>
  );

  const formatContributors = () => {
    const { contributors = [] } = instance;
    const maxAmountToDisplay = 3;
    const contributorLinks = contributors.map(({ name }) => (
      <li key={name}>
        <Link
          to={generateRouteParams({
            pathname: ROUTE.search,
            query: {
              ...queryParams,
              option: SEARCH_OPTION.author,
              query: name,
            },
          })}
          key={name}
          className={cn('underlinedLink', cnBemModalOrPlacement('__link'))}
        >
          {name}
        </Link>
      </li>
    ));

    return (
      !isEmpty(contributorLinks) && (
        <div
          className={cnBemWithCommon('__publication')}
          dir="ltr"
          data-testid="contributors"
        >
          <span className={cnBemRecordSummary('__contributorsBy')}>
            <FormattedMessage id="resultsList.by" defaultMessage="By:" />{' '}
          </span>
          {isModal || isVirtualItem ? (
            contributors.map(({ name }) => `${name};`).join(' ')
          ) : (
            <ShowMore
              limit={maxAmountToDisplay}
              className={cnBemRecordSummary('__showMore')}
            >
              <ul className={cnBemRecordSummary('__contributorsList')}>
                <ShowMore.Items>{contributorLinks}</ShowMore.Items>
              </ul>
              <ShowMoreButton
                showMoreLimit={maxAmountToDisplay}
                dataLength={contributors.length}
              />
            </ShowMore>
          )}
        </div>
      )
    );
  };

  const getCardTitle = () => {
    const cardTitle =
      !isInstanceDetails && !isNonCatalogItem && !isVirtualItem ? (
        <Link
          data-link={`${instance.id}-link`}
          data-testid={`${instance.id}-link`}
          tabIndex={0}
          to={getInstanceDetailsRoutePath(instance.id)}
          onClick={() => setInstanceIdScrollTo(instance.id)}
        >
          {instance.title}
        </Link>
      ) : (
        instance.title
      );
    const modalTitle = (
      <h3 className={cn(cnBemModal('__contentWrapper-title'))}>
        <Truncate maxLines={2} content={instance.title} />
      </h3>
    );
    const titleLevel = {
      results: 2,
      instanceDetails: 1,
      bookshelf: 2,
    };

    return isModal
      ? modalTitle
      : createElement(
          `h${titleLevel[placement]}`,
          {
            className: cn(
              cnBemRecordSummary('__metadata-title'),
              cnBemModalOrPlacement('__contentWrapper-title')
            ),
            dir: 'ltr',
            'data-testid': 'record-summary-title',
          },
          cardTitle
        );
  };

  return (
    <div
      data-testid={testId}
      className={cn(cnBemRecordSummary(), cnBemModalOrPlacement())}
    >
      <div className={cnBemWithCommon('__contentWrapper')}>
        <CoverImageContainer
          src={coverArtSrc}
          sourceTypes={
            isNonCatalogItem ? DefaultCoverImageTypes.nonCatalog : sourceTypes
          }
          isLoading={isCoverArtLoading}
          imageClassName={cnBemWithCommon('__coverImage')}
          className={cnBemWithCommon('__defaultImageWrapper')}
        />
        <div className={cnBemRecordSummary('__metadata')}>
          {sourceTypes && (
            <div
              className={cn(
                cnBemModalOrPlacement('__type'),
                cnBemWithCommon('__metadata-type')
              )}
              data-testid="source-types"
            >
              {sourceTypes}
            </div>
          )}
          {getCardTitle()}
          {formatContributors()}
          <ParentField />
          {publicationDate}
          {!isModal && isRTACShown && <RecordSummaryAvailability />}
          {isRTACShown &&
            !isModal &&
            !isMobileInstanceDetails &&
            !isBookshelf && <PlaceHoldButton />}
        </div>
      </div>
      {!isModal && saveInstancesFlag?.isActive && (
        <div className={cnBemRecordSummary('__toolsWrapper')}>
          <RecordsTools entry={instance} />
        </div>
      )}
      {isMobileInstanceDetails && !isModal && (
        <PlaceHoldButton
          className={cnBemModalOrPlacement('__buttonsWrapper')}
        />
      )}
      {isMobileBookshelf && itemStatusData && !isModal && getItemStatusPanel()}
    </div>
  );
};
