import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSessionStorage } from 'usehooks-ts';
import { invert, isEmpty } from 'lodash';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import {
  dateFiltersMessages,
  getInitialFacets,
  PUBLICATION_YEAR_OPTION,
} from '@app/components';
import { SEARCH_HISTORY_KEY, SearchState } from '@app/search';
import { sharedMessages } from '@app/translations';

import './SearchList.scss';

const cnBem = createBemBlockBuilder(['searchList']);

export const SearchList: FC = () => {
  const { $t } = useIntl();
  const facetLabels = getInitialFacets($t);
  const [searchHistory] = useSessionStorage<SearchState[]>(
    SEARCH_HISTORY_KEY,
    []
  );

  return isEmpty(searchHistory) ? (
    <div>
      {$t({
        id: 'myDashboard.empty',
        defaultMessage: 'Empty',
      })}
    </div>
  ) : (
    <ul data-testid="search-list" className={cnBem('__list')}>
      {searchHistory.map(
        (
          { query, option, sortDirection, sortField, facets, publicationYear },
          index
        ) => (
          <li key={index}>
            {query && (
              <div>
                <span>
                  {$t({
                    id: 'myDashboard.query',
                    defaultMessage: 'Query: ',
                  })}
                </span>
                {query}
              </div>
            )}
            <div>
              <span>
                {$t({
                  id: 'myDashboard.searchOption',
                  defaultMessage: 'Search option: ',
                })}
              </span>
              {option}
            </div>
            <div>
              <span>
                {$t({
                  id: 'myDashboard.sort',
                  defaultMessage: 'Sort: ',
                })}
              </span>
              {sortField} {sortDirection}
            </div>
            {!isEmpty(facets) && (
              <div>
                {Object.keys(facets || {}).map(facet => (
                  <div key={facet}>
                    <span>
                      {facetLabels[facet].label}
                      {': '}
                    </span>
                    {facets[facet]?.join(', ')}
                  </div>
                ))}
              </div>
            )}
            {publicationYear?.minDate && !publicationYear?.maxDate && (
              <div>
                {$t(
                  dateFiltersMessages[
                    invert(PUBLICATION_YEAR_OPTION)[publicationYear?.minDate]
                  ]
                )}
              </div>
            )}
            {publicationYear?.maxDate && (
              <div>
                {$t(sharedMessages.from)} {publicationYear.minDate}{' '}
                {$t(sharedMessages.to)} {publicationYear.maxDate}
              </div>
            )}
          </li>
        )
      )}
    </ul>
  );
};
