import { FC } from 'react';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import './PageBanner.scss';

const cnBem = createBemBlockBuilder(['pageBanner']);

interface PageBannerProps {
  message: string;
}

export const PageBanner: FC<PageBannerProps> = ({ message }) => (
  <div data-testid="page-banner" className={cnBem()}>
    {message}
  </div>
);
