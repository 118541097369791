import { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import css from './BrowsePaginationTitle.module.scss';

interface BrowsePaginationTitleProps {
  paginationTitle: MessageDescriptor;
  loadedRecordsAmount: number;
  totalRecords: number;
}

export const BrowsePaginationTitle: FC<BrowsePaginationTitleProps> = ({
  paginationTitle,
  loadedRecordsAmount,
  totalRecords,
}) => (
  <span className={css.paginationTitle} data-testid="pagination-title">
    <FormattedMessage
      {...paginationTitle}
      values={{
        loadedRecordsAmount,
        totalRecords,
      }}
    />
  </span>
);
