import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { Button, InPageNotification } from '@ebsco-ui/ebsco-ui';

import { Icon } from '@app/components';
import { sharedMessages } from '@app/translations';

import css from './ModalNotification.module.scss';

interface ModalNotifications<T> {
  success?: T;
  error?: T;
  information?: T;
}

interface ModalNotificationItem<T> {
  type: T;
  header: ReactNode;
  children: ReactNode;
}

export type ModalNotificationType = keyof ModalNotifications<string>;

interface ModalNotificationProps {
  type: ModalNotificationType;
  modalNotifications: ModalNotifications<
    ModalNotificationItem<ModalNotificationType>
  >;
  onCloseButtonClick?: () => void;
}

export const ModalNotification: FC<ModalNotificationProps> = ({
  type,
  modalNotifications,
  onCloseButtonClick,
}) => {
  const { $t } = useIntl();
  const modalNotificationItem = modalNotifications[type];

  return modalNotificationItem ? (
    <InPageNotification className={css.notification} variant={type}>
      <InPageNotification.Header>
        {modalNotificationItem.header}
      </InPageNotification.Header>
      <InPageNotification.Description>
        {modalNotificationItem.children}
        {onCloseButtonClick && (
          <Button
            styleType="flat-icon"
            className={css.closeButton}
            aria-label={$t(sharedMessages.close)}
            onClick={onCloseButtonClick}
          >
            <Icon icon={faTimes} />
          </Button>
        )}
      </InPageNotification.Description>
    </InPageNotification>
  ) : null;
};
