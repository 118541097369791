import { DateTime } from 'luxon';

export const getFormattedDate = ({
  date,
  locale,
  shouldDisplayTime = true,
}: {
  date: string;
  locale: string;
  shouldDisplayTime?: boolean;
}): string =>
  DateTime.fromISO(date, { setZone: true })
    .setLocale(locale)
    .toLocaleString(shouldDisplayTime ? DateTime.DATETIME_SHORT : undefined);
