import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { axios } from '@app/utils';
import { INVOKE_PATH_PREFIX, ROUTE } from '@app/constants';

import { useQueryParams } from '..';

interface SSOLoginDto {
  location: string;
  bindingMethod: 'POST' | 'GET';
  relayState: string;
  samlRequest: string;
}

interface SamlCheckDto {
  active: boolean;
}

interface SSOLoginProps {
  institutionId?: string;
}

type SSOMutation = UseMutationResult<void, unknown, SSOLoginProps>;

const processSSOLoginResponse = (response: SSOLoginDto): void => {
  if (response.bindingMethod === 'POST') {
    const form = document.getElementById('ssoForm') as HTMLFormElement;

    form.setAttribute('action', response.location);
    form.setAttribute('method', response.bindingMethod);

    const samlRequest = document.createElement('input');

    samlRequest.setAttribute('type', 'hidden');
    samlRequest.setAttribute('name', 'SAMLRequest');
    samlRequest.setAttribute('data-testId', 'samlRequestInput');
    samlRequest.setAttribute('value', response.samlRequest);
    form.appendChild(samlRequest);

    const relayState = document.createElement('input');

    relayState.setAttribute('type', 'hidden');
    relayState.setAttribute('name', 'RelayState');
    relayState.setAttribute('data-testId', 'relayStateInput');
    relayState.setAttribute('value', response.relayState);
    form.appendChild(relayState);

    form.submit();
  } else {
    window.open(response.location, '_self');
  }
};

export const useSSOLogin = ({
  shouldCheckSSO = false,
  setFormError,
  getFormErrorMessage,
}: {
  shouldCheckSSO?: boolean;
  setFormError: (error: string) => void;
  getFormErrorMessage: (error: string) => string;
}): {
  samlCheckQuery: UseQueryResult<SamlCheckDto>;
  ssoMutation: SSOMutation;
} => {
  const { redirect } = useQueryParams();

  const getRedirectRoute = () => {
    const redirectRoute = redirect ? `/${redirect}` : ROUTE.search;

    return `${window.origin}${redirectRoute}`;
  };

  const samlCheckQuery = useQuery(
    ['samlCheck'],
    () =>
      axios.get<SamlCheckDto>('/saml/check').then(response => response.data),
    { enabled: shouldCheckSSO }
  );

  const ssoMutation = useMutation(
    ['ssoLogin'],
    ({ institutionId }: SSOLoginProps = {}) =>
      axios
        .post<SSOLoginDto>(
          `${INVOKE_PATH_PREFIX}/saml/login`,
          {
            stripesUrl: getRedirectRoute(),
            ...(institutionId && { institutionId }),
          },
          {
            withCredentials: true,
          }
        )
        .then(response => processSSOLoginResponse(response.data))
        .catch(error => {
          setFormError(getFormErrorMessage(error));
        })
  );

  return {
    samlCheckQuery,
    ssoMutation,
  };
};
