import { isEmpty } from 'lodash';

import { DefaultCoverImageTypes } from '@app/constants';

import book from './icons/book.svg';
import video from './icons/video.svg';
import eBook from './icons/eBook.svg';
import audio from './icons/audio.svg';
import unknown from './icons/unknown.svg';
import other from './icons/other.svg';
import nonCatalog from './icons/nonCatalog.svg';

export const defaultCoverImages: { [key: string]: string } = {
  book,
  video,
  eBook,
  audio,
  unknown,
  other,
  nonCatalog,
};

export const getInstanceDefaultCover = (type = ''): string => {
  const firstSourceType = type?.split(',')[0];

  if (isEmpty(firstSourceType)) {
    return defaultCoverImages.unknown;
  }

  const defaultCoverImage =
    defaultCoverImages[DefaultCoverImageTypes[firstSourceType]];

  return defaultCoverImage || defaultCoverImages.other;
};
