import { FC } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';

import { PropsWithClassName } from '@app/constants';

import css from './SectionWithTitle.module.scss';

interface SectionTitleProps {
  title?: string;
  ariaLabel?: string;
  testId?: string;
}

export const SectionWithTitle: FC<PropsWithClassName<SectionTitleProps>> = ({
  title,
  ariaLabel = title,
  testId = kebabCase(title),
  className,
  children,
}) => {
  return (
    <section
      className={css.wrapper}
      aria-label={ariaLabel}
      data-testid={`record-${testId}-title`}
    >
      {title && <h2 className={cn(css.title, className)}>{title}</h2>}
      {children}
    </section>
  );
};
