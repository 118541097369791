import { createContext, MutableRefObject, useContext } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { SelectedFacetAttributes } from '@app/components';
import {
  SearchInstanceDto,
  SearchResultsDto,
  SearchResultsErrorsDto,
} from '@app/pages/constants';
import {
  PublicationYear,
  SEARCH_OPTION,
  SearchState,
  SearchStateInitialized,
} from '@app/search';
import { SetState } from '@app/constants';

export const SearchContext = createContext<SearchContextProps>(null!);

interface Results {
  searchResultsQuery: UseQueryResult<SearchResultsDto, SearchResultsErrorsDto>;
  pages: Array<SearchInstanceDto[]>;
  currentPage?: SearchInstanceDto[];
  totalRecords?: number;
}

export interface SearchContextProps {
  state: SearchStateInitialized;
  searchOption: SEARCH_OPTION;
  searchResults: Results;
  selectedFacets: SelectedFacetAttributes;
  selectedPublicationYear: PublicationYear;
  shouldRefocusRef: MutableRefObject<boolean>;
  instanceIdScrollTo: string | null;
  isPageFromBackButton: boolean;
  updateState: SetState<Partial<SearchState>>;
  setSelectedFacets: SetState<SelectedFacetAttributes>;
  setSelectedPublicationYear: SetState<PublicationYear>;
  setSearchOption: SetState<string>;
  setInstanceIdScrollTo: SetState<string | null>;
  setIsPageFromBackButton: SetState<boolean>;
}

export const useSearchContext = (): SearchContextProps =>
  useContext(SearchContext);
