import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  axios,
  getOpacConfigurationsQuery,
  OpacConfig,
  parseJSON,
} from '@app/utils';

export interface BannerMessageDto {
  message: string;
  pollFrequency: number;
}

const DEFAULT_BANNER_MESSAGE_POLLING_FREQUENCY = 10;

const BANNER_MESSAGE_CONFIG: OpacConfig = {
  module: 'OPAC_CONFIGURATION',
  configName: 'BANNER_MSG',
  code: 'BANNER_MSG',
  getConfigValue: config => ({
    bannerMessage: parseJSON(config?.value),
  }),
};

export const getPageBannerRefetchInterval = (
  bannerMessage?: BannerMessageDto
): number =>
  (bannerMessage?.pollFrequency ?? DEFAULT_BANNER_MESSAGE_POLLING_FREQUENCY) *
  60 *
  1000;

export const usePageBannerConfig = (isEnabled: boolean): string | undefined => {
  const [bannerMessage, setBannerMessage] = useState<string>();

  const pageBannerConfigQuery = useQuery(
    ['bannerMessage'],
    () =>
      axios
        .get<{ configs: OpacConfig[] }>('/opac-configurations/entries', {
          params: {
            query: getOpacConfigurationsQuery([BANNER_MESSAGE_CONFIG]),
          },
        })
        .then(request => parseJSON(request.data.configs[0].value)),
    {
      refetchInterval: getPageBannerRefetchInterval,
      enabled: isEnabled,
    }
  );

  useEffect(() => {
    if (pageBannerConfigQuery.isFetched) {
      setBannerMessage(pageBannerConfigQuery.data?.message ?? '');
    }
  }, [pageBannerConfigQuery.data?.message, pageBannerConfigQuery.isFetched]);

  return bannerMessage;
};
