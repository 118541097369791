import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { isEmpty, isNumber, pick, uniqueId } from 'lodash';

import {
  ItemStatusData,
  HoldDto,
  LoanDto,
  ChargeDto,
  SearchInstanceDto,
  SetTabsCounters,
} from '@app/pages/constants';
import {
  getHoldsStatusesData,
  getCheckoutsStatusesData,
  getFeesStatusesData,
} from '@app/pages/utils';
import { useAppContext } from '@app/contexts';
import { axios, setPageRecords } from '@app/utils';
import { RECORDS_PER_PAGE, ROUTE, SetState } from '@app/constants';

interface BookshelfDto {
  holds: HoldDto[];
  charges: ChargeDto[];
  loans: LoanDto[];
  totalHolds: number;
  totalChargesCount: number;
  totalLoans: number;
  sortByParams: SortBy;
}

interface BookshelfData {
  pages: Partial<SearchInstanceDto>[][] | null;
  itemsStatusesData: ItemStatusData[] | null;
  pageNumber: number;
  bookshelfQuery: UseQueryResult<BookshelfDto>;
  setPageNumber: SetState<number>;
}

interface SortBy {
  sortField: string;
  sortDirection: string;
}

export type QueryConfig = Partial<{
  includeHolds: boolean;
  includeCharges: boolean;
  includeLoans: boolean;
}>;

type ItemsData = HoldDto[] | LoanDto[] | ChargeDto[];

export const useBookshelfDataFetch = ({
  sortByParams,
  queryConfig,
  setTabsCounters,
}: {
  sortByParams: SortBy;
  queryConfig: QueryConfig;
  setTabsCounters: SetTabsCounters;
}): BookshelfData => {
  const intl = useIntl();
  const location = useLocation();
  const { locale } = useAppContext();
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState<Partial<SearchInstanceDto>[][]>([]);
  const [itemsStatusesData, setItemsStatusesData] = useState<
    ItemStatusData[] | null
  >(null);
  const { patronSession } = useAppContext();

  const externalSystemId = patronSession?.user?.externalSystemId;

  const setPagesData = useCallback(
    (itemsData: ItemsData): void => {
      const startInstancesIndex = (pageNumber - 1) * RECORDS_PER_PAGE;

      const formattedInstancesData = itemsData.map(record => {
        const item = record.item;

        if (!item && isEmpty(item)) {
          return {
            id: uniqueId(record.reason),
            title: record.reason
              ? record.reason
              : intl.$t({
                  id: 'fees.noReason',
                  defaultMessage: 'Undefined',
                }),
          };
        }

        return {
          ...pick(item, 'title', 'publication', 'isbns', 'sourceTypes'),
          id: item.instanceId,
          contributors: item.authors?.map(author => ({ name: author })),
        };
      });
      const records = formattedInstancesData.slice(
        startInstancesIndex,
        startInstancesIndex + RECORDS_PER_PAGE
      );

      setPageRecords({ records, pageNumber, setPages });
    },
    [intl, pageNumber]
  );

  const bookshelfQuery = useQuery<BookshelfDto>(
    ['bookshelfPage', externalSystemId, sortByParams, queryConfig],
    () =>
      axios
        .get<BookshelfDto>(
          `/opac-patron/account/${externalSystemId}/with-rich-metadata`,
          {
            params: {
              ...queryConfig,
              sortBy: `${sortByParams.sortField}/sort.${sortByParams.sortDirection}`,
            },
          }
        )
        .then(response => response.data),
    { enabled: !isEmpty(externalSystemId) }
  );

  useEffect(() => {
    if (bookshelfQuery.data && !isEmpty(bookshelfQuery.data)) {
      const holdsData = bookshelfQuery.data.holds;
      const loansData = bookshelfQuery.data.loans;
      const chargesData = bookshelfQuery.data.charges;

      if (location.pathname === ROUTE.holds) {
        if (!isEmpty(holdsData)) {
          setPagesData(holdsData);
          setItemsStatusesData(getHoldsStatusesData(holdsData, intl));
        } else {
          setItemsStatusesData([]);
        }
      }

      if (location.pathname === ROUTE.checkouts) {
        if (!isEmpty(loansData)) {
          setPagesData(loansData);
          setItemsStatusesData(
            getCheckoutsStatusesData(loansData, chargesData, intl, locale)
          );
        } else {
          setItemsStatusesData([]);
        }
      }

      if (location.pathname === ROUTE.fees) {
        if (!isEmpty(chargesData)) {
          setPagesData(chargesData);
          setItemsStatusesData(
            getFeesStatusesData(chargesData, loansData, intl, locale)
          );
        } else {
          setItemsStatusesData([]);
        }
      }
    }

    if (isEmpty(bookshelfQuery.data) && bookshelfQuery.isSuccess) {
      setItemsStatusesData([]);
    }
  }, [
    bookshelfQuery.data,
    bookshelfQuery.isSuccess,
    intl,
    locale,
    location,
    setPagesData,
  ]);

  useEffect(() => {
    if (bookshelfQuery.data) {
      const tabsCounters = {
        holds: bookshelfQuery.data.totalHolds,
        checkouts: bookshelfQuery.data.totalLoans,
        fees: bookshelfQuery.data.totalChargesCount,
      };

      if (Object.values(tabsCounters).every(isNumber)) {
        setTabsCounters(tabsCounters);
      }
    }
  }, [setTabsCounters, bookshelfQuery.data]);

  return {
    pages,
    itemsStatusesData,
    pageNumber,
    bookshelfQuery,
    setPageNumber,
  };
};
