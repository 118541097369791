import { FormattedMessage } from 'react-intl';
import {
  faAddressCard,
  faBookOpen,
  faUniversity,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SEARCH_OPTION } from '@app/search';
import { sharedMessages } from '@app/translations';

export const AutoCompleteGroups = [
  {
    id: SEARCH_OPTION.title,
    title: <FormattedMessage {...sharedMessages.title} />,
    icon: <FontAwesomeIcon icon={faBookOpen} />,
  },
  {
    id: SEARCH_OPTION.author,
    title: <FormattedMessage {...sharedMessages.author} />,
    icon: <FontAwesomeIcon icon={faAddressCard} />,
  },
  {
    id: SEARCH_OPTION.subject,
    title: <FormattedMessage {...sharedMessages.subject} />,
    icon: <FontAwesomeIcon icon={faUniversity} />,
  },
];
