import { FC, useEffect } from 'react';

import { IndeterminateSpinner } from '@ebsco-ui/ebsco-ui';

import { useAppContext } from '@app/contexts';
import { useQueryParams } from '@app/hooks';

import css from './SSOPage.module.scss';

export const SSOPage: FC = () => {
  const { fwd: redirectRoute, ssoToken } = useQueryParams();
  const { authenticateToken } = useAppContext();

  useEffect(() => {
    if (ssoToken) {
      authenticateToken(ssoToken as string, redirectRoute as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={css.spinner} data-testid="spinner">
      <IndeterminateSpinner level="page" />
    </div>
  );
};
