import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { InPageNotification } from '@ebsco-ui/ebsco-ui';

import { NotificationProps } from '@app/components';
import { useLogin, useSSOLogin, useFeatureFlag } from '@app/hooks';
import { APIError } from '@app/utils';
import { sharedMessages } from '@app/translations';
import { FEATURE, LOGIN_METHOD } from '@app/constants';

interface UseAuthenticationProps {
  method?: LOGIN_METHOD;
  passwordFieldName?: string;
  shouldCheckSSO?: boolean;
}

interface AuthenticationUtils {
  notification: NotificationProps | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isSSOEnabled: boolean;
  isSSOError: boolean;
  loginUser: (values?, form?) => void;
}

export const useAuthentication = ({
  passwordFieldName,
  method = LOGIN_METHOD.native,
  shouldCheckSSO = false,
}: UseAuthenticationProps): AuthenticationUtils => {
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);
  const [formError, setFormError] = useState<string | null>(null);
  const { search: urlQuery } = useLocation();
  const { $t } = useIntl();
  const { loginMutation, directLoginMutation } = useLogin({
    isConsortiaLogin: Boolean(consortiaLoginFlowFlag?.isActive),
  });

  const getFormErrorMessage = error =>
    error instanceof APIError
      ? error.message
      : $t(sharedMessages.somethingWentWrongContactAdmin);

  const { ssoMutation, samlCheckQuery } = useSSOLogin({
    shouldCheckSSO,
    setFormError,
    getFormErrorMessage,
  });

  const isSSOLogin = method === LOGIN_METHOD.sso;
  const isSSOError = Number(queryString.parse(urlQuery)?.errorCode) >= 400;

  const methodMap = {
    [LOGIN_METHOD.direct]: directLoginMutation,
    [LOGIN_METHOD.native]: loginMutation,
    [LOGIN_METHOD.sso]: ssoMutation,
  };

  const loginUser = (values, form) => {
    setFormError(null);

    methodMap[method].mutate(values, {
      onError: error => {
        setFormError(getFormErrorMessage(error));

        form.resetFieldState(passwordFieldName);
        form.change(passwordFieldName, null);
      },
    });
  };

  const notification = useMemo(
    () =>
      formError
        ? {
            variant: InPageNotification.VARIANT.error,
            description: formError,
          }
        : undefined,
    [formError]
  );

  useEffect(() => {
    if (!isSSOError) {
      setFormError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery]);

  useEffect(() => {
    if (isSSOError) {
      setFormError($t(sharedMessages.somethingWentWrongContactAdmin));
    }
  }, [$t, isSSOError]);

  return {
    notification,
    isLoading: isSSOLogin ? ssoMutation.isLoading : methodMap[method].isLoading,
    isSuccess: isSSOLogin ? ssoMutation.isSuccess : methodMap[method].isSuccess,
    isSSOEnabled: Boolean(samlCheckQuery.data?.active),
    isSSOError,
    loginUser: isSSOLogin ? ssoMutation.mutate : loginUser,
  };
};
