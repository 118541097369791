import { FC, RefObject } from 'react';
import cn from 'classnames';

import { PageBanner } from '@app/components';
import { useAppContext } from '@app/contexts';
import { PropsWithClassName } from '@app/constants';

import css from './MainContentContainer.module.scss';

interface MainContentContainerProps {
  scrollRef: RefObject<HTMLDivElement>;
}

export const MainContentContainer: FC<
  PropsWithClassName<MainContentContainerProps>
> = ({ scrollRef, className, ...rest }) => {
  const { bannerMessage } = useAppContext();

  return (
    <div className={css.wrapper}>
      {bannerMessage && <PageBanner message={bannerMessage} />}
      <div
        className={cn(css.mainContainer, className, {
          [css.hasBanner]: bannerMessage,
        })}
        tabIndex={-1}
        ref={scrollRef}
        id="mainContainer"
        {...rest}
      />
    </div>
  );
};
