import { FC } from 'react';
import { Link } from 'react-router-dom';

import { SearchInstanceSubjectsDto } from '@app/pages/SearchResultsPage';
import { SEARCH_OPTION } from '@app/search';
import { useQueryParams } from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { ROUTE } from '@app/constants';

import css from './RelatedSubjects.module.scss';

interface RelatedSubjectsProps {
  subjects: SearchInstanceSubjectsDto;
}

export const RelatedSubjects: FC<RelatedSubjectsProps> = ({ subjects }) => {
  const queryParams = useQueryParams();

  return (
    <div className={css.wrapper}>
      {subjects.map(subject => (
        <Link
          to={generateRouteParams({
            pathname: ROUTE.search,
            query: {
              ...queryParams,
              option: SEARCH_OPTION.subject,
              query: subject?.value ?? subject,
            },
          })}
          className={css.link}
          key={subject?.value ?? subject}
        >
          {subject?.value ?? subject}
        </Link>
      ))}
    </div>
  );
};
