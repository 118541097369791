import { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { BROWSE_SHELF_INSTANCES_LIMIT } from '@app/pages/InstanceDetailsPage/constants';
import { SearchInstanceDto } from '@app/pages/SearchResultsPage';
import { axios, formatArrayToString } from '@app/utils';

type VirtualShelfField =
  | 'contributors'
  | 'title'
  | 'id'
  | 'isbns'
  | 'sourceTypes';

type VirtualShelfItemDto = Pick<SearchInstanceDto, VirtualShelfField>;

interface VirtualShelfDto {
  instances: VirtualShelfItemDto[];
}

export type VirtualShelfItem = Omit<VirtualShelfItemDto, 'sourceTypes'> & {
  sourceTypes?: string;
};

interface VirtualShelf {
  virtualShelfQuery: UseQueryResult<VirtualShelfItemDto[], null>;
  relatedItems?: VirtualShelfItem[];
}

export const useVirtualShelfFetch = (order?: string): VirtualShelf => {
  const [relatedItems, setRelatedItems] = useState<VirtualShelfItem[]>();

  const virtualShelfQuery = useQuery<
    VirtualShelfDto,
    null,
    VirtualShelfItemDto[]
  >(
    ['instance-details', 'virtual-shelf', order],
    () =>
      axios
        .get<VirtualShelfDto>('/search/virtual-shelves/instances', {
          params: {
            expandAll: true,
            effectiveShelvingOrder: order,
            limit: BROWSE_SHELF_INSTANCES_LIMIT,
            type: 'AROUND',
          },
        })
        .then(response => response.data),
    {
      enabled: Boolean(order),
      select: ({ instances }) => instances,
    }
  );

  useEffect(() => {
    if (virtualShelfQuery.isFetched) {
      const instances = virtualShelfQuery.data;

      setRelatedItems(
        instances?.map(({ id, title, contributors, sourceTypes, isbns }) => ({
          id,
          title,
          sourceTypes: formatArrayToString(sourceTypes),
          contributors,
          isbns,
        }))
      );
    }
  }, [virtualShelfQuery.data, virtualShelfQuery.isFetched]);

  return {
    relatedItems,
    virtualShelfQuery,
  };
};
