import { FC } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, Params, useNavigate } from 'react-router-dom';
import { noop } from 'lodash';

import { Button } from '@ebsco-ui/ebsco-ui';

import { generateRouteParams } from '@app/utils';
import { sharedMessages } from '@app/translations';
import { ROUTE } from '@app/constants';

interface ButtonWithRedirectProps {
  redirectBackRoute: string;
  queryParams?: Params;
  onClick?: () => void;
}

export const ButtonWithRedirect: FC<ButtonWithRedirectProps> = ({
  redirectBackRoute,
  queryParams = {},
  onClick = noop,
}) => {
  const navigate = useNavigate();
  const { $t } = useIntl();

  return (
    <Button
      styleType="default"
      onClick={() => {
        navigate(
          generateRouteParams({
            pathname: ROUTE.login,
            query: {
              redirect: generatePath(redirectBackRoute, queryParams).slice(1),
            },
          })
        );
        onClick();
      }}
    >
      {$t(sharedMessages.signIn)}
    </Button>
  );
};
