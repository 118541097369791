import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash';
import {
  Button,
  createBemBlockBuilder,
  IndeterminateSpinner,
  PageErrorMessage,
} from '@ebsco-ui/ebsco-ui';

import {
  AuthForm,
  AuthMethodSwitch,
  AuthPageWrapper,
  FieldDescription,
} from '@app/components';
import { fieldDescriptions } from '@app/pages';
import { useAuthentication } from '@app/hooks';
import { generateRouteParams, axios } from '@app/utils';
import { sharedMessages } from '@app/translations';
import { LOGIN_METHOD, ROUTE, URLQuery } from '@app/constants';

import '../../components/AuthPageWrapper/AuthPageWrapper.scss';
import './LoginSwitchPage.scss';

interface InstitutionsDto {
  id: string;
  code: string;
  name: string;
  authProfile?: string;
}

export interface InstitutionItem {
  id: string;
  label: string;
  value: string;
  method: LOGIN_METHOD;
  description: FieldDescription[];
  code: string;
  passwordFieldName?: string;
  ssoProviderLink?: string;
}

const formatInstitutions = (
  institutions: InstitutionsDto[]
): InstitutionItem[] => {
  return institutions.map(institution => ({
    id: institution.id,
    label: institution.name,
    value: '',
    method:
      institution.authProfile === 'saml'
        ? LOGIN_METHOD.sso
        : LOGIN_METHOD.native,
    code: institution.code,
    description:
      fieldDescriptions[institution.authProfile as string] ??
      fieldDescriptions['BASIC/BARCODE+PIN'],
    passwordFieldName: 'pin',
  }));
};

const cnBem = createBemBlockBuilder(['authPageWrapper', 'loginSwitchPage']);

export const LoginSwitchPage: FC = () => {
  const [currentMethod, setCurrentMethod] = useState<
    InstitutionItem | undefined
  >();
  const [isMethodSelected, setMethodSelected] = useState(false);
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { search: urlQuery } = useLocation();

  const isSSOLogin =
    currentMethod?.method === LOGIN_METHOD.sso && isMethodSelected;

  const institutionsQuery = useQuery(['institutions'], () =>
    axios
      .get('/opac-configurations/institutions')
      .then(response => formatInstitutions(response.data))
  );

  const titlePrompt = (
    <>
      {$t(
        {
          id: 'login.accessProvidedBy',
          defaultMessage: 'Access provided by <b>{institution}</b>',
        },
        {
          institution: currentMethod?.label,
          b: chunks => <strong>{chunks}</strong>,
        }
      )}
      <div>
        {$t(
          {
            id: 'login.signInWithAnotherInstitution',
            defaultMessage:
              'Want to sign in to another institution? <a>Find your institution</a>.',
          },
          {
            a: chunks => (
              <Link to={`${ROUTE.login}?switch=true`}>{chunks}</Link>
            ),
          }
        )}
      </div>
    </>
  );

  const { notification, isLoading, isSuccess, isSSOError, loginUser } =
    useAuthentication({
      method: currentMethod?.method,
      passwordFieldName: currentMethod?.passwordFieldName,
    });

  useEffect(() => {
    navigate(
      generateRouteParams({
        pathname: ROUTE.login,
        query: {
          ...queryString.parse(urlQuery),
          switch: 'true',
        },
      })
    );

    setMethodSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryString.parse(urlQuery)?.switch && isMethodSelected) {
      setMethodSelected(false);
    }
  }, [isMethodSelected, urlQuery]);

  useEffect(() => {
    if (isSSOLogin) {
      loginUser({ institutionId: currentMethod?.code });
    }
  }, [isSSOLogin, loginUser, currentMethod?.code]);

  useEffect(() => {
    if (institutionsQuery.isSuccess) {
      setCurrentMethod(institutionsQuery.data[0]);
    }
  }, [institutionsQuery.data, institutionsQuery.isSuccess]);

  const isSSOLoggingIn = isSSOLogin && !isSSOError;

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'login.documentTitle',
            defaultMessage: 'Login - EBSCO Locate',
          })}
        </title>
      </Helmet>
      {(isSSOLoggingIn || institutionsQuery.isLoading) && (
        <div className={cnBem('__spinner')} data-testid="spinner">
          <IndeterminateSpinner level="page" />
        </div>
      )}
      {currentMethod && institutionsQuery.data && (
        <AuthPageWrapper
          title={
            isMethodSelected
              ? $t(sharedMessages.welcomeBack)
              : $t(sharedMessages.signIn)
          }
          titlePrompt={isMethodSelected ? titlePrompt : undefined}
          notification={
            isMethodSelected || isSSOError ? notification : undefined
          }
        >
          {!isMethodSelected || isSSOLogin ? (
            <form
              className={cnBem('__form')}
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <AuthMethodSwitch
                options={institutionsQuery.data}
                selectedItem={currentMethod}
                setCurrentMethod={setCurrentMethod}
              />
              <Button
                className={cnBem('__continueButton')}
                onClick={() => {
                  navigate(
                    generateRouteParams({
                      pathname: ROUTE.login,
                      query: omit(queryString.parse(urlQuery), [
                        'switch',
                      ]) as URLQuery,
                    })
                  );
                  setMethodSelected(true);
                }}
              >
                {$t({
                  id: 'login.continue',
                  defaultMessage: 'Continue',
                })}
              </Button>
            </form>
          ) : (
            <AuthForm
              fields={currentMethod.description}
              isLoading={isLoading || isSuccess}
              onSubmit={(values, form) => {
                loginUser(
                  {
                    ...values,
                    additionalParameters: {
                      agencyCode: currentMethod?.code,
                    },
                  },
                  form
                );
              }}
            />
          )}
        </AuthPageWrapper>
      )}
      {institutionsQuery.isError && (
        <div className={cnBem('__pageErrorMessage')}>
          <PageErrorMessage
            buttonText={$t(sharedMessages.refreshPage)}
            title={$t(sharedMessages.pageUnavailable)}
            text={$t(sharedMessages.searchUnavailableText)}
            titleTag="h2"
            onClick={() => institutionsQuery?.refetch()}
          />
        </div>
      )}
      <form id="ssoForm" aria-hidden={true} />
    </>
  );
};
