import { createContext, useContext } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { ItemStatusDto } from '@app/pages/constants';

export const MappingsContext = createContext<Mappings>({
  locations: undefined,
  pickupLocations: undefined,
  userItemStatuses: undefined,
  noteTypes: undefined,
  areUserItemStatusesLoading: false,
  configurations: {
    isNativeLogin: false,
    isSSOLogin: false,
    isStackMapEnabled: false,
    libraryLocationMap: {
      library: '',
      location: '',
    },
    configurationsQuery: undefined,
  },
});

export interface LocationDto {
  id: string;
  name: string;
  code: string;
  isActive: boolean;
  institutionId: string;
  campusId: string;
  libraryId: string;
  primaryServicePoint: string;
  discoveryDisplayName?: string;
}

export interface LocationsDto {
  locations: LocationDto[];
  totalRecords: number;
}

export interface ServicePointDto {
  id: string;
  name: string;
  code: string;
  discoveryDisplayName: string;
  pickupLocation: boolean;
  holdShelfExpiryPeriod: {
    duration: number;
    intervalId: string;
  };
}

export interface PickupLocationsDto {
  servicepoints: ServicePointDto[];
  totalRecords: number;
}

export interface ConfigurationsValues {
  isNativeLogin: boolean;
  isSSOLogin: boolean;
  isStackMapEnabled: boolean;
  libraryLocationMap: {
    library: string;
    location: string;
  };
}

interface Configurations {
  configurationsQuery?: UseQueryResult;
}

interface NoteType {
  active: boolean;
  labelKey: string;
}

export interface NoteTypesDto {
  noteTypesForDisplay: NoteType[];
}

export type NoteTypesTransformedDto = Record<string, NoteType>;

export interface Mappings {
  areUserItemStatusesLoading: boolean;
  configurations: Configurations & ConfigurationsValues;
  locations?: LocationDto[];
  pickupLocations?: ServicePointDto[];
  userItemStatuses?: ItemStatusDto;
  noteTypes?: NoteTypesTransformedDto;
}

export const useMappingsContext = (): Mappings => {
  return useContext(MappingsContext);
};
