import { last } from 'lodash';

import { FOCUSED_ELEMENT_SELECTOR } from '@app/constants';

interface SetFocusOnProps {
  selector?: string;
  element?: HTMLElement | null;
}

export const setFocusOn = ({
  selector = FOCUSED_ELEMENT_SELECTOR,
  element,
}: SetFocusOnProps = {}): void => {
  const elementToFocus: HTMLElement | undefined =
    element || last(document.querySelectorAll(selector));

  if (elementToFocus) {
    elementToFocus.focus();
  }
};
