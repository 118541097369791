import branding from '../branding.json';

interface BrandingColor {
  main: string;
  contrast: string;
}

interface PrimaryColor {
  primary: BrandingColor;
}

interface BrandingLogo {
  altText: string;
  url: string;
  link?: string;
  alignment?: string;
  id?: string;
}

interface BrandingSearchBar {
  text: string;
  poweredByLabel: string;
  shortText?: string;
}

interface BrandingCustomer {
  name: string;
}

interface NavigationItems {
  label: string;
  link: string;
}

interface BrandingNavigation {
  label: string;
  display: boolean;
  items: NavigationItems[];
}

interface BrandingConceptMap {
  enabled?: boolean;
}

interface BrandingData {
  colors: PrimaryColor;
  logo: BrandingLogo;
  searchBar: BrandingSearchBar;
  navigation: {
    [propName: string]: BrandingNavigation;
  };
  copyrightNotice?: string;
  customer?: BrandingCustomer;
  conceptMap?: BrandingConceptMap;
}

export const useBranding = (): BrandingData => branding;
