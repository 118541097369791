import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import {
  ExternalLink,
  Footer,
  Image,
  Notification,
  NotificationProps,
} from '@app/components';
import { useBranding } from '@app/hooks';
import { ROUTE, SUPPORT_SITE_URL } from '@app/constants';

import './AuthPageWrapper.scss';

const cnBem = createBemBlockBuilder(['authPageWrapper']);

interface LoginPageWrapperProps {
  title: string;
  containerClassName?: string;
  notification?: NotificationProps;
  titlePrompt?: ReactElement;
}

export const AuthPageWrapper: FC<LoginPageWrapperProps> = ({
  title,
  containerClassName,
  notification,
  titlePrompt,
  children,
}) => {
  const { logo } = useBranding();
  const { $t } = useIntl();

  return (
    <div className={cnBem('__wrapper')}>
      <header className={cnBem('__header')}>
        <div className={cn('container', cnBem('__headerInner'))}>
          <Link to={ROUTE.search}>
            <Image className="logo" src={logo.url} altText={logo.altText} />
          </Link>
          <ExternalLink href={SUPPORT_SITE_URL}>
            {$t({
              id: 'login.supportLink',
              defaultMessage: 'EBSCO Support Site',
            })}
          </ExternalLink>
        </div>
      </header>
      <main
        role="main"
        className={cnBem('__mainWrapper')}
        data-testid="auth-wrapper"
      >
        <div className={cn(cnBem('__container'), containerClassName)}>
          <div className={cnBem('__heading')} data-testid="auth-heading">
            <h1 className={cnBem('__title')}>{title}</h1>
            {titlePrompt && (
              <div className={cnBem('__titlePrompt')}>{titlePrompt}</div>
            )}
          </div>
          {notification && (
            <div className={cnBem('__section')} data-testid="auth-notification">
              <Notification
                className={cnBem('__notification')}
                {...notification}
              />
            </div>
          )}
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};
