import { defineMessages } from 'react-intl';

import { sharedMessages } from '@app/translations';

export const removeHoldToastMessages = {
  ...defineMessages({
    success: {
      id: 'removeHoldToast.successfullyRemoved',
      defaultMessage: 'Hold successfully removed',
    },
  }),
  error: sharedMessages.somethingWentWrongPleaseTryAgain,
};
