import { LocationDto } from '@app/contexts';

import { MappedFacetValue } from '../constants';
import { UnmappedFacetValue } from '.';

export const getLocationOptions = (
  locations: LocationDto[] = [],
  values: UnmappedFacetValue[] = []
): MappedFacetValue[] =>
  values.map(({ id, totalRecords }) => {
    const location = locations.find(term => term.id === id);

    return {
      label: location?.discoveryDisplayName ?? location?.name,
      value: id,
      count: totalRecords,
    };
  });
