import { ItemStatusDto } from '../constants';
import { ItemWithStatus } from '.';

export const getItemWithStatus = (
  instanceId: string,
  userItemStatuses?: ItemStatusDto
): ItemWithStatus | undefined => {
  const findItem = ({ item }) => instanceId === item.instanceId;

  const itemLoan = userItemStatuses?.loans?.find(findItem);

  if (itemLoan) {
    const itemChargeData = userItemStatuses?.charges?.find(
      ({ item }) => itemLoan.item.itemId === item?.itemId
    );

    return {
      ...itemLoan,
      ...itemChargeData?.chargeAmount,
    };
  }

  const itemHold = userItemStatuses?.holds?.find(findItem);

  if (itemHold) {
    return itemHold;
  }

  return undefined;
};
