import { FC, ReactElement, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { Button, Drawer, Icon } from '@ebsco-ui/ebsco-ui';

import { useFeatureFlag } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { BREAKPOINT, FEATURE } from '@app/constants';

import { FacetInstanceDto, PublicationYearFacet, ActiveFilters } from '..';

import css from './FilterPanelContent.module.scss';

interface FilterPanelContentProps {
  selectedFiltersCount: number;
  facets: FacetInstanceDto;
  renderFacets: () => ReactElement[];
  toggleFacetAttribute: (facetKey: string, value: string) => void;
}

export const FilterPanelContent: FC<FilterPanelContentProps> = ({
  selectedFiltersCount,
  facets,
  renderFacets,
  toggleFacetAttribute,
}) => {
  const { $t } = useIntl();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isFooterOverlapped, setFooterOverlapped] = useState(false);
  const [dateFacetCount, setDateFacetCount] = useState(0);
  const publicationYearFacetFlag = useFeatureFlag(FEATURE.publicationYearFacet);

  const totalSelectedFiltersCount = selectedFiltersCount + dateFacetCount;
  const refineResultsButtonId = 'refineResultsButton';

  const filterIcon =
    totalSelectedFiltersCount > 0
      ? {
          ...Icon.ICONS.FILTERS_ON,
          title: $t({
            id: 'filterPane.facetsOn',
            defaultMessage: 'Facets are selected',
          }),
        }
      : {
          ...Icon.ICONS.FILTERS_OFF,
          title: $t({
            id: 'filterPane.facetsOff',
            defaultMessage: 'Facets are not selected',
          }),
        };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${BREAKPOINT.SCREEN.DESKTOP_SM}px)`,
  });

  const handleScroll = useCallback(({ target }) => {
    const { clientHeight, scrollHeight, scrollTop } = target;
    const shouldOverlap = scrollHeight - Math.round(scrollTop) > clientHeight;

    setFooterOverlapped(shouldOverlap);
  }, []);

  const showDrawer = useCallback(
    (isOpen: boolean) => () => setDrawerOpen(isOpen),
    []
  );

  const filterPanelTitle = (
    <h2 className={css.title} data-testid="filter-panel-title">
      <Icon {...filterIcon} />
      {!totalSelectedFiltersCount ? (
        <FormattedMessage
          id="filterPane.title"
          defaultMessage="Refine results"
        />
      ) : (
        <FormattedMessage
          id="filterPane.titlePlural"
          defaultMessage="Refine results ({count})"
          values={{ count: totalSelectedFiltersCount }}
        />
      )}
    </h2>
  );

  const filterPanelContent = (
    <section
      aria-label={$t({
        id: 'filterPane.sectionAriaLabel',
        defaultMessage: 'Search filters',
      })}
      className={css.filterPanelContent}
      data-testid="filter-panel"
    >
      <div className={css.filterPanelHeader}>
        {filterPanelTitle}
        {!isDesktop && (
          <Button
            styleType="flat-icon"
            className={css.closeButton}
            aria-label={$t(sharedMessages.close)}
            onClick={showDrawer(false)}
          >
            <FontAwesomeIcon className="fa-lg" icon={faTimes} />
          </Button>
        )}
      </div>
      {totalSelectedFiltersCount > 0 && (
        <ActiveFilters
          facets={facets}
          onFacetAttributeRemoval={toggleFacetAttribute}
        />
      )}
      <div className={css.filterPanelBody} onScroll={handleScroll}>
        {renderFacets()}
        {publicationYearFacetFlag?.isActive && (
          <PublicationYearFacet
            dateFacetCount={dateFacetCount}
            setDateFacetCount={setDateFacetCount}
          />
        )}
      </div>
      {!isDesktop && (
        <div
          className={cn(css.filterPanelFooter, {
            [css.isFooterOverlapping]: isFooterOverlapped,
          })}
        >
          <Button
            styleType="solid"
            className={css.seeResultsButton}
            onClick={showDrawer(false)}
          >
            <FormattedMessage
              id="filterPane.seeResults"
              defaultMessage="See results"
            />
          </Button>
        </div>
      )}
    </section>
  );

  if (isDesktop) {
    return filterPanelContent;
  }

  return (
    <>
      <div className={css.refineResultsButtonWrapper}>
        <button
          className={css.refineResultsButton}
          id={refineResultsButtonId}
          type="button"
          aria-expanded={isDrawerOpen}
          onClick={showDrawer(true)}
        >
          {filterPanelTitle}
        </button>
      </div>
      <Drawer
        drawerTag="section"
        className={css.drawer}
        position="start"
        titleElementId={refineResultsButtonId}
        isOpen={isDrawerOpen}
        onClose={showDrawer(false)}
      >
        {filterPanelContent}
      </Drawer>
    </>
  );
};
