import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { flatMap, compact } from 'lodash';

import { SEARCH_OPTION } from '@app/search';
import { useSearchContext } from '@app/contexts';
import { removeMarkTagsFromString } from '@app/utils';

import {
  SuggestionDto,
  SuggestionDtoValue,
  useSuggestionsFetch,
} from './requests';

interface SelectItemProps {
  selectedValue: string;
  selectedOption: SEARCH_OPTION;
}

interface AutoCompleteProps {
  value: string;
  searchOption: SEARCH_OPTION;
  onSelectItem: (selectItem: SelectItemProps) => void;
}

interface TermFragments {
  term: string;
  fragments: { text: string; isHighlighted: boolean }[];
}

interface SuggestionItem extends Partial<SuggestionDtoValue>, TermFragments {
  category: string;
}

interface AutoCompleteUtils {
  inputValue: string;
  suggestions: SuggestionItem[];
  handleSelectItem: (changes) => void;
  handleInputValueChange: (changes) => void;
  handleSearchButtonClick: (event) => void;
}

const parseHighlightedTerm = (term: string): TermFragments => {
  const regex = /(<mark>.*<\/mark>)/;
  const parts = compact(term.split(regex));

  return {
    term: term.replace(regex, match => removeMarkTagsFromString(match)),
    fragments: parts.map(part => {
      const isHighlighted = part.startsWith('<mark>');

      return {
        text: isHighlighted ? removeMarkTagsFromString(part) : part,
        isHighlighted,
      };
    }),
  };
};

const formatSuggestionItems = (
  suggestions: SuggestionDto = {}
): SuggestionItem[] => {
  return flatMap(
    suggestions,
    (suggestionsValues, category) =>
      suggestionsValues?.terms?.map(suggestion => ({
        ...suggestion,
        category,
        ...parseHighlightedTerm(suggestion.term),
      })) ?? []
  );
};

export const useAutoComplete = ({
  value,
  searchOption,
  onSelectItem,
}: AutoCompleteProps): AutoCompleteUtils => {
  const { updateState, shouldRefocusRef } = useSearchContext();

  const [formattedSuggestions, setFormattedSuggestions] = useState<
    SuggestionItem[]
  >([]);
  const [inputValue, setInputValue] = useState(value);

  const { query: suggestionsQuery, setSearchTerm } =
    useSuggestionsFetch(searchOption);

  const handleInputValueChange = changes => {
    setInputValue(changes.inputValue);
    setSearchTerm(changes.inputValue);
  };

  const handleSelectItem = changes => {
    setInputValue(changes?.inputValue ?? '');

    if (
      changes.type === useCombobox.stateChangeTypes.ItemClick ||
      changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter
    ) {
      onSelectItem({
        selectedValue: changes.selectedItem.term,
        selectedOption: changes.selectedItem.category,
      });
    }
  };

  const handleSearchButtonClick = event => {
    if (event) {
      event.preventDefault();
    }

    updateState({
      query: inputValue,
      option: searchOption,
    });

    shouldRefocusRef.current = true;
  };

  useEffect(() => {
    setFormattedSuggestions(
      formatSuggestionItems(suggestionsQuery?.data?.suggestions)
    );
  }, [suggestionsQuery?.data?.suggestions]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return {
    inputValue,
    suggestions: formattedSuggestions,
    handleInputValueChange,
    handleSelectItem,
    handleSearchButtonClick,
  };
};
