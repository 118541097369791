import { generatePath } from 'react-router-dom';
import queryString from 'query-string';

import { ROUTE } from '@app/constants';

import { useQueryParams } from './useQueryParams';

interface UseInstanceDetailsRoutePath {
  pathname: string;
  search: string;
}

type InstanceDetailsRoutePath = {
  getInstanceDetailsRoutePath: (id: string) => UseInstanceDetailsRoutePath;
  checkIsInstanceDetailsRoute: (pathname: string) => boolean;
};

export const useInstanceDetailsRoutePath = (): InstanceDetailsRoutePath => {
  const searchState = useQueryParams();

  return {
    getInstanceDetailsRoutePath(id: string) {
      return {
        pathname: generatePath(ROUTE.instanceDetails, { id }),
        search: queryString.stringify(searchState),
      };
    },
    checkIsInstanceDetailsRoute(pathname) {
      return pathname.startsWith(ROUTE.instanceDetails.split('/:id')[0]);
    },
  };
};
