import {
  FC,
  useState,
  useEffect,
  useReducer,
  useCallback,
  Reducer,
} from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isEmpty } from 'lodash';
import { faBooks } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Loading,
  IndeterminateSpinner,
  Page,
  Button,
  ShowMorePagination,
  PageErrorMessage,
  InPageNotification,
} from '@ebsco-ui/ebsco-ui';

import {
  CourseHero,
  RecordSummary,
  RecordLinks,
  SortByDropdown,
  BackToRoute,
} from '@app/components';
import { SORT_FIELD, useUrlManager } from '@app/search';
import {
  useAppContext,
  useMappingsContext,
  useAvailabilityContext,
} from '@app/contexts';
import {
  useCourseReserveDetailsFetch,
  usePaginationItemFocus,
} from '@app/hooks';
import {
  sharedMessages,
  getPageMessages,
  sortByItemsMessages,
} from '@app/translations';
import {
  RECORDS_PER_PAGE,
  ROUTE,
  SORT_DIRECTION,
  SortOption,
} from '@app/constants';

import { getItemWithStatus, getItemStatusData } from '../utils';
import { CourseReserveDetailsData } from '../CourseReservesPage/courseReservesTypes';
import { SearchState } from './courseReserveTypes';

import css from './CourseReserveDetailsPage.module.scss';

export const sortOptions: SortOption[] = [
  {
    label: sortByItemsMessages.title,
    sortField: SORT_FIELD.title,
    sortDirection: SORT_DIRECTION.asc,
  },
  {
    label: sortByItemsMessages.title,
    sortField: SORT_FIELD.title,
    sortDirection: SORT_DIRECTION.desc,
  },
  {
    label: sortByItemsMessages.author,
    sortField: SORT_FIELD.author,
    sortDirection: SORT_DIRECTION.asc,
  },
  {
    label: sortByItemsMessages.author,
    sortField: SORT_FIELD.author,
    sortDirection: SORT_DIRECTION.desc,
  },
];

export const CourseReserveDetailsPage: FC = () => {
  const intl = useIntl();
  const { $t } = intl;
  const { locale } = useAppContext();
  const { userItemStatuses } = useMappingsContext();
  const { availability, getAvailability, isAvailabilityLoading } =
    useAvailabilityContext();
  const [isShowMoreLoading, setShowMoreLoading] = useState(false);
  const { updateUrl, getSearchStateFromUrl } = useUrlManager();
  const location = useLocation();
  const { sortField, sortDirection } = getSearchStateFromUrl();

  const [state, updateState] = useReducer<
    Reducer<SearchState, Partial<SearchState>>
  >(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
      pageNumber: newState.pageNumber ?? 1,
    }),
    {
      pageNumber: 1,
      sortDirection: sortDirection as SORT_DIRECTION,
      sortField: sortField as SORT_FIELD,
    }
  );

  const {
    courseQuery,
    courseListingsQuery,
    courseReserve,
    pages,
    totalRecords,
    currentPage,
  } = useCourseReserveDetailsFetch(state);

  const getRecordsIds = (records: CourseReserveDetailsData[] = []) =>
    records.map(record => record.id);
  const hasCourses =
    (courseListingsQuery.isSuccess && !isEmpty(pages[0])) ||
    state.pageNumber > 1;

  usePaginationItemFocus({
    pageNumber: state.pageNumber,
    totalPagesNumber: pages.length,
  });

  useEffect(() => {
    const searchStateFromUrl = getSearchStateFromUrl();

    updateState({
      sortDirection: searchStateFromUrl.sortDirection,
      sortField: searchStateFromUrl.sortField,
    });
  }, [getSearchStateFromUrl]);

  useEffect(() => {
    if (courseListingsQuery.isSuccess) {
      getAvailability(getRecordsIds(currentPage), state.pageNumber);
    }
  }, [
    getAvailability,
    courseListingsQuery.isSuccess,
    currentPage,
    state.pageNumber,
  ]);

  useEffect(() => {
    if (!courseListingsQuery.isFetching) {
      setShowMoreLoading(false);
    }
  }, [setShowMoreLoading, courseListingsQuery.isFetching]);

  const renderLoading = () => (
    <div
      data-testid="course-reserve-details-spinner"
      className={css.pageSpinner}
    >
      <Loading
        direction="vertical"
        size="large"
        loadingMessage={$t(sharedMessages.loadingMessage)}
      />
    </div>
  );

  const handleSortBySelect = useCallback(
    sortOption => {
      updateUrl(
        {
          sortField: sortOption.sortField,
          sortDirection: sortOption.sortDirection,
        },
        location.pathname
      );
    },
    [updateUrl, location.pathname]
  );

  const renderErrorCourses = () => (
    <InPageNotification
      className={css.errorNotification}
      icon={faExclamationTriangle}
      variant="error"
    >
      <InPageNotification.Header>
        <FormattedMessage {...sharedMessages.somethingWentWrong} />
      </InPageNotification.Header>
      <InPageNotification.Description>
        <FormattedMessage
          id="courseReserveDetails.courseReserveUnavailable"
          defaultMessage="The course reserve items cannot display at this time. {linkText}"
          values={{
            linkText: (
              <Button
                styleType="inline"
                aria-label={$t(sharedMessages.pleaseTryAgain)}
                onClick={() => courseListingsQuery.refetch()}
              >
                <FormattedMessage {...sharedMessages.pleaseTryAgain} />
              </Button>
            ),
          }}
        />
      </InPageNotification.Description>
    </InPageNotification>
  );

  const renderPageStatus = () => {
    if (courseListingsQuery.isFetching) {
      return (
        <div
          className={css.courseListingSpinner}
          data-testid="course-listing-spinner"
        >
          <IndeterminateSpinner level="component" />
        </div>
      );
    }

    if (courseListingsQuery.isError) {
      return renderErrorCourses();
    }

    if (courseListingsQuery.isSuccess && totalRecords === 0) {
      return (
        <div className={css.messageWrapper} data-testid="no-courses">
          <FontAwesomeIcon icon={faBooks} className={css.icon} />
          <FormattedMessage
            id="courseReserveDetails.noItems"
            defaultMessage="This course currently has no items."
          />
        </div>
      );
    }

    return null;
  };

  const renderCourses = () => (
    <ShowMorePagination
      uniqueName="courseReserveDetails"
      data-testid="courseReserveDetails"
      error={courseListingsQuery.isError}
      messages={getPageMessages(intl)}
      isLoading={isShowMoreLoading}
      totalRecords={totalRecords}
      recordsPerPage={RECORDS_PER_PAGE}
      pages={pages}
      onLoadPage={() => {
        setShowMoreLoading(true);

        return courseListingsQuery.isError
          ? courseListingsQuery.refetch()
          : updateState({ pageNumber: state.pageNumber + 1 });
      }}
    >
      {(records, currentPageNumber) => (
        <Page
          title={$t(sharedMessages.pageNumber, {
            pageNumber: currentPageNumber,
          })}
          key={currentPageNumber}
          number={currentPageNumber}
          uniqueName="courseReserveDetailsDivider"
        >
          {records.map((instance, index) => {
            const itemWithStatus = getItemWithStatus(
              instance.id,
              userItemStatuses
            );

            return (
              <RecordSummary
                testId="record-summary"
                key={instance.id}
                instance={instance}
                placement="results"
                itemStatusData={
                  itemWithStatus &&
                  getItemStatusData(itemWithStatus, intl, locale)
                }
                availability={availability[instance.id]?.summaryAvailability}
                isAvailabilityLoading={
                  currentPageNumber === pages.length - 1 &&
                  isAvailabilityLoading
                }
                links={<RecordLinks id={instance.id} title={instance.title} />}
                isLast={index === records.length - 1}
              />
            );
          })}
        </Page>
      )}
    </ShowMorePagination>
  );

  const renderContent = () => {
    if (courseQuery.isFetching) {
      return renderLoading();
    }

    if (courseQuery.isError) {
      return (
        <PageErrorMessage
          buttonText={$t(sharedMessages.refreshPage)}
          title={intl.$t(sharedMessages.pageUnavailable)}
          text={$t(sharedMessages.searchUnavailableText)}
          titleTag="h1"
          onClick={() => courseQuery.refetch()}
        />
      );
    }

    if (!isEmpty(courseReserve)) {
      const instructors =
        courseReserve.courseListingObject?.instructorObjects?.map(
          instructor => instructor.name
        );

      return (
        <div className="container">
          <Helmet>
            <title>
              {$t(
                {
                  id: 'courseReserveDetails.documentTitle',
                  defaultMessage:
                    '{title} - Course reserve details - EBSCO Locate',
                },
                { title: courseReserve.name }
              )}
            </title>
          </Helmet>
          <BackToRoute
            to={ROUTE.courseReserves}
            id="courseReserveDetails.backToCoursesReserves"
            defaultMessage="Courses reserves"
          />
          <CourseHero
            courseName={courseReserve.name}
            instructors={instructors}
            courseNumber={courseReserve.courseNumber}
            department={courseReserve.departmentObject?.name}
            description={courseReserve.description}
            location={courseReserve.courseListingObject?.locationObject?.name}
          />
          <div className={css.resultsPanel}>
            <span data-testid="courses-amount">
              <FormattedMessage
                {...sharedMessages.numberOfCourses}
                values={{ number: totalRecords }}
              />
            </span>
            <SortByDropdown
              options={sortOptions}
              sortField={state.sortField}
              sortDirection={state.sortDirection}
              onSelect={handleSortBySelect}
            />
          </div>
          {state.pageNumber === 1 && renderPageStatus()}
          {hasCourses && renderCourses()}
        </div>
      );
    }

    return null;
  };

  return <div className={css.wrapper}>{renderContent()}</div>;
};
