import { defineMessages } from 'react-intl';

export const navigationMessages = defineMessages({
  expandBrowseLinks: {
    id: 'navigation.expandBrowse',
    defaultMessage: 'Expand browse links',
  },
  collapseBrowseLinks: {
    id: 'navigation.collapseBrowse',
    defaultMessage: 'Collapse browse links',
  },
});
