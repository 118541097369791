import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Field, Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import {
  Button,
  createBemBlockBuilder,
  IndeterminateSpinner,
  InPageNotification,
} from '@ebsco-ui/ebsco-ui';

import { AuthPageWrapper, StepsBar, TextField } from '@app/components';
import { useResetPinStep1Mutation, useValidator } from '@app/hooks';
import { sharedMessages } from '@app/translations';

import '../../../components/AuthPageWrapper/AuthPageWrapper.scss';

const cnBem = createBemBlockBuilder(['authPageWrapper']);

export const EnterBarcodePage: FC = () => {
  const { $t } = useIntl();
  const { validateRequiredField } = useValidator();
  const resetPinStep1Mutation = useResetPinStep1Mutation();
  const successNotification = useMemo(
    () =>
      resetPinStep1Mutation.isSuccess
        ? {
            variant: InPageNotification.VARIANT.success,
            header: $t({
              id: 'resetPin.success',
              defaultMessage: 'Success.',
            }),
            description: $t({
              id: 'resetPin.emailWasSent',
              defaultMessage:
                'An email has been sent to you with a link to reset your PIN.',
            }),
          }
        : undefined,
    [resetPinStep1Mutation.isSuccess, $t]
  );
  const errorNotification = useMemo(
    () =>
      resetPinStep1Mutation.isError
        ? {
            variant: InPageNotification.VARIANT.error,
            header: $t(sharedMessages.somethingWentWrong),
            description: $t(sharedMessages.weAreNotAbleToProcessRequest),
          }
        : undefined,
    [resetPinStep1Mutation.isError, $t]
  );

  return (
    <>
      <Helmet>
        <title>
          {$t({
            id: 'resetPin.documentTitle',
            defaultMessage: 'Reset PIN - EBSCO Locate',
          })}
        </title>
      </Helmet>
      <AuthPageWrapper
        title={$t(sharedMessages.resetPin)}
        containerClassName={cnBem('__resetPinContainer')}
        notification={successNotification || errorNotification}
      >
        <Form
          render={({ handleSubmit, submitting, pristine, invalid }) => (
            <form
              className={cn(cnBem('__form'), cnBem('__resetPinForm'))}
              onSubmit={handleSubmit}
            >
              {resetPinStep1Mutation.isLoading && (
                <div data-testid="spinner">
                  <IndeterminateSpinner
                    level="component"
                    className={cnBem('__spinner')}
                  />
                </div>
              )}
              <StepsBar stepsAmount={3} currentStepIndex={0} />
              <div className={cn(cnBem('__row'), cnBem('__rowMessage'))}>
                {$t({
                  id: 'resetPin.enterBarcode',
                  defaultMessage:
                    'To reset your PIN please enter the barcode associated with your account.',
                })}
              </div>
              <div className={cnBem('__row')}>
                <Field
                  label={$t(sharedMessages.barcode)}
                  name="barcode"
                  type="text"
                  component={TextField}
                  validate={validateRequiredField}
                />
              </div>
              <div className={cnBem('__row')}>
                <Button
                  className={cnBem('__submitButton')}
                  styleType="solid"
                  type="submit"
                  disabled={invalid || submitting || pristine}
                >
                  {$t(sharedMessages.submit)}
                </Button>
              </div>
            </form>
          )}
          onSubmit={({ barcode }) => {
            resetPinStep1Mutation.mutate(barcode);
          }}
        />
      </AuthPageWrapper>
    </>
  );
};
