import { FC, ReactNode, Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { camelCase } from 'lodash';
import {
  faCheckCircle,
  faExclamationTriangle,
  faCalendarAlt,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { Icon } from '@app/components';
import { useAppContext } from '@app/contexts';
import { getFormattedDate } from '@app/utils';
import { sharedMessages } from '@app/translations';
import { PropsWithClassName } from '@app/constants';

import './AvailabilityStatus.scss';

export enum RecordAvailabilityStatus {
  available = 'Available',
  onLoan = 'On Loan',
  notAvailable = 'Not Available',
  error = 'error',
  unknown = 'Unknown',
}

const cnBem = createBemBlockBuilder(['availabilityStatus']);

const libraryHoldingStatusMessages = defineMessages({
  [RecordAvailabilityStatus.available]: {
    id: 'holdingStatus.available',
    defaultMessage: 'Available{colon, select, false {} true {: } other {}}',
  },
  [RecordAvailabilityStatus.onLoan]: {
    id: 'holdingStatus.onLoan',
    defaultMessage: 'On Loan',
  },
  [RecordAvailabilityStatus.notAvailable]: {
    id: 'holdingStatus.notAvailable',
    defaultMessage: 'Not Available{colon, select, false {} true {: } other {}}',
  },
  [RecordAvailabilityStatus.error]: {
    id: 'holdingStatus.error',
    defaultMessage:
      'Availability unknown{colon, select, false {} true {: } other {}}',
  },
});

const statusMap = {
  [RecordAvailabilityStatus.available]: {
    icon: faCheckCircle,
  },
  [RecordAvailabilityStatus.onLoan]: {
    icon: faCalendarAlt,
  },
  [RecordAvailabilityStatus.notAvailable]: {
    icon: faTimesCircle,
  },
  [RecordAvailabilityStatus.error]: {
    icon: faExclamationTriangle,
  },
};

interface AvailabilityStatusProps {
  status: RecordAvailabilityStatus;
  hasVolumes?: boolean;
  copiesRemaining?: {
    available: number;
    total: number;
  };
  dueDate?: string;
  StatusTag?: 'strong' | typeof Fragment;
}

export const AvailabilityStatus: FC<
  PropsWithClassName<AvailabilityStatusProps>
> = ({
  status,
  hasVolumes,
  copiesRemaining,
  dueDate,
  StatusTag = Fragment,
}) => {
  const { $t } = useIntl();
  const { locale } = useAppContext();

  const getStatusInfo = ({ info }: { info?: ReactNode } = {}) => (
    <>
      <StatusTag>
        {$t(libraryHoldingStatusMessages[status], {
          colon: Boolean(info),
        })}
      </StatusTag>
      {info}
    </>
  );

  const getStatusMessage = () => {
    const copiesRemainingMessage =
      copiesRemaining &&
      $t(
        {
          id: 'resultsList.copiesRemaining',
          defaultMessage:
            '{available} of {total} {hasVolumes, select, false {copies} true {volumes} other {}} remaining',
        },
        {
          available: copiesRemaining.available,
          total: copiesRemaining.total,
          hasVolumes,
        }
      );

    if (status === RecordAvailabilityStatus.available) {
      return getStatusInfo({ info: copiesRemainingMessage });
    }

    if (status === RecordAvailabilityStatus.onLoan) {
      return getStatusInfo();
    }

    if (status === RecordAvailabilityStatus.notAvailable) {
      const info =
        copiesRemainingMessage ||
        (dueDate &&
          $t(sharedMessages.dueDate, {
            date: getFormattedDate({
              date: dueDate,
              locale,
              shouldDisplayTime: false,
            }),
          }));

      return getStatusInfo({ info });
    }

    return getStatusInfo({
      info: $t({
        id: 'resultsList.unableHoldingsStatus',
        defaultMessage: 'Unable to load library holdings status.',
      }),
    });
  };

  return (
    <Icon
      icon={statusMap[status].icon}
      className={cnBem(`__icon--${camelCase(status)}`)}
    >
      <span data-testid="availability-status">{getStatusMessage()}</span>
    </Icon>
  );
};
