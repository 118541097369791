import { isEmpty } from 'lodash';

import { FACET, OPERATOR, PublicationYear } from '@app/search';

export const getPublicationYearCQL = (
  publicationYear: PublicationYear | undefined
): string => {
  if (!publicationYear || isEmpty(publicationYear)) {
    return '';
  }

  if (publicationYear.maxDate) {
    return `${FACET.PUBLICATION_YEAR}${OPERATOR.GREATER_THAN}${publicationYear.minDate} and ${FACET.PUBLICATION_YEAR}${OPERATOR.LESS_THAN}${publicationYear.maxDate}`;
  }

  return `${FACET.PUBLICATION_YEAR}${OPERATOR.GREATER_THAN}${publicationYear.minDate}`;
};
