import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { isEmpty, difference, mapValues } from 'lodash';
import queryString from 'query-string';

import { getCQLState } from '@app/search';
import { useSearchContext, useMappingsContext } from '@app/contexts';
import { axios } from '@app/utils';

import {
  getMappedFacetValues,
  getInitialFacets,
  FacetInstanceDto,
  FACET_LIMIT,
} from '..';

interface FacetRequestProps {
  isStatePristine: boolean;
  expandedFacets?: string[];
}

export const useFacetsRequest = ({
  isStatePristine,
  expandedFacets = [],
}: FacetRequestProps): FacetInstanceDto => {
  const { $t } = useIntl();

  const [facetGroups, setFacetGroups] = useState(getInitialFacets($t));
  const [expandedFacetsQuery, setExpandedFacetsQuery] = useState('');

  const { locations } = useMappingsContext();
  const {
    state: { option, query, publicationYear, facets: selectedFacets },
  } = useSearchContext();

  useEffect(() => {
    if (expandedFacets.length) {
      setExpandedFacetsQuery(
        expandedFacets.map(facet => `${facet}:${FACET_LIMIT}`).join(',')
      );
    }
  }, [expandedFacets]);

  const shouldGetFacets =
    (!isStatePristine || !isEmpty(selectedFacets)) &&
    Boolean(expandedFacetsQuery);

  const actualFacetsQuery = useQuery(
    [
      'facets',
      expandedFacetsQuery,
      selectedFacets,
      option,
      query,
      publicationYear,
    ],
    () => {
      return axios
        .get('/search/instances/facets', {
          params: {
            facet: expandedFacetsQuery,
            ...getCQLState({
              query,
              facets: selectedFacets,
              option,
              publicationYear,
              hasRequestedFacets: Boolean(expandedFacetsQuery),
            }),
          },
          paramsSerializer: queryString.stringify,
        })
        .then(response => response.data);
    },
    {
      enabled: shouldGetFacets,
    }
  );

  const getNullishFacets = (facetsForKey, facetKey) => {
    if (!selectedFacets) {
      return [];
    }

    return difference(
      selectedFacets[facetKey],
      facetsForKey?.map(
        facetValue => selectedFacets[facetKey]?.find(el => el === facetValue.id)
      )
    ).map(facetValue => ({ id: facetValue, totalRecords: 0 }));
  };

  useEffect(() => {
    if (actualFacetsQuery.isError) {
      setFacetGroups(
        previousFacetGroups =>
          mapValues(previousFacetGroups, (value, key) => {
            const isFacedExpanded = expandedFacets?.includes(key);
            const isFacedNullish = value.values === null;

            return {
              ...value,
              isError: isFacedExpanded ? isFacedNullish : false,
            };
          }) as FacetInstanceDto
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualFacetsQuery.isError]);

  useEffect(() => {
    if (actualFacetsQuery.data) {
      const updatedFacets = expandedFacets.reduce(
        (newFacets, facetKey) => {
          const facetValues =
            actualFacetsQuery.data.facets[facetKey]?.values || [];
          const nullishFacets = getNullishFacets(facetValues, facetKey);
          const mappedValues = getMappedFacetValues({
            $t,
            facetName: facetKey,
            values: [...nullishFacets, ...facetValues],
            locations,
          });

          newFacets[facetKey] = {
            ...newFacets[facetKey],
            values: mappedValues,
            isError: false,
          };

          return newFacets;
        },
        { ...facetGroups }
      );

      setFacetGroups(updatedFacets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualFacetsQuery.data]);

  return facetGroups;
};
