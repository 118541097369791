import { defineMessages } from 'react-intl';

export const noteTypesMessages = defineMessages({
  generalNote: {
    id: 'instanceNoteTypes.generalNote',
    defaultMessage: 'Notes',
  },
  summary: {
    id: 'instanceNoteTypes.summary',
    defaultMessage: 'Summary',
  },
  contentNote: {
    id: 'instanceNoteTypes.contentNote',
    defaultMessage: 'Content notes',
  },
  bibliographicNote: {
    id: 'instanceNoteTypes.bibliographicNote',
    defaultMessage: 'Bibliographic notes',
  },
  localNote: {
    id: 'instanceNoteTypes.localNote',
    defaultMessage: 'Local notes',
  },
});
