import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SearchAutocomplete } from '@ebsco-ui/ebsco-ui';

import { sharedMessages } from '@app/translations';

import css from './SearchBar.module.scss';

interface SearchBarProps {
  placeholder?: string;
  value?: string;
  onSearch: (searchQuery: string) => void;
}

export const SearchBar: FC<SearchBarProps> = ({
  placeholder = '',
  value = '',
  onSearch,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const { $t } = useIntl();

  const handleSearch = event => {
    if (event) {
      event.preventDefault();
    }

    onSearch(inputValue);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <form onSubmit={handleSearch}>
      <SearchAutocomplete
        items={[]}
        className={css.searchBar}
        messages={{
          autocompleteMessages: {
            input: {
              'aria-label': placeholder,
              placeholder,
            },
            clearButton: {
              'aria-label': $t(sharedMessages.clear),
            },
            menu: { 'aria-label': placeholder },
          },
        }}
        searchButtonProps={{
          messages: {
            'aria-label': $t(sharedMessages.search),
            title: $t(sharedMessages.search),
          },
          onClick: handleSearch,
        }}
        hasSearchButton
        value={inputValue}
        onChange={changes => {
          setInputValue(changes.inputValue ?? '');
        }}
      />
    </form>
  );
};
