import { FC } from 'react';
import cn from 'classnames';

import { Image } from '@app/components';
import { PropsWithClassName } from '@app/constants';

import { getInstanceDefaultCover } from './getInstanceDefaultCover';

import css from './CoverImageContainer.module.scss';

interface CoverImageContainerProps {
  src?: string;
  altText?: string;
  sourceTypes?: string;
  isLoading?: boolean;
  imageClassName?: string;
}

export const CoverImageContainer: FC<
  PropsWithClassName<CoverImageContainerProps>
> = ({
  sourceTypes,
  altText = '',
  src,
  isLoading,
  imageClassName,
  className,
}) => {
  const defaultInstanceCover = getInstanceDefaultCover(sourceTypes);

  return isLoading || src ? (
    <Image
      src={src}
      className={imageClassName}
      altText={altText}
      isLoading={isLoading}
    />
  ) : (
    <div className={cn(css.imageWrapper, className)}>
      <img
        data-testid="default-instance-image"
        src={defaultInstanceCover}
        alt={altText}
      />
    </div>
  );
};
