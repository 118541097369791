import { IntlShape } from 'react-intl';

import { FACET } from '@app/search';
import { sharedMessages } from '@app/translations';

import { FacetInstanceDto } from '../constants';

export const getInitialFacets = ($t: IntlShape['$t']): FacetInstanceDto => {
  return {
    [FACET.STATUS]: {
      label: $t({
        id: 'facets.status',
        defaultMessage: 'Status',
      }),
      values: null,
    },
    [FACET.FORMAT]: {
      label: $t({
        id: 'facets.format',
        defaultMessage: 'Format',
      }),
      values: null,
    },
    [FACET.LOCATION]: {
      label: $t(sharedMessages.location),
      values: null,
    },
    [FACET.PUBLISHER]: {
      label: $t({
        id: 'facets.publisher',
        defaultMessage: 'Publisher',
      }),
      values: null,
    },
    [FACET.LANGUAGE]: {
      label: $t({
        id: 'facets.language',
        defaultMessage: 'Language',
      }),
      values: null,
    },
    [FACET.SUBJECT]: {
      label: $t(sharedMessages.subject),
      values: null,
    },
  };
};
