import { useEffect, useState } from 'react';

import { setFocusOn } from '@app/utils';

interface PaginationItemFocusProps {
  pageNumber: number;
  totalPagesNumber?: number;
  selector?: string;
  instanceIdScrollTo?: string | null;
  shouldResetState?: boolean;
}

interface FocusedItemData {
  shouldBeFocused: boolean;
}

export const usePaginationItemFocus = ({
  selector = '[data-auto=page-divider]',
  totalPagesNumber = 0,
  pageNumber,
  instanceIdScrollTo,
  shouldResetState = false,
}: PaginationItemFocusProps): FocusedItemData => {
  const [shouldBeFocused, setShouldBeFocused] = useState(false);

  useEffect(() => {
    setShouldBeFocused(
      !instanceIdScrollTo && totalPagesNumber === pageNumber && pageNumber > 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, totalPagesNumber]);

  useEffect(() => {
    if (shouldBeFocused) {
      setFocusOn({ selector });

      if (shouldResetState) {
        setShouldBeFocused(false);
      }
    }
  }, [selector, shouldBeFocused, shouldResetState]);

  return { shouldBeFocused };
};
