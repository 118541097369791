import { UseMutationResult } from '@tanstack/react-query';
import { isObject, isString } from 'lodash';

import { HoldActionErrorDto } from '@app/hooks';

export const getErrorReason = (
  holdMutationResult: UseMutationResult<unknown, HoldActionErrorDto>
): string | undefined => {
  if (holdMutationResult.isError) {
    if (isObject(holdMutationResult.error.response.data)) {
      return holdMutationResult.error.response.data.errorMessage;
    }

    if (isString(holdMutationResult.error.response.data)) {
      return holdMutationResult.error.response.data;
    }
  }

  return undefined;
};
