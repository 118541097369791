import { FC } from 'react';

import css from './MetadataDetailsList.module.scss';

interface MetadataDetailsListProps {
  details: string[];
}

export const MetadataDetailsList: FC<MetadataDetailsListProps> = ({
  details,
}) => (
  <ul className={css.metadataDetailsList}>
    {details.map(detail => (
      <li key={detail}>{detail}</li>
    ))}
  </ul>
);
