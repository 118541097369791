import { Dispatch, SetStateAction } from 'react';

export enum BookShelfSortField {
  requestDate = 'requestDate',
  dueDate = 'dueDate',
  amount = 'amount',
}

export type BookshelfTab = 'holds' | 'checkouts' | 'fees';

export type SetTabsCounters = Dispatch<
  SetStateAction<Record<BookshelfTab, number>>
>;
