import { ChangeEvent, forwardRef } from 'react';
import { FormattedNumber } from 'react-intl';

import { Checkbox } from '@ebsco-ui/ebsco-ui';

import css from './FacetAttribute.module.scss';

interface FacetAttributeProps {
  isChecked: boolean;
  label: string;
  value: string;
  selectedCount: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FacetAttribute = forwardRef<HTMLInputElement, FacetAttributeProps>(
  ({ isChecked, label, value, onChange, selectedCount }, ref) => {
    const currentLabel = (
      <span className={css.label}>
        <span className={css.value} data-testid="facet-attribute-title">
          {label}
        </span>
        <span className={css.count} data-testid="facet-attribute-value">
          <FormattedNumber value={selectedCount} />
        </span>
      </span>
    );

    return (
      <div className={css.facetAttribute} data-testid="facet-attribute">
        <Checkbox
          label={currentLabel}
          checked={isChecked}
          value={value}
          className={css.checkbox}
          ref={ref}
          onChange={onChange}
        />
      </div>
    );
  }
);

FacetAttribute.displayName = 'FacetAttribute';
