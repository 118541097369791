import { FC } from 'react';
import { useIntl } from 'react-intl';
import { times } from 'lodash';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { sharedMessages } from '@app/translations';

import './GhostFacet.scss';

interface GhostFacetProps {
  onFocusChange: (isFocused: boolean) => void;
}

const cnBem = createBemBlockBuilder(['ghostFacet']);

export const GhostFacet: FC<GhostFacetProps> = ({ onFocusChange }) => {
  const { $t } = useIntl();
  const ghostRowCount = 6;

  return (
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      aria-label={$t(sharedMessages.loadingMessage)}
      data-testid="ghost-facet"
      onFocus={() => onFocusChange(true)}
      onBlur={() => onFocusChange(false)}
    >
      {times(ghostRowCount, index => (
        <div key={index} className={cnBem('__row')} />
      ))}
    </div>
  );
};
