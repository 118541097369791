import { defineMessages } from 'react-intl';

export const sharedMessages = defineMessages({
  advanced: {
    id: 'shared.advanced',
    defaultMessage: 'Advanced',
  },
  apply: {
    id: 'shared.apply',
    defaultMessage: 'Apply',
  },
  author: {
    id: 'shared.author',
    defaultMessage: 'Author',
  },
  cancel: {
    id: 'shared.cancel',
    defaultMessage: 'Cancel',
  },
  checkouts: {
    id: 'shared.checkouts',
    defaultMessage: 'Checkouts',
  },
  cite: {
    id: 'shared.cite',
    defaultMessage: 'Cite',
  },
  clear: {
    id: 'shared.clear',
    defaultMessage: 'Clear',
  },
  clearResults: {
    id: 'shared.clearResults',
    defaultMessage: 'Clear results',
  },
  close: {
    id: 'shared.close',
    defaultMessage: 'Close',
  },
  description: {
    id: 'shared.description',
    defaultMessage: 'Description',
  },
  endOfList: {
    id: 'resultsList.endOfList',
    defaultMessage: 'End of list',
  },
  error: {
    id: 'resultsList.error',
    defaultMessage: 'Sorry, no new results can be loaded. Please try again.',
  },
  from: {
    id: 'shared.from',
    defaultMessage: 'From',
  },
  holds: {
    id: 'shared.holds',
    defaultMessage: 'Holds',
  },
  isbn: {
    id: 'shared.isbn',
    defaultMessage: 'ISBN',
  },
  lccn: {
    id: 'shared.lccn',
    defaultMessage: 'LCCN',
  },
  issn: {
    id: 'shared.issn',
    defaultMessage: 'ISSN',
  },
  keyword: {
    id: 'shared.keyword',
    defaultMessage: 'Keyword',
  },
  loading: {
    id: 'resultsList.loading',
    defaultMessage: 'Loading results...',
  },
  loadingMessage: {
    id: 'shared.loading',
    defaultMessage: 'Loading',
  },
  more: {
    id: 'shared.more',
    defaultMessage: 'More',
  },
  no: {
    id: 'shared.no',
    defaultMessage: 'No',
  },
  numberOfItems: {
    id: 'shared.numberOfItems',
    defaultMessage: 'Number of items: {number}',
  },
  numberOfCourses: {
    id: 'shared.numberOfCourses',
    defaultMessage: 'Course results: {number}',
  },
  pageNumber: {
    id: 'shared.pageNumber',
    defaultMessage: 'Page {pageNumber}',
  },
  pageUnavailable: {
    id: 'shared.pageUnavailable',
    defaultMessage: 'Page unavailable',
  },
  pleaseTryAgain: {
    id: 'shared.pleaseTryAgain',
    defaultMessage: 'Please try again.',
  },
  plusMore: {
    id: 'shared.plusMore',
    defaultMessage: '+{amount} more',
  },
  refreshPage: {
    id: 'shared.refreshPage',
    defaultMessage: 'Refresh page',
  },
  results: {
    id: 'shared.results',
    defaultMessage: 'Results: {number}',
  },
  save: {
    id: 'shared.save',
    defaultMessage: 'Save',
  },
  unsave: {
    id: 'shared.unsave',
    defaultMessage: 'Unsave',
  },
  scrollBackward: {
    id: 'shared.scrollBackward',
    defaultMessage: 'Scroll backward',
  },
  scrollForward: {
    id: 'shared.scrollForward',
    defaultMessage: 'Scroll forward',
  },
  search: {
    id: 'shared.search',
    defaultMessage: 'Search',
  },
  searchInvalidText: {
    id: 'shared.searchInvalidText',
    defaultMessage:
      'Invalid search term or Boolean operator provided in query. Please change your search query and try again.',
  },
  searchUnavailableText: {
    id: 'shared.searchUnavailableText',
    defaultMessage: 'Something went wrong. Try refreshing the page.',
  },
  share: {
    id: 'shared.share',
    defaultMessage: 'Share',
  },
  showLess: {
    id: 'shared.showLess',
    defaultMessage: 'Show less',
  },
  showMoreButtonTitle: {
    id: 'resultsList.showMoreButtonTitle',
    defaultMessage: 'Show more results',
  },
  signIn: {
    id: 'shared.signIn',
    defaultMessage: 'Sign in',
  },
  somethingWentWrong: {
    id: 'shared.somethingWentWrong',
    defaultMessage: 'Something went wrong.',
  },
  somethingWentWrongPleaseTryAgain: {
    id: 'shared.somethingWentWrongPleaseTryAgain',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  somethingWentWrongContactAdmin: {
    id: 'shared.somethingWentWrongContactAdmin',
    defaultMessage:
      'Something went wrong. Please try again or contact your Administrator.',
  },
  weAreNotAbleToProcessRequest: {
    id: 'shared.weAreNotAbleToProcessRequest',
    defaultMessage:
      'We are not able to process your request. Please try again later.',
  },
  subject: {
    id: 'shared.subject',
    defaultMessage: 'Subject',
  },
  title: {
    id: 'shared.title',
    defaultMessage: 'Title',
  },
  to: {
    id: 'shared.to',
    defaultMessage: 'To',
  },
  yes: {
    id: 'shared.yes',
    defaultMessage: 'Yes',
  },
  advancedSearch: {
    id: 'shared.advancedSearch',
    defaultMessage: 'Advanced search',
  },
  map: {
    id: 'shared.map',
    defaultMessage: 'Map',
  },
  dueDate: {
    id: 'shared.dueDate',
    defaultMessage: 'Due {date}',
  },
  staffView: {
    id: 'shared.staffView',
    defaultMessage: 'Staff view',
  },
  location: {
    id: 'shared.location',
    defaultMessage: 'Location',
  },
  instructor: {
    id: 'shared.instructor',
    defaultMessage: 'Instructor',
  },
  department: {
    id: 'shared.department',
    defaultMessage: 'Department',
  },
  preferences: {
    id: 'shared.preferences',
    defaultMessage: 'Preferences',
  },
  submit: {
    id: 'shared.submit',
    defaultMessage: 'Submit',
  },
  required: {
    id: 'shared.required',
    defaultMessage: 'Required',
  },
  welcomeBack: {
    id: 'shared.welcomeBack',
    defaultMessage: 'Welcome back',
  },
  barcode: {
    id: 'shared.barcode',
    defaultMessage: 'Library Number or Barcode',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
  },
  pin: {
    id: 'shared.pin',
    defaultMessage: 'PIN',
  },
  resetPin: {
    id: 'shared.resetPin',
    defaultMessage: 'Reset PIN',
  },
  name: {
    id: 'shared.name',
    defaultMessage: 'Last Name',
  },
  externalLink: {
    id: 'shared.externalLink',
    defaultMessage: '(link opens in new tab)',
  },
});
