import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { axios } from '@app/utils';

const minimumImageSize = 2000;

export const useCoverArtFetch = (
  isbns: string[] = []
): UseQueryResult<string> => {
  return useQuery(
    ['coverArt', isbns],
    () =>
      axios
        .get<Blob>('/cover-art/images', {
          params: {
            isbn: isbns[0],
            size: 'L',
            returnStatus: 'E',
          },
          responseType: 'blob',
          headers: {
            Accept: '*/*',
          },
        })
        .then(response => response.data)
        .then(imageBlob => {
          if (imageBlob.size > minimumImageSize) {
            const urlCreator = window.URL || window.webkitURL;

            return urlCreator.createObjectURL(imageBlob);
          }

          return null;
        }),
    { enabled: Boolean(isbns?.[0]), staleTime: 1000 * 60 * 60 * 24 }
  );
};
