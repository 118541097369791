import { IntlShape } from 'react-intl';
import { find } from 'lodash';

import { langMessages } from '@app/translations';

import { MappedFacetValue } from '../constants';
import languages from '../languages.json';
import { UnmappedFacetValue } from '.';

// Given a two or three-character language code, return a localized language name string
export const formatLanguageName = (
  code: string,
  $t: IntlShape['$t']
): string => {
  const language = find(
    languages,
    entry => entry.alpha3 === code || entry.alpha2 === code
  );

  if (language === undefined) {
    return $t(langMessages.undefined);
  }

  if (langMessages[language.alpha3]) {
    return $t(langMessages[language.alpha3]);
  }

  return language.name;
};

export const getLanguageOptions = (
  $t: IntlShape['$t'],
  langs: UnmappedFacetValue[] = []
): MappedFacetValue[] =>
  langs.map(({ id, totalRecords }) => ({
    label: formatLanguageName(id, $t),
    value: id,
    count: totalRecords,
  }));
