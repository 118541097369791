import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import {
  faExclamationTriangle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Loading,
  Modal,
  Toast,
  createBemBlockBuilder,
} from '@ebsco-ui/ebsco-ui';

import { getErrorReason } from '@app/components';
import { useRemoveHoldMutation } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { IS_TEST_ENV, MODAL_PLACEMENT } from '@app/constants';

import { removeHoldToastMessages } from './removeHoldToastMessages';

import '../Modal/Modal.scss';
import modalHeaderCss from '../ModalHeader/ModalHeader.module.scss';
import css from './RemoveHoldModal.module.scss';

interface RemoveHoldModalProps {
  isOpen: boolean;
  recordId: string;
  onModalClose: () => void;
  onError: ({ isError, errorMessage }) => void;
}

const cnBem = createBemBlockBuilder(['modal']);

export const RemoveHoldModal: FC<RemoveHoldModalProps> = ({
  isOpen,
  recordId,
  onModalClose,
  onError,
}) => {
  const queryClient = useQueryClient();
  const { $t } = useIntl();
  const removeHoldMutation = useRemoveHoldMutation();

  const removeHoldTitle = useMemo(
    () =>
      $t({
        id: 'removeHoldModal.removeHold',
        defaultMessage: 'Remove hold',
      }),
    [$t]
  );

  const removeHoldToastIcons = useMemo(
    () => ({
      success: faCheckCircle,
      error: faExclamationTriangle,
    }),
    []
  );

  useEffect(() => {
    const status = removeHoldMutation.status;

    if (status === 'success') {
      Toast(
        <span data-testid="remove-hold-toast-message">
          <FormattedMessage {...removeHoldToastMessages[status]} />
        </span>,
        {
          icon: removeHoldToastIcons[status],
          type: status,
          closeButton: false,
          closeButtonTitle: '',
        }
      );

      onModalClose();

      queryClient.refetchQueries(['bookshelfPage'], { type: 'active' });
      queryClient.refetchQueries(['userItemStatuses'], { type: 'active' });
    }

    if (status === 'error') {
      onError({
        isError: true,
        errorMessage: getErrorReason(removeHoldMutation),
      });
      onModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryClient,
    removeHoldMutation.status,
    removeHoldToastIcons,
    onModalClose,
  ]);

  const getModalHeader = () => {
    return (
      <>
        <h2 className={modalHeaderCss.headerTitle}>{removeHoldTitle}</h2>
        <div className={modalHeaderCss.headerBody}>
          <p
            data-testid="remove-hold-message"
            className={css.removeHoldMessage}
          >
            <FormattedMessage
              id="removeHoldModal.removeHoldMessage"
              defaultMessage="Are you sure you want to remove this hold? This action cannot be undone."
            />
          </p>
        </div>
      </>
    );
  };

  const getModalFooter = () => {
    return (
      <>
        <Button
          title={$t(sharedMessages.cancel)}
          styleType="text"
          onClick={onModalClose}
        >
          {$t(sharedMessages.cancel)}
        </Button>
        <Button
          title={$t({
            id: 'removeHoldModal.remove',
            defaultMessage: 'Remove',
          })}
          styleType="default"
          onClick={() => {
            removeHoldMutation.mutate({
              holdId: recordId,
            });
          }}
        >
          <FormattedMessage
            id="removeHoldModal.remove"
            defaultMessage="Remove"
          />
        </Button>
      </>
    );
  };

  return (
    <Modal
      disableAnimations={IS_TEST_ENV}
      isOpen={isOpen}
      modalTitle={removeHoldTitle}
      appElement={MODAL_PLACEMENT}
      ariaHideApp={!IS_TEST_ENV}
      className={cn(cnBem(), cnBem('--remove'))}
      onClose={onModalClose}
    >
      <div
        data-testid="remove-hold-modal"
        className={cn({
          [cnBem('__disabledScroll')]: removeHoldMutation.isLoading,
        })}
      >
        <Modal.Header
          closeButtonProps={{
            title: $t(sharedMessages.close),
            onClick: onModalClose,
          }}
        >
          {getModalHeader()}
        </Modal.Header>
        <Modal.Footer>{getModalFooter()}</Modal.Footer>
        {removeHoldMutation.isLoading && (
          <Loading
            size="large"
            loadingMessage={$t({
              id: 'removeHoldModal.removingHold',
              defaultMessage: 'Removing hold...',
            })}
          />
        )}
      </div>
    </Modal>
  );
};
