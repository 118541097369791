import { FC, Fragment, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { chunk } from 'lodash';

import {
  Button,
  ShowMore,
  ShowMorePagination,
  createBemBlockBuilder,
} from '@ebsco-ui/ebsco-ui';

import { ShowMoreButton } from '@app/components';
import { courseReservesMessages } from '@app/pages/CourseReservesPage/courseReservesMessages';
import {
  useDepartmentsInstructorsFetch,
  usePaginationItemFocus,
  useQueryParams,
} from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { getPageMessages, sharedMessages } from '@app/translations';
import {
  FOCUSED_ELEMENT_SELECTOR,
  RECORDS_PER_PAGE,
  ROUTE,
} from '@app/constants';

import './BrowseBy.scss';

export type BrowseByField = 'departments' | 'instructors';

interface BrowseByProps {
  fieldName: BrowseByField;
}

const departmentsListLimit = 10;
const instructorsListLimit = RECORDS_PER_PAGE;
const cnBem = createBemBlockBuilder(['browseBy']);

export const BrowseBy: FC<BrowseByProps> = ({ fieldName }) => {
  const [instructorsPageNumber, setInstructorsPageNumber] = useState(1);
  const queryParams = useQueryParams();
  const resource = useDepartmentsInstructorsFetch(fieldName);
  const intl = useIntl();
  const { $t } = intl;
  const { browseByDepartment, browseByInstructor } = courseReservesMessages;
  const { shouldBeFocused } = usePaginationItemFocus({
    pageNumber: instructorsPageNumber,
    totalPagesNumber: instructorsPageNumber,
    selector: FOCUSED_ELEMENT_SELECTOR,
    shouldResetState: true,
  });

  const instructorsColumns = useMemo(
    () => [
      {
        label: 'Name',
        formatter: instructor => instructor.name,
      },
    ],
    []
  );

  const getSearchPath = search => {
    return generateRouteParams({
      pathname: ROUTE.courseReserves,
      query: {
        ...queryParams,
        search,
      },
    });
  };

  const getInstructorsPages = instructors => {
    const chunks = chunk(instructors.data, instructorsListLimit);

    return chunks.slice(0, instructorsPageNumber);
  };

  const renderInstructors = instructors => {
    const pages = getInstructorsPages(instructors);

    return (
      <section className={cnBem('__instructorsWrapper')}>
        <h2 className={cnBem('__title')}>
          <FormattedMessage {...browseByInstructor} />
        </h2>
        <div
          role="grid"
          aria-label={$t(browseByInstructor)}
          aria-colcount={instructorsColumns.length}
        >
          <ShowMorePagination
            pagesContainerTag="div"
            uniqueName="instructors"
            className={cnBem('__instructors')}
            error={undefined}
            messages={getPageMessages(intl, {
              showMoreButtonTitle: $t(sharedMessages.showMoreButtonTitle),
            })}
            isLoading={false}
            totalRecords={instructors.data.length}
            recordsPerPage={instructorsListLimit}
            pages={pages}
            onLoadPage={() =>
              setInstructorsPageNumber(prevState => prevState + 1)
            }
          >
            {(records, currentPageNumber) => (
              <Fragment key={currentPageNumber}>
                {currentPageNumber === 0 && (
                  <div role="rowgroup" className={cnBem('__instructorsHeader')}>
                    <div role="row">
                      {instructorsColumns.map(column => (
                        <div role="columnheader" key={column.label}>
                          {column.label}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {records.map((record, index) => (
                  <div role="rowgroup" key={record.id}>
                    <div
                      role="row"
                      data-testid="row"
                      aria-rowindex={
                        currentPageNumber * instructorsListLimit + index + 1
                      }
                    >
                      {instructorsColumns.map(
                        (column, columnIndex) =>
                          column && (
                            <div
                              role="cell"
                              className={cnBem('__instructorsCell')}
                              key={columnIndex}
                            >
                              <Link
                                to={getSearchPath(record.name)}
                                className="underlinedLink"
                                {...(shouldBeFocused &&
                                  !index && {
                                    'data-focus': 'focusedNode',
                                  })}
                              >
                                {column.formatter(record)}
                              </Link>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
          </ShowMorePagination>
        </div>
      </section>
    );
  };

  const renderDepartments = departments => {
    return (
      <section className={cnBem('__departments')}>
        <h2 className={cnBem('__title')}>
          <FormattedMessage {...browseByDepartment} />
        </h2>
        <ShowMore limit={departmentsListLimit}>
          <ul data-testid="departments-list">
            <ShowMore.Items>
              {departments.data.map(department => (
                <li
                  className={cnBem('__departmentsItemButton')}
                  key={department.id}
                >
                  <Link to={getSearchPath(department.name)}>
                    <Button styleType="pill" tabIndex={-1}>
                      {department.name}
                    </Button>
                  </Link>
                </li>
              ))}
            </ShowMore.Items>
            <li className={cnBem('__departmentsItemButton')}>
              <ShowMoreButton
                showMoreLimit={departmentsListLimit}
                dataLength={departments.data.length}
              />
            </li>
          </ul>
        </ShowMore>
      </section>
    );
  };

  const renderMap = {
    departments: renderDepartments,
    instructors: renderInstructors,
  };

  const renderBrowseBy = () => {
    if (!resource?.data || queryParams?.search) {
      return null;
    }

    return renderMap[fieldName](resource);
  };

  return renderBrowseBy();
};
