import { defineMessages } from 'react-intl';

export const dateRangeMessages = defineMessages({
  enterBothFields: {
    id: 'dateRange.enterBothFields',
    defaultMessage: 'Please enter a date in both fields',
  },
  enterCorrectDateFormat: {
    id: 'dateRange.enterCorrectDateFormat',
    defaultMessage: 'Please enter YYYY',
  },
  enterCorrectDateRange: {
    id: 'dateRange.enterCorrectDateRange',
    defaultMessage:
      "Please enter a 'from' date that is earlier than the 'to' date",
  },
});
