import { FC, ReactChild } from 'react';

import { InPageNotification } from '@ebsco-ui/ebsco-ui';

import { PropsWithClassName } from '@app/constants';

export type NotificationProps = {
  variant: keyof typeof InPageNotification.VARIANT;
  header?: ReactChild;
  description?: ReactChild;
};

export const Notification: FC<PropsWithClassName<NotificationProps>> = ({
  className,
  variant,
  header,
  description,
}) => (
  <InPageNotification className={className} variant={variant}>
    {(header as ReactChild) && (
      <InPageNotification.Header>{header}</InPageNotification.Header>
    )}
    {(description as ReactChild) && (
      <InPageNotification.Description>
        {description}
      </InPageNotification.Description>
    )}
  </InPageNotification>
);
