import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { sharedMessages } from '@app/translations';
import { NOTIFICATION_STATUS } from '@app/constants';

export const getErrorNotificationProps = ({
  title,
  message,
}: {
  title: ReactElement;
  message: string;
}): {
  type: NOTIFICATION_STATUS;
  header: ReactElement;
  children: ReactElement;
} => ({
  type: NOTIFICATION_STATUS.error,
  header: <FormattedMessage {...sharedMessages.somethingWentWrong} />,
  children: (
    <span data-testid="error-message">
      {title} {message}
    </span>
  ),
});
