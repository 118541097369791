import { chunk, compact, invert, isArray, isEmpty } from 'lodash';

import {
  BOOLEAN_CHIP,
  FIELDS_SEARCH_MAP,
  getModalValuesObject,
  ModalValues,
} from '@app/components/AdvancedSearchModal';
import { SEARCH_OPTION } from '@app/search';

interface AdvancedSearchQueryUtils {
  createAdvancedSearchQuery: (values: ModalValues) => string;
  parseAdvancedSearchQuery: (query: string) => ModalValues;
}

export const useAdvancedSearchQuery = (): AdvancedSearchQueryUtils => {
  const booleanOperatorsRegex = '( AND | OR | NOT )';
  const optionsRegex = Object.values(FIELDS_SEARCH_MAP).join('|');
  const regex = new RegExp(`${booleanOperatorsRegex}(?=${optionsRegex})`, 'g');

  const removeQuotes = query => query.replace(/['"]+/g, '');

  const createAdvancedSearchQuery = (values: ModalValues): string => {
    return Object.values(values).reduce((queryString, currentRow) => {
      const shouldPrependChip =
        !isEmpty(queryString) && Boolean(currentRow.booleanChip);
      const chip = shouldPrependChip
        ? ` ${currentRow.booleanChip?.toUpperCase()} `
        : '';

      const currentRowString = currentRow.value
        ? `${chip}${FIELDS_SEARCH_MAP[currentRow.searchOption]}"${removeQuotes(
            currentRow.value
          )}"`
        : '';

      return `${queryString}${currentRowString}`;
    }, '');
  };

  const parseOptionAndQuery = (optionWithQuery: string) => {
    const isQueryStringValid = new RegExp(`^(${optionsRegex})`).test(
      optionWithQuery
    );

    if (!isQueryStringValid) {
      return {
        value: optionWithQuery,
        searchOption: SEARCH_OPTION.keyword,
      };
    }

    const [option, ...query] = compact(
      optionWithQuery.split(new RegExp(`(${optionsRegex})`))
    );

    return {
      value: removeQuotes(query?.join('') || query),
      searchOption: invert(FIELDS_SEARCH_MAP)[option] as SEARCH_OPTION,
    };
  };

  const getModalValuesWithInvalidQuery = (query): ModalValues => {
    const modalValues = getModalValuesObject();

    modalValues[0].value = query;

    return modalValues;
  };

  const parseAdvancedSearchQuery = (query: string): ModalValues => {
    const [firstRow, ...rowsWithChips] = query.split(regex);
    const groupedValues = [firstRow, ...chunk(rowsWithChips, 2)];
    let isQueryInvalid = false;

    const modalValues = groupedValues.reduce(
      (values: ModalValues, currentRow, index) => {
        const isRowWithChip = isArray(currentRow);
        const optionAndQuery = isRowWithChip ? currentRow[1] : currentRow;

        try {
          values[index] = {
            ...parseOptionAndQuery(optionAndQuery),
            ...(isRowWithChip && {
              booleanChip: currentRow[0].trim().toLowerCase() as BOOLEAN_CHIP,
            }),
          };
        } catch (error) {
          isQueryInvalid = true;
        }

        return values;
      },
      getModalValuesObject()
    );

    return isQueryInvalid ? getModalValuesWithInvalidQuery(query) : modalValues;
  };

  return {
    createAdvancedSearchQuery,
    parseAdvancedSearchQuery,
  };
};
