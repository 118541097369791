import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from '@app/components';
import { PropsWithClassName } from '@app/constants';

interface BackToRouteProps {
  to: string;
  id: string;
  defaultMessage: string;
}

export const BackToRoute: FC<PropsWithClassName<BackToRouteProps>> = ({
  defaultMessage,
  id,
  to,
  className,
}) => {
  return (
    <div className={className}>
      <Icon icon={faArrowLeft} />
      <FormattedMessage id={id} defaultMessage={defaultMessage}>
        {message => (
          <Link
            data-testid="back-to-route-link"
            className="emphasizedLink"
            to={to}
          >
            {message[0]}
          </Link>
        )}
      </FormattedMessage>
    </div>
  );
};
