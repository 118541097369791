import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import cn from 'classnames';
import {
  faExpandArrows,
  faMinusCircle,
  faPlusCircle,
  faPrint,
} from '@fortawesome/pro-solid-svg-icons';

import {
  Button,
  createBemBlockBuilder,
  IndeterminateSpinner,
} from '@ebsco-ui/ebsco-ui';

import { ExternalLink, Icon } from '@app/components';
import { sharedMessages } from '@app/translations';
import { BREAKPOINT } from '@app/constants';

import '../StackMapModal.scss';

interface ZoomMapProps {
  imgSrc: string;
  isImageLoaded: boolean;
  printMapUrl: string;
  onImageLoad: () => void;
}

const cnBem = createBemBlockBuilder(['stackMapModal']);

export const ZoomMap: FC<ZoomMapProps> = ({
  imgSrc,
  isImageLoaded,
  printMapUrl,
  onImageLoad,
}) => {
  const { $t } = useIntl();
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINT.SCREEN.TABLET_SM - 1}px)`,
  });
  const tools = [
    {
      name: $t({
        id: 'stackMap.zoomIn',
        defaultMessage: 'Zoom in',
      }),
      icon: faPlusCircle,
      key: 'zoomIn',
    },
    {
      name: $t({
        id: 'stackMap.zoomOut',
        defaultMessage: 'Zoom out',
      }),
      icon: faMinusCircle,
      key: 'zoomOut',
    },
    {
      name: $t({
        id: 'stackMap.entireMap',
        defaultMessage: 'Entire map',
      }),
      icon: faExpandArrows,
      key: 'resetTransform',
    },
    {
      name: $t({
        id: 'stackMap.print',
        defaultMessage: 'Print',
      }),
      icon: faPrint,
      key: 'print',
      onClick: () => window.open(printMapUrl),
    },
  ];
  const getStackMapCopyright = () => (
    <div className={cnBem('__poweredBy')}>
      {$t(
        {
          id: 'stackMap.poweredByStackMap',
          defaultMessage: 'Powered by <a>StackMap.com</a>',
        },
        {
          a: chunks => (
            <ExternalLink href="https://www.stackmap.com/">
              {chunks}
            </ExternalLink>
          ),
        }
      )}
    </div>
  );

  return (
    <TransformWrapper
      wheel={{
        wheelDisabled: true,
      }}
    >
      {zoomProps => (
        <>
          <div className={cnBem('__row')}>
            <div className={cnBem('__toolsWrapper')}>
              {tools.map(tool => (
                <Button
                  styleType="flat-icon"
                  className={cnBem('__tool')}
                  key={tool.name}
                  onClick={() => (zoomProps[tool.key] || tool.onClick)()}
                >
                  <Icon icon={tool.icon}>{tool.name}</Icon>
                </Button>
              ))}
            </div>
            {!isMobile && getStackMapCopyright()}
          </div>
          <div
            className={cn(cnBem('__mapWrapper'), {
              [cnBem('__hiddenMap')]: !isImageLoaded,
            })}
          >
            <TransformComponent>
              <img
                src={imgSrc}
                alt={$t(sharedMessages.map)}
                className={cnBem('__map')}
                aria-hidden={!isImageLoaded}
                onLoad={onImageLoad}
              />
            </TransformComponent>
          </div>
          {!isImageLoaded && (
            <div
              className={cn(cnBem('__mapWrapper'), cnBem('__spinner'))}
              data-testid="stackMapSpinner"
            >
              <IndeterminateSpinner level="component" />
            </div>
          )}
          {isMobile && getStackMapCopyright()}
        </>
      )}
    </TransformWrapper>
  );
};
