import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { ExternalLink } from '@app/components';
import { useBranding } from '@app/hooks';

import { footerLinksMessages } from './footerLinksMessages';

import './Footer.scss';

const cnBem = createBemBlockBuilder(['footer']);

export const footerLinks = [
  {
    text: footerLinksMessages.help,
    path: 'https://connect.ebsco.com/s/article/Locate-Quick-Start-Guide?language=en_US',
  },
  {
    text: footerLinksMessages.disclaimer,
    path: 'https://www.ebsco.com/license-agreement',
  },
  {
    text: footerLinksMessages.privacyPolicy,
    path: 'https://www.ebsco.com/company/privacy-policy/',
  },
  {
    text: footerLinksMessages.termsOfUse,
    path: 'https://www.ebsco.com/terms-of-use/',
  },
];

export const Footer: FC = () => {
  const { $t } = useIntl();
  const {
    copyrightNotice = $t(
      {
        id: 'footer.copyright',
        defaultMessage: '{year} EBSCO Industries, Inc. All rights reserved',
      },
      { year: new Date().getFullYear() }
    ),
  } = useBranding();

  return (
    <footer className={cnBem()}>
      <div className={cn(cnBem('__container'), 'container')}>
        <nav
          className={cnBem('__navigation')}
          aria-label={$t({
            id: 'footer.footerLinks',
            defaultMessage: 'Footer links',
          })}
        >
          <ul>
            {footerLinks.map(({ text, path }) => {
              const translatedText = $t(text);

              return (
                <li key={translatedText}>
                  <ExternalLink href={path}>{translatedText}</ExternalLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <p className={cnBem('__copyright')} data-testid="copyright">
          {copyrightNotice}
        </p>
        {process.env.REACT_APP_VERSION && (
          <p className={cnBem('__appVersion')} data-testid="app-version">
            {$t(
              {
                id: 'appVersion',
                defaultMessage: 'Version {version}',
              },
              { version: process.env.REACT_APP_VERSION }
            )}
          </p>
        )}
      </div>
    </footer>
  );
};
