import { defineMessages } from 'react-intl';

export const dateFiltersMessages = defineMessages({
  allTime: {
    id: 'dateFilters.allTime',
    defaultMessage: 'All time',
  },
  pastYear: {
    id: 'dateFilters.lastYear',
    defaultMessage: 'Past 12 months',
  },
  pastFiveYears: {
    id: 'dateFilters.lastFiveYears',
    defaultMessage: 'Past 5 years',
  },
  pastTenYears: {
    id: 'dateFilters.lastTenYears',
    defaultMessage: 'Past 10 years',
  },
  custom: {
    id: 'dateFilters.custom',
    defaultMessage: 'Custom range',
  },
  range: {
    id: 'dateFilters.range',
    defaultMessage: '{minYear}–{maxYear}',
  },
});
