export interface RecordsData<T> {
  pageNumber: number;
  setPages: (newState: T[][] | ((prevState: T[][]) => T[][])) => void;
  records?: T[];
}

export const setPageRecords = <T>({
  records = [],
  pageNumber,
  setPages,
}: RecordsData<T>): void => {
  if (pageNumber === 1) {
    setPages([records]);
  } else {
    setPages(prevState => {
      const newPages = Array.from(prevState);

      newPages[pageNumber - 1] = records;

      return newPages;
    });
  }
};
