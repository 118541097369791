import { FC, useCallback, useState } from 'react';
import { UseSelectStateChange } from 'downshift';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import { Button, Dropdown as EbscoDropdown } from '@ebsco-ui/ebsco-ui';

import { ArrayElement } from '@app/utils';

type EbscoDropdownProps = Omit<Parameters<typeof EbscoDropdown>[0], 'children'>;

export type DropdownItem = ArrayElement<EbscoDropdownProps['items']>;

type DropdownProps = EbscoDropdownProps & {
  id: string;
  selectedItem: DropdownItem;
  label?: string;
  hideLabel?: boolean;
  onSelectedItemChange?: ({
    selectedItem,
  }: UseSelectStateChange<DropdownItem>) => void;
};

export const Dropdown: FC<DropdownProps> = ({
  id,
  items,
  className,
  label,
  hideLabel,
  selectedItem,
  styleType,
  onSelectedItemChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onIsOpenChange = changes => {
    setIsDropdownOpen(changes.isOpen);
  };

  const Toggle = useCallback(
    ({ getToggleButtonProps, ...rest }) => (
      <Button type="button" {...getToggleButtonProps(rest)}>
        <EbscoDropdown.Icon icon={selectedItem?.icon as IconProp} />
        <span>{selectedItem?.label}</span>
        <EbscoDropdown.Icon icon={faAngleDown} />
      </Button>
    ),
    [selectedItem?.icon, selectedItem?.label]
  );

  return (
    <EbscoDropdown
      items={items}
      className={className}
      hideLabel={hideLabel}
      downshiftOptions={{
        selectedItem: items.find(item => item.label === selectedItem?.label),
        onSelectedItemChange,
        onIsOpenChange,
      }}
      styleType={styleType}
    >
      <EbscoDropdown.Context>
        {({ getToggleButtonProps }) => (
          <>
            <div className="dropdown-control" id={id}>
              {label && <EbscoDropdown.Label>{label}</EbscoDropdown.Label>}
              {selectedItem?.icon ? (
                <Toggle getToggleButtonProps={getToggleButtonProps} />
              ) : (
                <EbscoDropdown.Toggle />
              )}
            </div>
            <EbscoDropdown.Menu
              aria-labelledby={id}
              aria-hidden={!isDropdownOpen}
            >
              {items.map((item, index) => (
                <EbscoDropdown.Item
                  key={`${item.label}-${index}`}
                  item={item}
                  index={index}
                >
                  {item.icon && <EbscoDropdown.Icon icon={item.icon} />}
                  {item.label}
                </EbscoDropdown.Item>
              ))}
            </EbscoDropdown.Menu>
          </>
        )}
      </EbscoDropdown.Context>
    </EbscoDropdown>
  );
};
