import { FC } from 'react';

import { createBemBlockBuilder, Loading } from '@ebsco-ui/ebsco-ui';

import {
  AvailabilityStatus,
  RecordAvailabilityStatus,
  useRecordSummaryContext,
} from '@app/components';
import { useMappingsContext } from '@app/contexts';

import '../RecordSummary/RecordSummary.scss';

const cnBem = createBemBlockBuilder(['recordSummary']);

export const RecordSummaryAvailability: FC = () => {
  const {
    availability,
    itemStatusData,
    availabilityLoadingMessage,
    isAvailabilityLoadingForTheFirstTime,
    isMobileBookshelf,
    getItemStatusPanel,
  } = useRecordSummaryContext();
  const { areUserItemStatusesLoading } = useMappingsContext();

  const getAvailabilityContent = () => {
    if (!availability?.status) {
      return null;
    }

    const isError = availability.status === RecordAvailabilityStatus.error;

    if (isError) {
      return (
        <AvailabilityStatus
          status={RecordAvailabilityStatus.error}
          StatusTag="strong"
        />
      );
    }

    const hasNoHoldings = availability?.copiesRemaining?.total === 0;
    const shouldDisplayAvailability =
      availability && !hasNoHoldings && availability.isRTACApplicable;

    return (
      shouldDisplayAvailability && (
        <AvailabilityStatus
          status={availability.status}
          StatusTag="strong"
          hasVolumes={availability.hasVolumes}
          copiesRemaining={availability.copiesRemaining}
        />
      )
    );
  };

  const hasNoData = !availability?.status && !itemStatusData;
  const shouldDisplaySpinner =
    hasNoData ||
    isAvailabilityLoadingForTheFirstTime ||
    areUserItemStatusesLoading;

  if (shouldDisplaySpinner) {
    return (
      <div data-testid="spinner" className={cnBem('__availabilitySpinner')}>
        <Loading
          direction="horizontal"
          size="small"
          loadingMessage={availabilityLoadingMessage as string}
        />
      </div>
    );
  }

  return (
    <>
      {!itemStatusData && getAvailabilityContent()}
      {itemStatusData && !isMobileBookshelf && getItemStatusPanel()}
    </>
  );
};
