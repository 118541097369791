import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { isFinite, toNumber } from 'lodash';

import { sharedMessages } from '@app/translations';

interface ValidatorResult {
  validateRequiredField: (value: string) => undefined | string;
  validateDigitsField: (value: string, length: number) => boolean;
}

export const useValidator = (): ValidatorResult => {
  const { $t } = useIntl();

  const validateRequiredField = useCallback(
    (value: string) => (value ? undefined : $t(sharedMessages.required)),
    [$t]
  );

  const validateDigitsField = useCallback(
    (value, length: number) =>
      value?.length === length && isFinite(toNumber(value)),
    []
  );

  return {
    validateRequiredField,
    validateDigitsField,
  };
};
