import { IntlShape } from 'react-intl';

import { sharedMessages } from '.';

type PageMessageDto = {
  error: string;
  loading: string;
  showMoreButtonTitle: string;
  endOfList: string;
};

export const getPageMessages = (
  intl: IntlShape,
  overrides: Partial<PageMessageDto> = {}
): PageMessageDto =>
  Object.assign(
    {
      error: intl.$t(sharedMessages.error),
      loading: intl.$t(sharedMessages.loading),
      showMoreButtonTitle: intl.$t(sharedMessages.showMoreButtonTitle),
      endOfList: intl.$t(sharedMessages.endOfList),
    },
    overrides
  );
