import { sortByItemsMessages } from '@app/translations';
import { SORT_DIRECTION, SortOption } from '@app/constants';

export const FACET = {
  STATUS: 'patronItemStatus',
  FORMAT: 'sourceTypes',
  LOCATION: 'items.effectiveLocationId',
  PUBLISHER: 'instancePublishers',
  LANGUAGE: 'languages',
  SUBJECT: 'instanceSubjects',
  PUBLICATION_YEAR: 'publicationYear',
} as const;

export enum OPERATOR {
  ALL = 'all',
  EQUAL = '=',
  PHRASE_MATCH = '==',
  GREATER_THAN = '>',
  LESS_THAN = '<',
}

export enum SEARCH_OPTION {
  keyword = 'keyword',
  title = 'title',
  author = 'author',
  issn = 'issn',
  isbn = 'isbn',
  lccn = 'lccn',
  subject = 'subject',
  advanced = 'advanced',
}

export const SEARCH_OPTIONS = [
  SEARCH_OPTION.keyword,
  SEARCH_OPTION.title,
  SEARCH_OPTION.author,
  SEARCH_OPTION.issn,
  SEARCH_OPTION.isbn,
  SEARCH_OPTION.lccn,
  SEARCH_OPTION.subject,
  SEARCH_OPTION.advanced,
];

export const searchOptionMap = {
  [SEARCH_OPTION.keyword]: `keyword ${OPERATOR.ALL}`,
  [SEARCH_OPTION.title]: `title ${OPERATOR.ALL}`,
  [SEARCH_OPTION.author]: `contributors ${OPERATOR.ALL}`,
  [SEARCH_OPTION.issn]: `issn ${OPERATOR.EQUAL}`,
  [SEARCH_OPTION.isbn]: `isbn ${OPERATOR.EQUAL}`,
  [SEARCH_OPTION.lccn]: `lccn ${OPERATOR.EQUAL}`,
  [SEARCH_OPTION.subject]: `subjects ${OPERATOR.ALL}`,
};

export enum SORT_FIELD {
  relevance = 'relevance',
  title = 'title',
  author = 'author',
  publicationYear = 'publicationYear',
}

export const sortFieldMap = {
  [SORT_FIELD.title]: 'title',
  [SORT_FIELD.author]: 'contributors',
  [SORT_FIELD.publicationYear]: 'publicationYear',
};

export const facetToOperatorMap = {
  [FACET.STATUS]: OPERATOR.PHRASE_MATCH,
  [FACET.LOCATION]: OPERATOR.PHRASE_MATCH,
  [FACET.FORMAT]: OPERATOR.PHRASE_MATCH,
  [FACET.LANGUAGE]: OPERATOR.PHRASE_MATCH,
  [FACET.PUBLISHER]: OPERATOR.PHRASE_MATCH,
  [FACET.SUBJECT]: OPERATOR.PHRASE_MATCH,
};

export const IDENTIFIER_TYPE = {
  ISBN: 'ISBN',
  ISSN: 'ISSN',
  LCCN: 'LCCN',
  OCLC: 'OCLC',
};

export const ALL_RECORDS_CQL = 'cql.allRecords=1';

export const searchResultSortItems: SortOption[] = [
  {
    label: sortByItemsMessages.relevance,
    sortField: SORT_FIELD.relevance,
  },
  {
    label: sortByItemsMessages.title,
    sortField: SORT_FIELD.title,
    sortDirection: SORT_DIRECTION.asc,
  },
  {
    label: sortByItemsMessages.title,
    sortField: SORT_FIELD.title,
    sortDirection: SORT_DIRECTION.desc,
  },
  {
    label: sortByItemsMessages.author,
    sortField: SORT_FIELD.author,
    sortDirection: SORT_DIRECTION.asc,
  },
  {
    label: sortByItemsMessages.author,
    sortField: SORT_FIELD.author,
    sortDirection: SORT_DIRECTION.desc,
  },
  {
    label: sortByItemsMessages.publicationYear,
    sortField: SORT_FIELD.publicationYear,
    sortDirection: SORT_DIRECTION.asc,
  },
  {
    label: sortByItemsMessages.publicationYear,
    sortField: SORT_FIELD.publicationYear,
    sortDirection: SORT_DIRECTION.desc,
  },
];

export const ADVANCED_SEARCH_MODAL_ROW_COUNT = 3;

export const SEARCH_HISTORY_KEY = 'searchHistory';
export const SAVED_INSTANCES_KEY = 'savedInstances';
