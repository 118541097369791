import { IntlShape } from 'react-intl';

import { FACET } from '@app/search';
import { LocationDto } from '@app/contexts';

import { MappedFacetValue } from '../constants';
import { getLanguageOptions, getLocationOptions } from '.';

export interface UnmappedFacetValue {
  id: string;
  totalRecords: number;
}

interface FacetMappingValueProps {
  $t: IntlShape['$t'];
  facetName: string;
  values: UnmappedFacetValue[];
  locations: LocationDto[] | undefined;
}

export const getMappedFacetValues = ({
  $t,
  facetName,
  values,
  locations,
}: FacetMappingValueProps): MappedFacetValue[] => {
  switch (facetName) {
    case FACET.LANGUAGE:
      return getLanguageOptions($t, values);
    case FACET.LOCATION:
      return getLocationOptions(locations, values);
    default:
      return values.map(({ id, totalRecords }) => ({
        label: id,
        value: id,
        count: totalRecords,
      }));
  }
};
