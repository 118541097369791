import { createContext, useContext } from 'react';

import { PatronSessionState } from '@app/components';
import { authenticateToken } from '@app/hooks';
import { SetState } from '@app/constants';

export interface AppContextProps {
  locale: string;
  patronSession: PatronSessionState;
  isLoggedIn: boolean;
  holdRecordId: string;
  bannerMessage: string | undefined;
  authenticateToken: authenticateToken;
  setHoldRecordId: SetState<string>;
  setPatronSession: SetState<PatronSessionState>;
  setSelectedLocale: SetState<string>;
}

export const AppContext = createContext<AppContextProps>(null!);

export const useAppContext = (): AppContextProps => {
  return useContext(AppContext);
};
