import { FC } from 'react';
import { RouteProps } from 'react-router-dom';

import { FeatureFlags } from '@app/components';
import { FEATURE } from '@app/constants';

type ConditionalRouteProps = {
  flags?: FEATURE[];
  shouldRenderOnFalse?: boolean;
};

export const ConditionalComponent: FC<RouteProps & ConditionalRouteProps> = ({
  flags,
  shouldRenderOnFalse = false,
  children,
}) => {
  const renderChildren = () => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  };

  if (!flags) {
    return renderChildren();
  }

  const props = {
    [shouldRenderOnFalse ? 'renderOff' : 'renderOn']: renderChildren,
  };

  return <FeatureFlags authorizedFlags={flags} {...props} />;
};
