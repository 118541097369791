import { FC, useEffect, useState } from 'react';
import { isEmpty, isString } from 'lodash';

import { PageSpinner, InitialAppState } from '@app/components';
import { useFeatureFlagsFetch } from '@app/hooks/requests/useFeatureFlagsFetch';
import { usePageBannerConfig, useSessionValidation } from '@app/hooks';
import { setAxiosHeaders } from '@app/utils';
import { SetState, USER_SESSION } from '@app/constants';

interface AppSetupProps {
  setInitialAppState: SetState<InitialAppState>;
  setIsOkapiSessionDown: SetState<boolean>;
}

export const AppSetup: FC<AppSetupProps> = ({
  setInitialAppState,
  setIsOkapiSessionDown,
}) => {
  const [isGuestUserLoggedIn, setIsGuestUserLoggedIn] = useState(false);

  const guestUserValidatedSession = useSessionValidation({
    userSessionKey: USER_SESSION.guest,
    setIsOkapiSessionDown,
  });
  const pageBannerMessage = usePageBannerConfig(isGuestUserLoggedIn);
  const featureFlags = useFeatureFlagsFetch(isGuestUserLoggedIn);

  useEffect(() => {
    if (
      guestUserValidatedSession.isLoggedIn &&
      guestUserValidatedSession.session?.token
    ) {
      setAxiosHeaders(guestUserValidatedSession.session);
      setIsGuestUserLoggedIn(true);
    }
  }, [guestUserValidatedSession.isLoggedIn, guestUserValidatedSession.session]);

  useEffect(() => {
    if (isString(pageBannerMessage) && !isEmpty(featureFlags)) {
      setInitialAppState({
        pageBannerMessage,
        featureFlags,
        isGuestUserLoggedIn,
      });
    }
    // eslint-disable-next-line
  }, [featureFlags, pageBannerMessage]);

  return <PageSpinner />;
};
