import { FC } from 'react';

import { FeatureFlag, FeatureFlagsContext } from '@app/contexts';

interface FeatureFlagsProviderProps {
  flags: FeatureFlag[];
}
export const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({
  flags,
  children,
}) => {
  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
