import { defineMessages } from 'react-intl';

import { ITEM_STATUS } from '@app/pages/constants';

export const statusMessages = defineMessages({
  feeDue: {
    id: 'itemStatus.feeDue',
    defaultMessage: 'Fee due',
  },
  [ITEM_STATUS.onHold]: {
    id: 'itemStatus.onHold',
    defaultMessage: 'On hold',
  },
  [ITEM_STATUS.checkedOut]: {
    id: 'itemStatus.checkedOut',
    defaultMessage: 'Checked out',
  },
  [ITEM_STATUS.overdue]: {
    id: 'itemStatus.overDue',
    defaultMessage: 'Overdue',
  },
});
