import { defineMessages } from 'react-intl';

export const holds = defineMessages({
  showMoreButtonTitle: {
    id: 'holds.showMoreButtonTitle',
    defaultMessage: 'Show more holds',
  },
  noTabResults: {
    id: 'holds.noTabResults',
    defaultMessage: 'You currently have no holds.',
  },
  promptTitle: {
    id: 'holds.promptTitle',
    defaultMessage: 'Place holds on items by signing into your account.',
  },
});

export const checkouts = defineMessages({
  showMoreButtonTitle: {
    id: 'checkouts.showMoreButtonTitle',
    defaultMessage: 'Show more checkouts',
  },
  noTabResults: {
    id: 'checkouts.noTabResults',
    defaultMessage: 'You currently have no checkouts.',
  },
  promptTitle: {
    id: 'checkouts.promptTitle',
    defaultMessage: 'See your checkouts by signing into your account.',
  },
});

export const fees = defineMessages({
  showMoreButtonTitle: {
    id: 'fees.showMoreButtonTitle',
    defaultMessage: 'Show more fees',
  },
  noTabResults: {
    id: 'fees.noTabResults',
    defaultMessage: 'You currently have no fees.',
  },
  promptTitle: {
    id: 'fees.promptTitle',
    defaultMessage: 'View your fees by signing into your account.',
  },
});
