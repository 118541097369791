import { useQuery } from '@tanstack/react-query';

import { features as defaultFeatureFlags } from '@tests/jest/mocks/feature-config.json';
import { FeatureFlag } from '@app/contexts';
import { axios } from '@app/utils';

export const useFeatureFlagsFetch = (isEnabled: boolean): FeatureFlag[] => {
  const featureFlagsQuery = useQuery(
    ['featureFlags'],
    () =>
      axios
        .get('/opac-configurations/features')
        .then(response => response.data.features)
        .catch(() => defaultFeatureFlags),
    {
      enabled: isEnabled,
    }
  );

  return featureFlagsQuery.data;
};
