import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Dropdown } from '@app/components';
import { SORT_FIELD } from '@app/search';
import { SORT_DIRECTION, SortOption } from '@app/constants';

import css from './SortByDropdown.module.scss';

export interface SortByDropdownProps {
  options: SortOption[];
  sortField?: string;
  sortDirection?: string;
  styleType?: 'standard' | 'primary';
  hideLabel?: boolean;
  onSelect: (searchOption: SortOption) => void;
}

export const SortByDropdown: FC<SortByDropdownProps> = ({
  options,
  sortField = SORT_FIELD.relevance,
  sortDirection = SORT_DIRECTION.desc,
  styleType,
  hideLabel,
  onSelect,
}) => {
  const { $t } = useIntl();
  const formattedOptions = useMemo(
    () =>
      options.map(option => ({
        ...option,
        label: $t(option.label, { order: option.sortDirection }),
      })),
    [$t, options]
  );
  const selectedOption =
    formattedOptions.find(
      option =>
        option.sortField === sortField && option.sortDirection === sortDirection
    ) || formattedOptions[0];

  const handleSelectedItemChange = useCallback(
    ({ selectedItem }) => {
      onSelect(selectedItem);
    },
    [onSelect]
  );

  return (
    <Dropdown
      id="sortByDropdown"
      items={formattedOptions}
      className={css.sortByDropdown}
      label={$t({
        id: 'sortByDropdown.label',
        defaultMessage: 'Sort by',
      })}
      selectedItem={selectedOption}
      hideLabel={hideLabel}
      styleType={styleType}
      onSelectedItemChange={handleSelectedItemChange}
    />
  );
};
