import { FC } from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { uniqueId } from 'lodash';

import { TextInput, PasswordInput } from '@ebsco-ui/ebsco-ui';

export interface FieldWrapperProps {
  component: typeof TextInput | typeof PasswordInput;
  input: FieldInputProps<string, HTMLInputElement>;
  meta: FieldMetaState<string>;
  id?: string;
  error?: string;
  showPasswordButtonText?: string;
  hidePasswordButtonText?: string;
}

export const FieldWrapper: FC<FieldWrapperProps> = ({
  id = uniqueId('form-field-'),
  error,
  meta,
  input,
  component: Component,
  ...props
}) => {
  const fieldError =
    error ||
    (meta.touched && (meta.error || meta.submitError)
      ? meta.error ?? meta.submitError
      : null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Component id={id} error={fieldError} {...props} {...input} />;
};
