import { FC } from 'react';

import { IndeterminateSpinner } from '@ebsco-ui/ebsco-ui';

import './PageSpinner.scss';

export const PageSpinner: FC = () => {
  return (
    <div data-testid="spinner">
      <IndeterminateSpinner level="page" className="page-spinner" />
    </div>
  );
};
