import { FC, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import {
  AppRoutes,
  PageSpinner,
  ProviderRoot,
  ThemingConfig,
} from '@app/components';
import { PatronSession, useAppUsers, useFeatureFlag } from '@app/hooks';
import { FEATURE, SetState, USER_SESSION } from '@app/constants';

interface AppReadyProps {
  locale: string;
  translationMessages: Record<string, string>;
  bannerMessage: string;
  setSelectedLocale: SetState<string>;
  setIsOkapiSessionDown: SetState<boolean>;
}

export type PatronSessionState = PatronSession | null;

export const AppReady: FC<AppReadyProps> = ({
  locale,
  translationMessages,
  bannerMessage,
  setSelectedLocale,
  setIsOkapiSessionDown,
}) => {
  const [patronSession, setPatronSession] = useState<PatronSessionState>(null);
  const [holdRecordId, setHoldRecordId] = useState('');
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);
  const { isPatronUserLoggedIn, authenticateToken } = useAppUsers({
    patronSession,
    isConsortiaLogin: Boolean(consortiaLoginFlowFlag?.isActive),
    setPatronSession,
    setIsOkapiSessionDown,
  });
  const patronSessionFromStorage = JSON.parse(
    sessionStorage.getItem(USER_SESSION.patron) || 'null'
  );

  const appContext = useMemo(
    () => ({
      locale,
      isLoggedIn: isPatronUserLoggedIn,
      patronSession,
      holdRecordId,
      bannerMessage,
      setSelectedLocale,
      setPatronSession,
      setHoldRecordId,
      authenticateToken,
    }),
    [
      locale,
      isPatronUserLoggedIn,
      patronSession,
      holdRecordId,
      bannerMessage,
      setSelectedLocale,
      setPatronSession,
      setHoldRecordId,
      authenticateToken,
    ]
  );

  if (!isEmpty(patronSessionFromStorage) && !isPatronUserLoggedIn) {
    return <PageSpinner />;
  }

  return (
    <ProviderRoot appContext={appContext} messages={translationMessages}>
      <ThemingConfig />
      <AppRoutes />
    </ProviderRoot>
  );
};
