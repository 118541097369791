import { UserPersonalInfo } from '@app/hooks';

const getFirstLetter = (str = '') => str.charAt(0).toUpperCase();

export const getUsersInitials = (user: Partial<UserPersonalInfo>): string => {
  if (!user) {
    return '';
  }

  const { firstName = '', lastName = '', email } = user;
  const firstNameInitial = getFirstLetter(firstName);
  const lastNameInitial = getFirstLetter(lastName);

  if (firstNameInitial || lastNameInitial) {
    return `${firstNameInitial}${lastNameInitial}`;
  }

  return getFirstLetter(email);
};
