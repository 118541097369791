import { FC, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { noop } from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faBooks } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { Button, Popover } from '@ebsco-ui/ebsco-ui';

import { Icon, ConditionalComponent } from '@app/components';
import { sharedMessages, navigationMessages } from '@app/translations';
import { ROUTE, FEATURE, SetState } from '@app/constants';

import css from './NavigationPanel.module.scss';

interface NavigationPanelProps {
  isMobile?: boolean;
  setShowDrawer?: SetState<boolean>;
}

export const NavigationPanel: FC<NavigationPanelProps> = ({
  isMobile,
  setShowDrawer,
}) => {
  const location = useLocation();
  const { $t } = useIntl();
  const [isBrowseDropdownExpanded, setIsBrowseDropdownExpanded] =
    useState(false);

  const handleCloseMobileMenu = () => setShowDrawer && setShowDrawer(false);
  const getBrowseLinksLabel = ({ isExpanded }) =>
    $t(
      isExpanded
        ? navigationMessages.collapseBrowseLinks
        : navigationMessages.expandBrowseLinks
    );

  const navigationLinks = useMemo(() => {
    return [
      {
        label: $t({
          defaultMessage: 'Catalog home',
          id: 'navigation.catalogHome',
        }),
        icon: faHome,
        href: ROUTE.search,
      },
      {
        label: $t({
          defaultMessage: 'My bookshelf',
          id: 'navigation.myBookshelf',
        }),
        flags: [FEATURE.bookshelf],
        icon: faBooks,
        href: ROUTE.bookshelf,
      },
    ];
  }, [$t]);

  const browseDropdownLinks = useMemo(() => {
    return [
      {
        flags: [FEATURE.courseReserves],
        label: $t({
          defaultMessage: 'Course reserves',
          id: 'courseReserves.title',
        }),
        href: ROUTE.courseReserves,
      },
      {
        flags: [FEATURE.callNumbers],
        label: $t({
          defaultMessage: 'Call numbers',
          id: 'navigation.callNumbers',
        }),
        href: ROUTE.callNumbers,
      },
    ];
  }, [$t]);

  const browseButtonContent = (
    <Icon icon={faBinoculars} className={css.icon}>
      {$t({
        defaultMessage: 'Browse',
        id: 'navigation.browse',
      })}
      <Icon
        icon={faChevronRight}
        className={cn(css.arrow, {
          [css.isRotate]: isMobile && isBrowseDropdownExpanded,
        })}
      />
    </Icon>
  );

  const hasBrowseDropdownActiveLink = [
    ROUTE.courseReserves,
    ROUTE.callNumbers,
  ].includes(location.pathname as ROUTE);

  const getBrowseDropdownLinks = (handlePopoverClose = noop) => (
    <ul className={css.browseDropdownLinks}>
      {browseDropdownLinks.map(({ href, label, flags }) => (
        <ConditionalComponent key={label} flags={flags}>
          <li>
            <NavLink
              to={href}
              className={({ isActive }) =>
                isActive ? css.isActive : undefined
              }
              onClick={() => {
                handleCloseMobileMenu();
                handlePopoverClose();
              }}
            >
              {label}
            </NavLink>
          </li>
        </ConditionalComponent>
      ))}
    </ul>
  );

  return (
    <nav
      className={css.navigation}
      aria-label={$t({
        id: 'navigation.main',
        defaultMessage: 'Main',
      })}
    >
      {isMobile && (
        <div className={css.closeButtonWrapper}>
          <Button
            styleType="flat-icon"
            className={css.closeButton}
            aria-label={$t(sharedMessages.close)}
            onClick={handleCloseMobileMenu}
          >
            <FontAwesomeIcon className="fa-lg" icon={faTimes} />
          </Button>
        </div>
      )}
      <ul className={css.links}>
        {navigationLinks.map(({ label, icon, href, flags }) => (
          <ConditionalComponent key={label} flags={flags}>
            <li key={label}>
              <NavLink
                to={href}
                className={({ isActive }) =>
                  cn(css.link, {
                    [css.isActive]: isActive,
                  })
                }
                onClick={handleCloseMobileMenu}
              >
                <FontAwesomeIcon icon={icon} className={css.icon} />
                {label}
              </NavLink>
            </li>
          </ConditionalComponent>
        ))}
        <ConditionalComponent
          flags={[FEATURE.callNumbers, FEATURE.courseReserves]}
        >
          <li>
            {isMobile ? (
              <>
                <Button
                  className={cn(css.browseDropdown, css.link, {
                    [css.isActive]: hasBrowseDropdownActiveLink,
                  })}
                  styleType="flat-icon"
                  aria-label={getBrowseLinksLabel({
                    isExpanded: isBrowseDropdownExpanded,
                  })}
                  onClick={() =>
                    setIsBrowseDropdownExpanded(!isBrowseDropdownExpanded)
                  }
                >
                  {browseButtonContent}
                </Button>
                {isBrowseDropdownExpanded && getBrowseDropdownLinks()}
              </>
            ) : (
              <Popover
                uniqueName="browse-links"
                panelPlacement="bottom-start"
                className={css.wrapper}
              >
                <Popover.ContextConsumer>
                  {({ isOpen, closePopover }) => (
                    <>
                      <Popover.Button
                        styleType="flat-icon"
                        id="browse-links-button"
                        className={cn(
                          css.browseDropdown,
                          css.link,
                          {
                            [css.isActive]: hasBrowseDropdownActiveLink,
                          },
                          'popoverButton'
                        )}
                        aria-label={getBrowseLinksLabel({
                          isExpanded: isOpen,
                        })}
                      >
                        {browseButtonContent}
                      </Popover.Button>
                      <Popover.Panel
                        aria-describedby="browse-links-button"
                        className="popoverWrapper"
                      >
                        {getBrowseDropdownLinks(closePopover)}
                      </Popover.Panel>
                    </>
                  )}
                </Popover.ContextConsumer>
              </Popover>
            )}
          </li>
        </ConditionalComponent>
      </ul>
    </nav>
  );
};
