import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Modal, createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';
import { StackMapDto } from '@app/components';
import { sharedMessages } from '@app/translations';
import { MODAL_PLACEMENT } from '@app/constants';

import { ZoomMap } from '..';

import '../Modal/Modal.scss';
import './StackMapModal.scss';

interface StackMapModalProps {
  isOpen: boolean;
  stackMapData: StackMapDto;
  onModalClose: () => void;
}

const cnBem = createBemBlockBuilder(['stackMapModal']);

export const StackMapModal: FC<StackMapModalProps> = ({
  isOpen,
  stackMapData: {
    mapurl,
    directions,
    callNo,
    ranges = [],
    printMapurl: printMapUrl,
  },
  onModalClose,
}) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { $t } = useIntl();

  const wrapChunks = chunks => <b>{chunks}</b>;
  const itemDirections = [
    $t(
      {
        id: 'stackMap.direction',
        defaultMessage: '<b>Direction:</b> {direction}',
      },
      {
        direction: directions,
        b: wrapChunks,
      }
    ),
    $t(
      {
        id: 'stackMap.shelvingRow',
        defaultMessage: '<b>Go to the shelving row labeled:</b> {row}',
      },
      {
        row: ranges[0]?.rangename,
        b: wrapChunks,
      }
    ),
    $t(
      {
        id: 'stackMap.lookForCallNumber',
        defaultMessage:
          '<b>Look for the item with this call number:</b> {callNo}',
      },
      {
        callNo,
        b: wrapChunks,
      }
    ),
  ];

  return (
    <Modal
      isOpen={isOpen}
      modalTitle={$t(sharedMessages.map)}
      appElement={MODAL_PLACEMENT}
      className={cnBem()}
      onClose={onModalClose}
      onAfterClose={() => setIsMapLoaded(false)}
    >
      <Modal.Header
        closeButtonProps={{
          title: $t(sharedMessages.close),
          className: createBemBlockBuilder(['modal'])('__close-button'),
          onClick: onModalClose,
        }}
        data-testid="stackMapModalHeader"
      >
        <h2>{$t(sharedMessages.map)}</h2>
      </Modal.Header>
      <Modal.Body>
        <ZoomMap
          imgSrc={mapurl}
          isImageLoaded={isMapLoaded}
          printMapUrl={printMapUrl}
          onImageLoad={() => setIsMapLoaded(true)}
        />
        <div
          className={cnBem('__directionsWrapper')}
          data-testid="stackMapDirections"
        >
          {itemDirections.map((direction, index) => (
            <div className={cnBem('__direction')} key={index}>
              {direction}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer data-testid="stackMapModalFooter">
        <Button styleType="text" onClick={onModalClose}>
          {$t(sharedMessages.close)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
