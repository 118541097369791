import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Loading } from '@ebsco-ui/ebsco-ui';

import { useNovelistSelect } from '@app/components';
import { sharedMessages } from '@app/translations';

export interface NovelistProps {
  isbn?: string;
  config?: {
    profile: string;
    password: string;
    url: string;
  };
}

export const Novelist: FC<NovelistProps> = ({ isbn = '', config }) => {
  const { $t } = useIntl();
  const { isSelectLoading, isScriptLoaded } = useNovelistSelect({
    isbn,
    config,
  });

  const shouldRender = isScriptLoaded && Boolean(isbn);

  return shouldRender ? (
    <aside
      aria-label={$t({
        id: 'instanceDetails.novelist',
        defaultMessage: 'Catalog enrichment powered by NoveList',
      })}
      data-testid="novelist"
      data-novelist-novelistselect={isbn}
    >
      {isSelectLoading && (
        <div data-testid="novelist-spinner">
          <Loading loadingMessage={$t(sharedMessages.loadingMessage)} />
        </div>
      )}
    </aside>
  ) : null;
};
