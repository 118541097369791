import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, createBemBlockBuilder } from '@ebsco-ui/ebsco-ui';

import { useRecordSummaryContext } from '@app/components';
import { useFeatureFlag } from '@app/hooks';
import { FEATURE, PropsWithClassName } from '@app/constants';

import '../RecordSummary/RecordSummary.scss';

const cnBem = createBemBlockBuilder(['recordSummary']);

export const PlaceHoldButton: FC<PropsWithClassName> = ({ className }) => {
  const placeHoldFlag = useFeatureFlag(FEATURE.placeHold);
  const {
    availability = { isRequestable: true },
    links,
    isAvailabilityLoadingForTheFirstTime,
    setPlaceHoldModalOpen,
  } = useRecordSummaryContext();

  return (
    <div className={className || cnBem('__buttonsWrapper')}>
      {availability?.isRequestable && placeHoldFlag?.isActive && (
        <Button
          disabled={isAvailabilityLoadingForTheFirstTime}
          onClick={() => setPlaceHoldModalOpen(true)}
        >
          <FormattedMessage
            id="resultsList.placeHold"
            defaultMessage="Place a hold"
          />
        </Button>
      )}
      {links}
    </div>
  );
};
