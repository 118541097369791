import { defineMessages } from 'react-intl';

export const checkoutMessages = defineMessages({
  amount: {
    id: 'itemStatus.amount',
    defaultMessage: 'Amount',
  },
  fees: {
    id: 'itemStatus.fees',
    defaultMessage: 'Fees',
  },
});
