import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { times } from 'lodash';

import { Button, createBemBlockBuilder, Modal } from '@ebsco-ui/ebsco-ui';

import {
  booleanChips,
  RowValues,
  SearchRow,
  useAdvancedSearchQuery,
} from '@app/components';
import { ADVANCED_SEARCH_MODAL_ROW_COUNT, SEARCH_OPTION } from '@app/search';
import { useSearchContext } from '@app/contexts';
import { sharedMessages } from '@app/translations';
import { IS_TEST_ENV, MODAL_PLACEMENT } from '@app/constants';

import './AdvancedSearchModal.scss';

interface AdvancedSearchModalProps {
  isOpen: boolean;
  onModalClose: () => void;
}

export type ModalValues = Record<number, RowValues>;

export const getModalValuesObject = (
  rowCount = ADVANCED_SEARCH_MODAL_ROW_COUNT
): ModalValues => {
  const rows = {};

  times(rowCount, index => {
    rows[index] = {
      value: '',
      searchOption: SEARCH_OPTION.keyword,
      ...(index > 0 && { booleanChip: booleanChips.and }),
    };
  });

  return rows;
};

const cnBem = createBemBlockBuilder(['advancedSearchModal']);

export const AdvancedSearchModal: FC<AdvancedSearchModalProps> = ({
  isOpen,
  onModalClose,
}) => {
  const [values, setValues] = useState<ModalValues>(getModalValuesObject());
  const { $t } = useIntl();
  const { state, shouldRefocusRef, updateState, setSearchOption } =
    useSearchContext();
  const { createAdvancedSearchQuery, parseAdvancedSearchQuery } =
    useAdvancedSearchQuery();

  const onSubmit = event => {
    event.preventDefault();

    shouldRefocusRef.current = true;

    setSearchOption(SEARCH_OPTION.advanced);
    updateState({
      query: createAdvancedSearchQuery(values),
      option: SEARCH_OPTION.advanced,
    });

    onModalClose();
  };

  const updateRow = rowIndex => {
    return updatedValues => {
      setValues(prevValues => ({
        ...prevValues,
        [rowIndex]: updatedValues,
      }));
    };
  };

  useEffect(() => {
    const isAdvancedSearch =
      state.option === SEARCH_OPTION.advanced && Boolean(state.query);

    if (isAdvancedSearch && createAdvancedSearchQuery(values) !== state.query) {
      setValues(parseAdvancedSearchQuery(state.query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.query, state.option]);

  return (
    <Modal
      isOpen={isOpen}
      appElement={MODAL_PLACEMENT}
      className={cnBem()}
      modalTitle={$t(sharedMessages.advancedSearch)}
      ariaHideApp={!IS_TEST_ENV}
      onClose={onModalClose}
    >
      <main className={cnBem('__container')}>
        <form role="search" onSubmit={onSubmit}>
          <Modal.Header
            closeButtonProps={{
              title: $t(sharedMessages.close),
              onClick: onModalClose,
            }}
          >
            <h1 className={cnBem('__header-title')}>
              {$t(sharedMessages.advancedSearch)}
            </h1>
          </Modal.Header>
          <Modal.Body>
            {Object.values(values).map((data, index) => (
              <SearchRow
                {...data}
                index={index}
                totalRowsCount={ADVANCED_SEARCH_MODAL_ROW_COUNT}
                key={`row-${index}`}
                updateRow={updateRow(index)}
              />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button styleType="text" onClick={onModalClose}>
              {$t(sharedMessages.cancel)}
            </Button>
            <Button type="submit" onClick={onModalClose}>
              {$t(sharedMessages.search)}
            </Button>
          </Modal.Footer>
        </form>
      </main>
    </Modal>
  );
};
