import { omit } from 'lodash';

import { FACET } from '@app/search';

export const FACETS_ORDER = Object.values(omit(FACET, 'PUBLICATION_YEAR'));

export interface MappedFacetValue {
  value: string;
  count: number;
  label?: string;
}

export type FacetInstanceDto = Record<
  (typeof FACETS_ORDER)[0],
  {
    label: string;
    values:
      | null
      | {
          id: string;
          value: string;
          count: number;
        }[];
    isError?: boolean;
  }
>;

export const initialDateRange = {
  minDate: { value: '1100', error: '' },
  maxDate: { value: '2022', error: '' },
  isSharedError: false,
};

export const FACET_SHOW_MORE_LIMIT = 6;

export const QUICK_FILTER_TYPE = {
  allTime: 'allTime',
  pastYear: 'pastYear',
  pastFiveYears: 'pastFiveYears',
  pastTenYears: 'pastTenYears',
  custom: 'custom',
} as const;

const currentYear = new Date().getFullYear();

export const PUBLICATION_YEAR_OPTION = {
  [QUICK_FILTER_TYPE.pastYear]: `${currentYear - 1}`,
  [QUICK_FILTER_TYPE.pastFiveYears]: `${currentYear - 5}`,
  [QUICK_FILTER_TYPE.pastTenYears]: `${currentYear - 10}`,
};

export const DATE_FORMAT = {
  yyyy: 'yyyy',
  YYYY: 'YYYY',
};

export const FACET_LIMIT = 500;
