import { QueryFunction } from '@tanstack/react-query';
import queryString from 'query-string';

import { SearchResultsDto } from '@app/pages/constants';
import { axios } from '@app/utils';

import { SearchState, useUrlManager, getCQLState, useSearchHistory } from '..';

export const useSearchRequest = (
  state: SearchState
): QueryFunction<SearchResultsDto> => {
  const { updateUrl } = useUrlManager();
  const { addSearchHistoryEntry } = useSearchHistory();

  return ({ queryKey }) => {
    const searchState = queryKey[1] as SearchState;

    if (!state.isInnerUpdate) {
      updateUrl(searchState);
    }

    addSearchHistoryEntry(searchState);

    return axios
      .get('/search/instances', {
        params: {
          expandAll: true,
          ...getCQLState(searchState),
        },
        paramsSerializer: queryString.stringify,
      })
      .then(response => response.data)
      .catch(error => {
        throw error.response.data;
      });
  };
};
