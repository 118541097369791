import { DateTime } from 'luxon';

import { dateRangeMessages } from '@app/translations';

import { DATE_FORMAT } from '..';

interface DateErrorMessage {
  id: string;
  defaultMessage: string;
}

interface DateErrorValue {
  minDateErrorMessage: DateErrorMessage | null;
  maxDateErrorMessage: DateErrorMessage | null;
  isSharedError: boolean;
}

const validateDate = (date: DateTime | '') => {
  if (!date) {
    return dateRangeMessages.enterBothFields;
  }

  if (!date.isValid) {
    return dateRangeMessages.enterCorrectDateFormat;
  }

  return null;
};

export const validateDateRange = ({
  minDate,
  maxDate,
}: Record<string, string>): DateErrorValue => {
  const mMinDate = minDate && DateTime.fromFormat(minDate, DATE_FORMAT.yyyy);
  const mMaxDate = maxDate && DateTime.fromFormat(maxDate, DATE_FORMAT.yyyy);

  let isSharedError = false;
  let minDateErrorMessage = validateDate(mMinDate);
  let maxDateErrorMessage = validateDate(mMaxDate);

  if (!minDateErrorMessage && !maxDateErrorMessage && mMinDate > mMaxDate) {
    minDateErrorMessage = maxDateErrorMessage =
      dateRangeMessages.enterCorrectDateRange;
    isSharedError = true;
  }

  return {
    minDateErrorMessage,
    maxDateErrorMessage,
    isSharedError,
  };
};
