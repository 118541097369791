import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { createBemBlockBuilder, ShowMore } from '@ebsco-ui/ebsco-ui';

import { ShowMoreButton } from '@app/components';
import { sharedMessages } from '@app/translations';

import './CourseHero.scss';

interface CourseHeroProps {
  courseName: string;
  department?: string;
  courseNumber?: string;
  description?: string;
  instructors?: string[];
  location?: string;
}

const instructorsListLimit = 3;
const cnBem = createBemBlockBuilder(['courseHero']);

export const CourseHero: FC<CourseHeroProps> = ({
  courseName,
  courseNumber,
  department,
  description,
  instructors = [],
  location,
}) => {
  const heroTitle = courseNumber
    ? `${courseNumber} - ${courseName}`
    : courseName;

  const getCoursePropertyInfo = ({
    label,
    value,
    formatter,
  }: {
    label: keyof typeof sharedMessages;
    value: string | ReactElement[];
    formatter?: (value: string | ReactElement[]) => ReactElement;
  }) => (
    <FormattedMessage {...sharedMessages[label]}>
      {message => (
        <div
          className={cnBem('__courseInfoProperty')}
          data-testid="course-info-property"
        >
          <strong className={cnBem('__label')}>{`${message}: `}</strong>
          {formatter ? (
            formatter(value)
          ) : (
            <span className={cnBem('__value')}>{value}</span>
          )}
        </div>
      )}
    </FormattedMessage>
  );

  const instructorItems = instructors.map(instructor => (
    <li className={cnBem('__value')} key={instructor}>
      {instructor}
    </li>
  ));

  const instructorsFormatter = items => (
    <ShowMore limit={instructorsListLimit} className={cnBem('__showMore')}>
      <ul className={cnBem('__instructorsList')}>
        <ShowMore.Items>{items}</ShowMore.Items>
      </ul>
      <ShowMoreButton
        showMoreLimit={instructorsListLimit}
        dataLength={instructors.length}
      />
    </ShowMore>
  );

  return (
    <div data-testid="course-hero" className={cnBem()}>
      <h1 className={cnBem('__title')}>{heroTitle}</h1>
      {department &&
        getCoursePropertyInfo({ label: 'department', value: department })}
      {!isEmpty(instructors) &&
        getCoursePropertyInfo({
          label: 'instructor',
          value: instructorItems,
          formatter: instructorsFormatter,
        })}
      {location &&
        getCoursePropertyInfo({
          label: 'location',
          value: location,
        })}
      {description && <p className={cnBem('__description')}>{description}</p>}
    </div>
  );
};
