import { defineMessage, defineMessages } from 'react-intl';

import { QueryConfig } from '@app/hooks';
import { sortByItemsMessages } from '@app/translations';
import { SORT_DIRECTION, SortOption } from '@app/constants';

import { BookshelfTab, BookShelfSortField } from './bookshelfTypes';

export const tabsConfig: Record<
  BookshelfTab,
  {
    title: ReturnType<typeof defineMessage>;
    documentTitle: ReturnType<typeof defineMessage>;
    queryConfig: Partial<QueryConfig>;
    sortOptions: SortOption[];
  }
> = {
  holds: {
    ...defineMessages({
      documentTitle: {
        id: 'holds.documentTitle',
        defaultMessage: 'Holds - My bookshelf - EBSCO Locate',
      },
      title: {
        id: 'holds.tabWithCounter',
        defaultMessage: 'Holds ({value})',
      },
    }),
    queryConfig: { includeHolds: true },
    sortOptions: [
      {
        label: sortByItemsMessages.publicationYear,
        sortField: BookShelfSortField.requestDate,
        sortDirection: SORT_DIRECTION.desc,
      },
      {
        label: sortByItemsMessages.publicationYear,
        sortField: BookShelfSortField.requestDate,
        sortDirection: SORT_DIRECTION.asc,
      },
    ],
  },
  checkouts: {
    ...defineMessages({
      documentTitle: {
        id: 'checkouts.documentTitle',
        defaultMessage: 'Checkouts - My bookshelf - EBSCO Locate',
      },
      title: {
        id: 'checkouts.tabWithCounter',
        defaultMessage: 'Checkouts ({value})',
      },
    }),
    queryConfig: {
      includeCharges: true,
      includeLoans: true,
    },
    sortOptions: [
      {
        label: sortByItemsMessages.dueDate,
        sortField: BookShelfSortField.dueDate,
        sortDirection: SORT_DIRECTION.asc,
      },
      {
        label: sortByItemsMessages.dueDate,
        sortField: BookShelfSortField.dueDate,
        sortDirection: SORT_DIRECTION.desc,
      },
    ],
  },
  fees: {
    ...defineMessages({
      documentTitle: {
        id: 'fees.documentTitle',
        defaultMessage: 'Fees - My bookshelf - EBSCO Locate',
      },
      title: {
        id: 'fees.tabWithCounter',
        defaultMessage: 'Fees ({value})',
      },
    }),
    queryConfig: {
      includeCharges: true,
      includeLoans: true,
    },
    sortOptions: [
      {
        label: sortByItemsMessages.amount,
        sortField: BookShelfSortField.amount,
        sortDirection: SORT_DIRECTION.desc,
      },
      {
        label: sortByItemsMessages.amount,
        sortField: BookShelfSortField.amount,
        sortDirection: SORT_DIRECTION.asc,
      },
    ],
  },
};
