import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';

import { createBemBlockBuilder, Popover } from '@ebsco-ui/ebsco-ui';

import { AccountIcon, Icon } from '@app/components';
import { useAppContext } from '@app/contexts';
import { useFeatureFlag } from '@app/hooks';
import { sharedMessages } from '@app/translations';
import { FEATURE, ROUTE } from '@app/constants';

import './AccountInfo.scss';

const cnBem = createBemBlockBuilder(['accountInfo']);

export const AccountInfo: FC = () => {
  const { $t } = useIntl();
  const { patronSession, isLoggedIn } = useAppContext();
  const consortiaLoginFlowFlag = useFeatureFlag(FEATURE.consortiaLoginFlow);

  const getUserInfo = () => {
    if (!(patronSession && isLoggedIn)) {
      return null;
    }

    if (consortiaLoginFlowFlag?.isActive) {
      return (
        <div className={cnBem('__username')} data-testid="user-name">
          {patronSession.user?.externalSystemId}
        </div>
      );
    }

    return (
      <>
        <div className={cnBem('__username')} data-testid="user-name">
          {patronSession.user?.firstName} {patronSession.user?.lastName}
        </div>
        <div className={cnBem('__userEmail')} data-testid="user-email">
          {patronSession.user?.email}
        </div>
      </>
    );
  };

  return (
    <Popover
      uniqueName="account-info"
      panelPlacement="bottom-end"
      className={cnBem('__wrapper')}
    >
      <Popover.ContextConsumer>
        {({ closePopover }) => (
          <>
            <Popover.Button
              styleType="flat-icon"
              id="account-info-button"
              className={'popoverButton'}
              aria-label={$t({
                id: 'navigation.openUserInfo',
                defaultMessage: 'Open user info',
              })}
            >
              <AccountIcon />
            </Popover.Button>
            <Popover.Panel
              aria-describedby="account-info-button"
              className="popoverWrapper"
            >
              <h2 className={cnBem('__header')}>
                {isLoggedIn ? (
                  <FormattedMessage
                    id="navigation.myAccount"
                    defaultMessage="My account"
                  />
                ) : (
                  <FormattedMessage
                    id="navigation.libraryUser"
                    defaultMessage="Library user"
                  />
                )}
              </h2>
              <div className={cnBem('__userInfoWrapper')}>
                <AccountIcon className={cnBem('__userIcon')} />
                {patronSession && isLoggedIn && (
                  <div className={cnBem('__userInfo')}>{getUserInfo()}</div>
                )}
              </div>
              <ul className={cnBem('__links')}>
                {!consortiaLoginFlowFlag?.isActive && (
                  <li>
                    <Icon icon={faUserEdit} className={cnBem('__linkIcon')}>
                      <FormattedMessage {...sharedMessages.preferences}>
                        {message => (
                          <Link to={ROUTE.preferences} onClick={closePopover}>
                            {message[0]}
                          </Link>
                        )}
                      </FormattedMessage>
                    </Icon>
                  </li>
                )}
                <li>
                  <Icon icon={faSignOut} className={cnBem('__linkIcon')}>
                    {isLoggedIn ? (
                      <FormattedMessage
                        id="navigation.signOut"
                        defaultMessage="Sign out"
                      >
                        {message => <Link to={ROUTE.logout}>{message[0]}</Link>}
                      </FormattedMessage>
                    ) : (
                      <FormattedMessage {...sharedMessages.signIn}>
                        {message => <Link to={ROUTE.login}>{message[0]}</Link>}
                      </FormattedMessage>
                    )}
                  </Icon>
                </li>
              </ul>
            </Popover.Panel>
          </>
        )}
      </Popover.ContextConsumer>
    </Popover>
  );
};
