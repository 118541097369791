import { FC } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Loading } from '@ebsco-ui/ebsco-ui';

import { sharedMessages } from '@app/translations';
import { PropsWithClassName } from '@app/constants';

import css from './Image.module.scss';

interface ImageProps {
  src: string | undefined;
  altText: string;
  isLoading?: boolean;
}

export const Image: FC<PropsWithClassName<ImageProps>> = ({
  src,
  altText,
  className,
  isLoading = false,
}) => {
  const { $t } = useIntl();

  return isLoading ? (
    <div className={cn(css.image, className)} data-testid="loader-wrapper">
      <Loading
        loadingMessage={$t(sharedMessages.loadingMessage)}
        showMessage={false}
        size="small"
      />
    </div>
  ) : (
    <img src={src} alt={altText} className={cn(css.image, className)} />
  );
};
