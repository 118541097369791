import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@ebsco-ui/ebsco-ui';

import { RecordSummaryCard } from '@app/components';
import { sharedMessages } from '@app/translations';

import css from './ModalHeader.module.scss';

interface ModalHeaderProps {
  headerTitle: string;
  onModalClose: () => void;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  headerTitle,
  onModalClose,
}) => {
  const { $t } = useIntl();

  return (
    <Modal.Header
      closeButtonProps={{
        title: $t(sharedMessages.close),
        onClick: onModalClose,
      }}
    >
      <h2 className={css.headerTitle}>{headerTitle}</h2>
      <RecordSummaryCard isModal />
    </Modal.Header>
  );
};
