import queryString from 'query-string';

import { SORT_FIELD, SearchState } from '@app/search';
import { SORT_DIRECTION, URLQuery } from '@app/constants';

const stringifySortState = (
  sortField: SORT_FIELD = SORT_FIELD.relevance,
  sortDirection: SORT_DIRECTION = SORT_DIRECTION.asc
) => {
  if (sortField === SORT_FIELD.relevance) {
    return '';
  }

  const sortPrefix = sortDirection === SORT_DIRECTION.desc ? '-' : '';

  return `${sortPrefix}${sortField}`;
};

export const getURLState = ({
  query,
  option,
  sortField,
  sortDirection,
  facets,
  publicationYear,
}: SearchState): URLQuery => {
  const sort = stringifySortState(sortField, sortDirection);
  const selectedPublicationYear =
    publicationYear && queryString.stringify(publicationYear);
  const selectedFacets =
    facets &&
    queryString.stringify(facets, {
      arrayFormat: 'bracket-separator',
    });

  return {
    ...(query && { query }),
    ...(option && { option }),
    ...(sort && { sort }),
    ...(selectedPublicationYear && {
      publicationYear: selectedPublicationYear,
    }),
    ...(selectedFacets && {
      facets: selectedFacets,
    }),
  };
};
