import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useAppContext } from '@app/contexts';
import { axios } from '@app/utils';

import { HoldActionErrorDto, PlaceHoldDto } from '.';

interface RenewCheckoutRequestProps {
  itemId: string;
}

interface RenewCheckoutDto extends PlaceHoldDto {
  dueDate: string;
  overdue: boolean;
}

export const useRenewCheckoutMutation = (): UseMutationResult<
  RenewCheckoutDto,
  HoldActionErrorDto,
  RenewCheckoutRequestProps
> => {
  const appContext = useAppContext();

  return useMutation(({ itemId }: RenewCheckoutRequestProps) => {
    const userId = appContext?.patronSession?.user?.externalSystemId;
    const patronPath = `/opac-patron/account/${userId}`;
    const renewPath = `/item/${itemId}/renew`;

    return axios
      .post<RenewCheckoutDto>(`${patronPath}${renewPath}`)
      .then(response => response.data);
  });
};
