import { FC, ReactNode } from 'react';

import { FeatureFlag, FeatureFlagsContext } from '@app/contexts';
import { FEATURE } from '@app/constants';

interface FeatureFlagsProps {
  authorizedFlags: FEATURE[];
  renderOn?: (flag: FeatureFlag) => ReactNode;
  renderOff?: (flag: FeatureFlag) => ReactNode;
}

export const FeatureFlags: FC<FeatureFlagsProps> = ({
  children,
  authorizedFlags,
  renderOn,
  renderOff,
}) => {
  const findMatchingFlags = flags =>
    flags.filter(flag => flag.isActive && authorizedFlags.includes(flag.name));

  const resolveRender = matchingFlags => children ?? renderOn?.(matchingFlags);

  return (
    <FeatureFlagsContext.Consumer>
      {flags => {
        const matchingFlags = findMatchingFlags(flags);

        return matchingFlags.length
          ? resolveRender(matchingFlags)
          : renderOff?.(matchingFlags);
      }}
    </FeatureFlagsContext.Consumer>
  );
};
