import { FC } from 'react';
import { size } from 'lodash';
import { useSessionStorage } from 'usehooks-ts';

import { RecordSummary } from '@app/components';
import { SearchInstanceDto } from '@app/pages';
import { SAVED_INSTANCES_KEY } from '@app/search';

export const SavedInstancesList: FC = () => {
  const [savedInstances] = useSessionStorage<SearchInstanceDto[]>(
    SAVED_INSTANCES_KEY,
    []
  );

  return (
    <div data-testid="saved-instances-list">
      {savedInstances.map((instance, index) => (
        <RecordSummary
          testId="record-summary"
          key={instance.id}
          instance={instance}
          placement="bookshelf"
          isRTACShown={false}
          isLast={index === size(savedInstances) - 1}
        />
      ))}
    </div>
  );
};
