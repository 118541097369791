import { useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { useAppContext } from '@app/contexts';
import { axios, setAxiosHeaders } from '@app/utils';
import { INVOKE_PATH_PREFIX, ROUTE, USER_SESSION } from '@app/constants';

export const LogoutPage: FC = () => {
  const navigate = useNavigate();
  const { setPatronSession } = useAppContext();

  const samlLogoutMutation = useMutation(['samlLogout'], () =>
    axios
      .post(`${INVOKE_PATH_PREFIX}/saml/logout`, undefined, {
        withCredentials: true,
      })
      .finally(() => {
        sessionStorage.removeItem(USER_SESSION.patron);
        setAxiosHeaders(
          JSON.parse(sessionStorage.getItem(USER_SESSION.guest) || '{}')
        );
        setPatronSession(null);
        navigate(ROUTE.search);
      })
  );

  useEffect(() => {
    samlLogoutMutation.mutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samlLogoutMutation.mutate]);

  return null;
};
