import { FC, useCallback, useMemo, useState } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/pro-duotone-svg-icons';
import {
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/pro-solid-svg-icons';

import { RECORDS_PER_PAGE } from '@app/components/Browse/constants';
import {
  Browse,
  BrowseContributors,
  BrowsePaginationTitle,
  SortByDropdownWithIcon,
} from '@app/components';
import { useCourseReservesFetch, useQueryParams } from '@app/hooks';
import { generateRouteParams } from '@app/utils';
import { sharedMessages, sortByItemsMessages } from '@app/translations';
import { ROUTE, SORT_DIRECTION } from '@app/constants';

import { courseReservesMessages } from './courseReservesMessages';
import { CourseReservesItemDto } from './courseReservesTypes';

import css from './CourseReservesPage.module.scss';

const normalizeCQLTerm = (term: string): string => {
  // since the CQL search uses name=${term}* approach for constructing the query
  // for term='*' the query is name=** and request for it will fail as it
  // is invalid CQL, so replacing it with empty string
  return term === '*' ? '' : term;
};

const {
  documentTitle,
  instructor,
  courseName,
  courseCode,
  emptyStateTitle,
  pageTitle,
  paginationTitleWithRecords,
  paginationTitleNoRecords,
  searchBarPlaceholder,
  showMoreButtonTitle,
} = courseReservesMessages;
const { location } = sharedMessages;

export const CourseReservesPage: FC = () => {
  const { $t } = useIntl();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const courseReservesResource = useCourseReservesFetch(
    pageNumber,
    normalizeCQLTerm(queryParams.search as string)
  );

  const columns = useMemo(
    () => [
      {
        label: $t(courseCode),
        formatter: ({ courseNumber }: CourseReservesItemDto) => courseNumber,
      },
      {
        label: $t(courseName),
        formatter: ({ id, name }: CourseReservesItemDto) => (
          <Link
            data-testid="course-details-link"
            className="underlinedLink"
            to={{
              pathname: generatePath(ROUTE.courseReserveDetails, {
                id,
              }),
            }}
          >
            {name}
          </Link>
        ),
      },
      {
        label: $t(instructor),
        formatter: ({ courseListingObject }: CourseReservesItemDto) => (
          <BrowseContributors
            contributors={courseListingObject?.instructorObjects}
          />
        ),
      },
      {
        label: $t(location),
        formatter: ({ courseListingObject }: CourseReservesItemDto) =>
          courseListingObject.locationObject?.name,
      },
    ],
    [$t]
  );

  const renderEmptyState = () => (
    <>
      <div>
        <div className={css.titleWrapper}>
          <BrowsePaginationTitle
            paginationTitle={paginationTitleNoRecords}
            totalRecords={0}
            loadedRecordsAmount={0}
          />
        </div>
      </div>
      <h1 className={css.emptyStateMessage} data-testid="empty-state">
        <FontAwesomeIcon
          icon={faGraduationCap}
          className={css.emptyStateIcon}
        />
        <FormattedMessage {...emptyStateTitle} />
      </h1>
    </>
  );

  const updateSortQuery = useCallback(
    (updatedSortDirection: SORT_DIRECTION) =>
      navigate(
        generateRouteParams({
          pathname: ROUTE.courseReserves,
          query: {
            ...queryParams,
            sort: updatedSortDirection,
          },
        })
      ),
    [navigate, queryParams]
  );

  const renderBrowseTitle = () => {
    return (
      <div className={css.titleWrapper}>
        <BrowsePaginationTitle
          paginationTitle={paginationTitleWithRecords}
          totalRecords={courseReservesResource.totalRecords}
          loadedRecordsAmount={Math.min(
            RECORDS_PER_PAGE * pageNumber,
            courseReservesResource.totalRecords
          )}
        />
        <SortByDropdownWithIcon
          sortField="name"
          sortDirection={
            (queryParams?.sort as SORT_DIRECTION) || SORT_DIRECTION.asc
          }
          options={[
            {
              label: sortByItemsMessages.courseName,
              sortField: 'name',
              sortDirection: SORT_DIRECTION.asc,
              icon: faSortAmountDownAlt,
            },
            {
              label: sortByItemsMessages.courseName,
              sortField: 'name',
              sortDirection: SORT_DIRECTION.desc,
              icon: faSortAmountDown,
            },
          ]}
          onSelect={option =>
            updateSortQuery(option.sortDirection as SORT_DIRECTION)
          }
        />
      </div>
    );
  };

  return (
    <Browse
      messages={{
        paginationTitle: paginationTitleWithRecords,
        documentTitle,
        pageTitle,
        searchBarPlaceholder,
        showMoreButtonTitle,
      }}
      tableClasses={{ cell: css.tableCell }}
      resource={courseReservesResource}
      columns={columns}
      pageNumber={pageNumber}
      shouldRenderBrowseBy
      setPageNumber={setPageNumber}
      loadMore={() => setPageNumber(pageNumber + 1)}
      renderEmptyState={renderEmptyState}
      renderBrowseTitle={renderBrowseTitle}
    />
  );
};
