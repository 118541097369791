import { FC } from 'react';

import { SearchInstanceDto } from '@app/pages';

import { SavedInstanceToolButton } from './SavedInstanceToolButton';

import css from './RecordsTools.module.scss';

interface RecordsToolsProps {
  entry: SearchInstanceDto;
}

export const RecordsTools: FC<RecordsToolsProps> = ({ entry }) => (
  <div className={css.wrapper}>
    <SavedInstanceToolButton entry={entry} />
  </div>
);
